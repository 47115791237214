import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import webConfig from '../../api-request/webConfig';


export const fetchGlobalEquipments = createAsyncThunk(
    'api/fetchGlobalEquipments',
    async ( thunkAPI) => {
      try {
       
        const response = await axios.get(`${webConfig.API_DOMAIN_URL}/constants/equipments`);
        console.log("GlobalEquipments", response.data);
        return response.data;
      } catch (error) {
        console.error('Error fetching GlobalEquipments:', error);
        // You can throw the error to let the caller handle it, or return an error object
        throw error;
      }
    }
  );
    interface GlobalEquipments {}

  interface ApiState {
    globalequipments: GlobalEquipments | null;
    loading: boolean;
    error: string | null;
  }
  
  const initialState: ApiState = {
    globalequipments: null,
    loading: false,
    error: null,
  };
  

  const fetchGlobalEquipmentsSlice = createSlice({
    name: 'fetchGlobalEquipments',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchGlobalEquipments.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(fetchGlobalEquipments.fulfilled, (state, action) => {
          state.loading = false;
          state.globalequipments = action.payload;
          console.log("state.services",state.globalequipments)
        })
        .addCase(fetchGlobalEquipments.rejected, (state, action) => {
          state.loading = false;
          state.error = action.error.message || 'Failed to fetch services';
        })
       
    },
  });
  const fetchGlobalEquipmentsReducer=fetchGlobalEquipmentsSlice.reducer
  export default fetchGlobalEquipmentsReducer;