import { useState } from "react";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import Logo from "../assets/images/Polampani_logo.svg";
import "../css/login.css";
import { validateEmail, validatePassword } from "../constants";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../redux/store";
import { login } from "../redux/Slices/login";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import LoaderGif from "../assets/images/loader.gif";

const LoginInPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [errorState, setErrorState] = useState({
    state: false,
    message: "",
  });
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleOnClickLogin = () => {
    if (
      validateEmail(loginData.email) &&
      validatePassword(loginData.password)
    ) {
      setErrorState({ ...errorState, state: false });
      setLoading(true); // Show loader immediately

      // Simulate delay with setTimeout (2 seconds)
      setTimeout(async () => {
        try {
          const resData: any = await dispatch(
            login({ email: loginData.email, password: loginData.password })
          );
          if (resData.error) {
            setErrorState({
              ...errorState,
              state: true,
              message: `${resData.payload.error}`,
            });
          } else {
            navigate("/home");
          }
        } catch (error) {
          console.error("Error logging in:", error);
          setErrorState({
            ...errorState,
            state: true,
            message: "An error occurred during login",
          });
        } finally {
          setLoading(false); 
        }
      }, 2000); 
    } else {
      setErrorState({
        ...errorState,
        state: true,
        message: "Invalid email or password",
      });
    }
  };

  return (
    <>
      {loading ? (
        <div className="flex flex-col items-center justify-center mt-[100px]">
          <img src={Logo} alt="logo" />
          <img src={LoaderGif} alt="loading" className="h-[50vh] w-[100%]"/>
        </div>
      ) : (
        <div className="login flex items-center justify-center h-screen ">
          <div className="w-[598px] h-[672px] bg-white shadow-md rounded-lg flex gap-5 flex-col items-center justify-center p-7">
            <img src={Logo} alt="logo" />
            <p className="font-inter text-20 font-bold leading-24.2 text-left">
              Admin Login
            </p>
            <label className="mb-2 text-14 font-semi-bold leading-24.2 text-left">
              {"Please enter your Login details"}
            </label>
            {errorState.state && (
              <div
                className="h-[40px] flex gap-1 items-center bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                role="alert"
              >
                <strong className="font-bold">Error!</strong>
                <span className="block sm:inline"> {errorState.message}</span>
              </div>
            )}
            <div className="flex gap-3 flex-col items-center justify-center">
              <input
                onChange={(e) => {
                  setLoginData({ ...loginData, email: e.target.value.toLocaleLowerCase() });
                }}
                type="email"
                placeholder="Email or UserID"
                className="w-[280px] h-[43px] p-5 border border-dark-green rounded-md focus:outline-none"
              />
              <div className="flex items-center w-[280px] h-[43px] border border-dark-green rounded-md p-2">
                <input
                  onChange={(e) => {
                    setLoginData({ ...loginData, password: e.target.value });
                  }}
                  type={showPassword ? "text" : "password"}
                  placeholder="password"
                  className="flex-grow h-full rounded focus:outline-none pl-2"
                />
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  className="h-full"
                >
                  {showPassword ? (
                    <VisibilityOutlinedIcon />
                  ) : (
                    <VisibilityOffOutlinedIcon />
                  )}
                </IconButton>
              </div>
            </div>
            <button
              onClick={handleOnClickLogin}
              className="w-[280px] h-[43px] flex items-center justify-center bg-dark-green text-white font-bold rounded-md focus:outline-none focus:ring-2"
            >
              <p className="font-inter text-14 font-semi-bold leading-16.4 text-left">
                Login
              </p>
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default LoginInPage;