import React, { useEffect, useState } from "react";
import Layout from "./Layout";
import withLayout from "./withLayout";
import { AppDispatch } from "../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllFarmers } from "../redux/Slices/getAllFarmers";
import { useNavigate } from "react-router-dom";
import FarmerDetails from "./FarmerDetails";
import enter from "../assets/images/enter.png";
import { blue } from "@mui/material/colors";

const Farmers = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const farmersDetails = useSelector((state: any) => state.farmersDetails.farmersDetails);
  const farmerpagination = useSelector((state: any) => state.farmerpagination.farmerpagination);
  const error = useSelector((state: any) => state.farmersDetails.error);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [usernameFilter, setUsernameFilter] = useState('');
  const [phoneFilter, setPhoneFilter] = useState('');
  const [pincodeFilter, setPincodeFilter] = useState('');
  const [villageFilter, setVillageFilter] = useState('');
  const [farmerIdFilter, setFarmerIdFilter] = useState('');
  const [noData, setNoData] = useState(false);

  const limit = 10;

  useEffect(() => {
    dispatch(fetchAllFarmers({ admin_id: 1, page: currentPage, limit: limit, usernameFilter, phoneFilter, pincodeFilter, villageFilter, farmerIdFilter }));
  }, [currentPage, dispatch]);

  useEffect(() => {
    if (farmerpagination && farmerpagination.totalPages) {
      setTotalPages(farmerpagination.totalPages);
    }
  }, [farmerpagination]);

  useEffect(() => {
    if (error) {
      setNoData(true);
    } else {
      setNoData(false);
    }
  }, [error]);

  const handleViewFarmer = (id: number) => {
    navigate(`/farmerdetails/${id}/`);
  };



  const handleSearch = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      setCurrentPage(1); // Reset to first page for new search
      dispatch(fetchAllFarmers({ admin_id: 1, page: 1, limit, usernameFilter, phoneFilter, pincodeFilter, villageFilter, farmerIdFilter }));
    }
  };

  const handleFilterChange = (setter: React.Dispatch<React.SetStateAction<string>>) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setter(event.target.value);
  };

  const filteredFarmers = farmersDetails; 
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(prevPage => prevPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(prevPage => prevPage + 1);
    }
  };
  const performSearch = () => {
    setCurrentPage(1); // Reset to first page for new search
    dispatch(fetchAllFarmers({ admin_id: 1, page: 1, limit, usernameFilter, phoneFilter, pincodeFilter, villageFilter, farmerIdFilter }));
  };
  return (
    <div className="h-full overflow-y-auto">
      <div className="grid grid-cols-6">
        {["Farmer ID", "Username", "Phone Number", "Pincode", "Village", "Action"].map((heading, index) => (
        
          <div key={index} className="bg-medium-green p-4 font-bold h-[10vh]">
            {heading === 'Username' ? (
               <div className="input-container">
              <input
                type="text"
                value={usernameFilter}
                onChange={handleFilterChange(setUsernameFilter)}
                onKeyDown={handleSearch}
                placeholder="Username"
                className="w-full p-2 border-none focus:outline-none focus:border-none hover:border-none hover:outline-none "

              />
                <button className="input-icon w-7 h-7 mr-3" onClick={performSearch}>
                  <img src={enter} alt="icon" className="w-full" />
                </button>

              </div>
            ) : heading === 'Phone Number' ? (

              <div className="input-container" >
           
              <input
                type="text"
                value={phoneFilter}
                onChange={handleFilterChange(setPhoneFilter)}
                onKeyDown={handleSearch}
                placeholder="Phone Number"
                className="w-full p-2 border-none focus:outline-none focus:border-none hover:border-none hover:outline-none "
               
              />
            
              <button className="input-icon w-7 h-7 mr-3" onClick={performSearch}>
                  <img src={enter} alt="icon" className="w-full" />
                </button>
 
    
            </div>
            ) : heading === 'Pincode' ? (
              <div className="input-container">
              <input
                type="text"
                value={pincodeFilter}
                onChange={handleFilterChange(setPincodeFilter)}
                onKeyDown={handleSearch}
                placeholder="Pincode"
                className="w-full p-2 border-none focus:outline-none focus:border-none hover:border-none hover:outline-none "

              />
              <button className="input-icon w-7 h-7 mr-3" onClick={performSearch}>
                  <img src={enter} alt="icon" className="w-full" />
                </button>

              </div>
            ) : heading === 'Village' ? (
              <div className="input-container">

              <input
                type="text"
                value={villageFilter}
                onChange={handleFilterChange(setVillageFilter)}
                onKeyDown={handleSearch}
                placeholder="Village"
                className="w-full p-2 border-none focus:outline-none focus:border-none hover:border-none hover:outline-none "

              />
              <button className="input-icon w-7 h-7 mr-3" onClick={performSearch}>
                  <img src={enter} alt="icon" className="w-full" />
                </button>

              </div>
            ) : heading === 'Farmer ID' ? (
              <div className="input-container">

              <input
                type="text"
                value={farmerIdFilter}
                onChange={handleFilterChange(setFarmerIdFilter)}
                onKeyDown={handleSearch}
                placeholder="Farmer ID"
                className="w-full p-2 border-none focus:outline-none focus:border-none hover:border-none hover:outline-none "

              />
                <button className="input-icon w-7 h-7 mr-3" onClick={performSearch}>
                  <img src={enter} alt="icon" className="w-full" />
                </button>
              </div>
            ) : heading}
          </div>
        ))}

{filteredFarmers && filteredFarmers.length > 0 ? (
  filteredFarmers.map((farmer: any, index: any) => (
    <React.Fragment key={index}>
      <div className="border-b-[2px] border-medium-green p-4">{farmer.farmer_id}</div>
      <div className="border-b-[2px] border-medium-green p-4">{farmer.username}</div>
      <div className="border-b-[2px] border-medium-green p-4">{farmer.phonenumber}</div>
      <div className="border-b-[2px] border-medium-green p-4">{farmer.pincode}</div>
      <div className="border-b-[2px] border-medium-green p-4">{farmer.village}</div>
      <div className="border-b-[2px] border-medium-green p-4">
        <div className="bg-dark-blue flex border-dark-blue border-2 w-1/2 h-full rounded-md items-center justify-center cursor-pointer" onClick={() => handleViewFarmer(farmer.farmer_id)}>
          <button className="text-white font-medium rounded" >View</button>
        </div>
      </div>
    </React.Fragment>
  ))
) : (
  <div className="col-span-6 flex items-center justify-center  mt-[15%]">No farmers available</div>
)}
      </div>

      <div className="flex fixed bottom-7 right-0 justify-between px-10">
        <button
          className={`px-4 py-2 rounded mr-2 ${currentPage === 1 ? 'bg-gray-300 cursor-not-allowed' : 'bg-dark-green text-white'}`}
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <button
          className={`px-4 py-2 rounded ${currentPage === totalPages ? 'bg-gray-300 cursor-not-allowed' : 'bg-dark-green text-white'}`}
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default withLayout(Farmers, "Farmers");
