import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import webConfig from '../../api-request/webConfig';

export const assignProvider = createAsyncThunk(
    'api/assignProvider',
    async ({ eqpregistration_id,
        farmerbooking_id,
        equipmenttype_id,
        service_id,
        requested_date,
        requested_timeslots,
        booking_status,}: { eqpregistration_id:number | null,
            farmerbooking_id:number,
            equipmenttype_id:number,
            service_id:number,
            requested_date:string,
            requested_timeslots:[],
            booking_status:string,}, thunkAPI) => {
      try {
        console.log("😂😂😂😂jdjdjf",requested_date)
       
        const response = await axios.post(`${webConfig.API_DOMAIN_URL}/admin/assign`,{eqpregistration_id,
            farmerbooking_id,
            equipmenttype_id,
            service_id,
            requested_date,
            requested_timeslots,
            booking_status});
        console.log("assignProvider", response.data);
        return response.data;
      } catch (error) {
        console.error('Error posting provider:', error);
        // You can throw the error to let the caller handle it, or return an error object
        throw error;
      }
    }
  );