export const IMG={

Logo:require("../assets/images/Polam pani Logo-White-28.png"),
HOme_ICon:require("../assets/images/Home.svg"),
Farmer_icon:require("../assets/images/farmer.png"),
Booking_icon:require("../assets/images/Booking_Icon.png"),
Equipment_icon:require("../assets/images/EquipmentLogo.svg"),
Provider:require("../assets/images/provider_icon.png"),
Support:require("../assets/images/support_Icon.png"),
LogOut:require("../assets/images/LogOut_icon.png"),
RightICon:require("../assets/images/right_ICon.png"),
Enter:require("../assets/images/enter.png"),
}