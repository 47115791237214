import React, { useEffect, useState } from "react";
import Layout from "./Layout";
import { useDispatch, useSelector } from "react-redux";
import { fetchSupportRequests } from "../redux/Slices/getSupportRequests";
import { updateSupportStatus } from "../redux/Slices/updateSupportStatus";
import withLayout from "./withLayout";
import { AppDispatch } from "../redux/store";
import enter from "../assets/images/enter.png";

const SupportRequests = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [userTypeFilter, setUserTypeFilter] = useState("");
  const [userIdFilter, setUserIdFilter] = useState("");
  const [userNameFilter, setUserNameFilter] = useState("");
  const [phoneNumberFilter, setPhoneNumberFilter] = useState("");
  const [dateFilter, setDateFilter] = useState("");
  const [messageFilter, setMessageFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState(""); // New status filter state
  const dispatch = useDispatch<AppDispatch>();
  const requests = useSelector((state: any) => state.requests.requests);
  const limit = 5;
  const [focused, setFocused] = useState({
    userType: false,
    userId: false,
    userName: false,
    phoneNumber: false,
    date: false,
    message: false,
  });

  useEffect(() => {
    dispatch(
      fetchSupportRequests({
        admin_id: 1,
        page: currentPage,
        limit,
        user_type: userTypeFilter,
        user_id: userIdFilter,
        username: userNameFilter,
        phone_number: phoneNumberFilter,
        support_raised_date: dateFilter,
        message: messageFilter,
        support_status: statusFilter, // Pass status filter to API
      })
    );
  }, [currentPage, dispatch, userTypeFilter, userIdFilter, userNameFilter, phoneNumberFilter, dateFilter, messageFilter, statusFilter]);
  
  useEffect(() => {
    if (requests) {
      setTotalPages(requests.totalPages);
    }
  }, [requests]);

  const headings = ["User Type", "User Id", "User Name", "Phone Number", "Date", "Message", "Status"]; // Added "Status" to headings

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handleFilterChange = (setFilter: React.Dispatch<React.SetStateAction<string>>) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value);
  };

  const handleStatusChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setStatusFilter(event.target.value);
  };
  
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      performSearch();
    }
  };

  const performSearch = () => {
    setCurrentPage(1);
    dispatch(
      fetchSupportRequests({
        admin_id: 1,
        page: currentPage,
        limit,
        user_type: userTypeFilter,
        user_id: userIdFilter,
        username: userNameFilter,
        phone_number: phoneNumberFilter,
        support_raised_date: dateFilter,
        message: messageFilter,
        support_status: statusFilter,
      })
    );
  };

  const handleFocus = (field: string) => {
    setFocused((prevState) => ({ ...prevState, [field]: true }));
  };

  const handleBlur = (field: string) => {
    setFocused((prevState) => ({ ...prevState, [field]: false }));
  };

  const handleStatusUpdate = (index: number, newStatus: string) => {
    const supportRequest = requests.supportrequest[index];
    dispatch(updateSupportStatus({ support_id: supportRequest.support_id, support_status: newStatus }))
      .unwrap()
      .then((updatedRequest) => {
        const updatedRequests = [...requests.supportrequest];
        updatedRequests[index] = updatedRequest;
        console.log(`Support request at index ${index} status changed to ${newStatus}`);
      })
      .catch((error) => {
        console.error('Failed to update support status:', error);
      });
  };




  return (
    <div className="h-full overflow-y-auto">
      <div className="grid grid-cols-7">
        {headings.map((heading, index) => (
          <div key={index} className="border-b-[2px] bg-medium-green border-medium-green p-4 font-bold">
            {heading !== "Status" ? (
              <div className="input-container">
                <input
                  type={
                    heading === 'Date' && !focused.date
                      ? 'text'
                      : heading === 'Date' && focused.date
                      ? 'date'
                      : 'text'
                  }
                  value={
                    heading === 'User Type'
                      ? userTypeFilter
                      : heading === 'User Id'
                      ? userIdFilter
                      : heading === 'User Name'
                      ? userNameFilter
                      : heading === 'Phone Number'
                      ? phoneNumberFilter
                      : heading === 'Date'
                      ? dateFilter
                      : messageFilter
                  }
                  onChange={
                    heading === 'User Type'
                      ? handleFilterChange(setUserTypeFilter)
                      : heading === 'User Id'
                      ? handleFilterChange(setUserIdFilter)
                      : heading === 'User Name'
                      ? handleFilterChange(setUserNameFilter)
                      : heading === 'Phone Number'
                      ? handleFilterChange(setPhoneNumberFilter)
                      : heading === 'Date'
                      ? handleFilterChange(setDateFilter)
                      : handleFilterChange(setMessageFilter)
                  }
                  onKeyPress={handleKeyPress}
                  onFocus={() => handleFocus(heading.toLowerCase().replace(' ', ''))}
                  onBlur={() => handleBlur(heading.toLowerCase().replace(' ', ''))}
                  placeholder={heading}
                  className="w-full p-2 border-none focus:outline-none focus:border-none hover:border-none hover:outline-none"
                />
                <button className="input-icon w-7 h-7 mr-3" onClick={performSearch}>
                  <img src={enter} alt="icon" className="w-full" />
                </button>
              </div>
            ) : (
              <select
                value={statusFilter}
                onChange={handleStatusChange}
                className="w-full p-2 border-none focus:outline-none focus:border-none hover:border-none hover:outline-none"
              >
                <option value="">All</option>
                <option value="pending">Pending</option>
                <option value="resolved">Resolved</option>
              </select>
            )}
          </div>
        ))}
      </div>

      {requests && requests.supportrequest.length > 0 ? (
        <div className="grid grid-cols-7">
          {requests.supportrequest.map((item: any, index: any) => {
            if (statusFilter && item.support_status !== statusFilter) {
              return null; // Skip rendering if status doesn't match filter
            }
            const date = item.support_raised_date ? new Date(item.support_raised_date) : null;
            const formattedDate = date ? date.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }) : "";
            return (
              <React.Fragment key={index}>
                <div className="border-b-[2px] border-medium-green p-4">{item.user_type}</div>
                <div className="border-b-[2px] border-medium-green p-4">{item.user_id}</div>
                <div className="border-b-[2px] border-medium-green p-4">{item.username}</div>
                <div className="border-b-[2px] border-medium-green p-4">{item.phone_number}</div>
                <div className="border-b-[2px] border-medium-green p-4">{formattedDate}</div>
                <div className="border-b-[2px] border-medium-green p-4">{item.message}</div>
                <div className="border-b-[2px] border-medium-green p-4">
                  <select
                    value={item.support_status}
                    onChange={(e) => handleStatusUpdate(index, e.target.value)}
                  >
                    <option value="pending">Pending</option>
                    <option value="resolved">Resolved</option>
                  </select>
                </div>
              </React.Fragment>
            );
          })}
        </div>
      ) : (
        <div>No support requests available</div>
      )}


      <div className="flex fixed bottom-7 right-0 justify-between px-10">
        <button
          className={`px-4 py-2 rounded mr-2 ${currentPage === 1 ? 'bg-gray-300 cursor-not-allowed' : 'bg-dark-green text-white'}`}
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <button
          className={`px-4 py-2 rounded ${currentPage === totalPages ? 'bg-gray-300 cursor-not-allowed' : 'bg-dark-green text-white'}`}
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default withLayout(SupportRequests, "Support Requests");
