import React from "react";

interface PropsType {
  label: string;
  handleClick: (e:any) => void;
  isChecked: boolean;
}

const CustomeCheckBox = ({ label, handleClick, isChecked }: PropsType) => {
  return (
    <div className="flex items-center">
      <input
        onClick={handleClick}
        checked={isChecked}
        type="checkbox"
        id="option3"
        className={`mr-2 rounded border-green-500 focus:ring-0 ${
          isChecked ? "border-green-500 bg-green-500" : ""
        }`}
      />
      <label
        htmlFor="option3"
        className="text-gray-700 font-inter font-semi-bold"
      >
        {label}
      </label>
    </div>
  );
};

export default CustomeCheckBox;
