import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import webConfig from '../../api-request/webConfig';
export const fetchSuitableEqpChoices = createAsyncThunk(
    'api/fetchSuitableEqpChoices',
    async ({ farmerbooking_id,equipmenttype_id,requested_date,requested_timeslots}: { equipmenttype_id:number,farmerbooking_id:number,requested_date:Date,requested_timeslots:number[]}, thunkAPI) => {
      try {
        console.log("API USEEEEEEEEEEEEEEEEEEEEEEE");
        const response = await axios.get(`${webConfig.API_DOMAIN_URL}/admin/getSuitableEquipments?farmerbooking_id=${farmerbooking_id}&equipmenttype_id=${equipmenttype_id}&requested_date=${requested_date}&requested_timeslots=${requested_timeslots.join(',')}`);
        console.log("response of getSuitableEquipments ", response.data);
        return response.data;
      } catch (error) {
        console.error('Error fetching getSuitableEquipments : not exist', error);
        // You can throw the error to let the caller handle it, or return an error object
        throw error;
      }
    }
  );
  
    interface EquipmentChoices {
   
    }
  interface ApiState {
    equipmentchoices: EquipmentChoices | null;
    loading: boolean;
    error: string | null;
    authToken:string | null;
  }
  
  const initialState: ApiState = {
    equipmentchoices: null,
    loading: false,
    error: null,
    authToken:null
  };
  

  const fetchSuitableEqpChoicesSlice = createSlice({
    name: 'fetchChoices ',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchSuitableEqpChoices.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(fetchSuitableEqpChoices.fulfilled, (state, action) => {
          state.loading = false;
       
          state.equipmentchoices = action.payload;
        
      
        })
        .addCase(fetchSuitableEqpChoices.rejected, (state, action) => {
          state.loading = false;
          state.error = action.error.message || 'Failed to fetch user';
        })
       
    },
  });
  const fetchSuitableEqpChoicesReducer=fetchSuitableEqpChoicesSlice.reducer
  export default fetchSuitableEqpChoicesReducer;