import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import webConfig from "../../api-request/webConfig";

export const fetchAllServices = createAsyncThunk(
  "api/fetchAllServices",

  async ({ page, limit }:{ page: any; limit: any },thunkAPI) => {
    try {
      const resData = await axios
        .get(`${webConfig.API_DOMAIN_URL}/constants/services/?page=${page || ''}&limit=${limit || ''}`)
      console.log("response of services", resData);
      return resData.data;
      return resData;
    } catch (error: any) {
      console.error("Error unable to get the service data", error);
  
    }
  }
);


const initialState = {
  servicesDetails: [],
  paginationDataServices: [],
  loading: false,
  error: null,
  page: 1,
  limit: 10,
  total: 0,
};

// Create the slice
const servicesSlice = createSlice({
  name: "services",
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setLimit: (state, action) => {
      state.limit = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllServices.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllServices.fulfilled, (state, action) => {
        state.loading = false;
        console.log(action);
        state.servicesDetails = action.payload.globalservices;
        state.paginationDataServices = action.payload.pagination;
      
        state.total = 1;
      })
      .addCase(fetchAllServices.rejected, (state: any, action) => {
        state.loading = false;
        state.error = action.payload || "Something went wrong";
      });
  },
});

// Export actions and reducer
export const { setPage, setLimit } = servicesSlice.actions;
const ServicesReducer = servicesSlice.reducer;
export default ServicesReducer;