import { configureStore } from "@reduxjs/toolkit";
import fetchAllFarmersReducer from "./Slices/getAllFarmers";
import fetchAllProvidersReducer from "./Slices/getAllProviders";
import fetchAllBookingsReducer, {
  fetchAllBookings,
} from "./Slices/getAllBookings";
import fetchBookingDetailsbyIdReducer from "./Slices/getBookingDetailsbyId";
import fetchLandsReducer from "./Slices/getFarmerLands";
import fetchEquipmentrelatedServicesReducer from "./Slices/getRelatedEqpServices";
import fetchCountsReducer from "./Slices/getCounts";
import fetchSupportRequestsReducer from "./Slices/getSupportRequests";
import fetchProviderEquipmentSliceReducer from "./Slices/getProviderEquipments";
import fetchPostalVillagesbyApiReducer from "./Slices/getPostalVillages";
import fetchUserReducer from "./Slices/findUserSlice";
import fetchServicesReducer from "./Slices/fetchEqprelatedServices";
import fetchSuitableEqpChoicesReducer from "./Slices/getSuitableEquipmentsList";
import authImp from "./Slices/login";
import ServicesReducer from "./Slices/getAllServicesSlice";
import createEquipmentReducer from "./Slices/createEquipment";
import addServiceReducer from "./Slices/createService";
import updateServicesReducer from "./Slices/updateService";
import fetchEquipmentsCountReducer from "./Slices/getEquipmentsCount";
import getStats from "./Slices/dashboardSlice";
import fetchGlobalEquipmentsReducer from "./Slices/fetchglobalEquipments";
import fetchProfileImageUriReducer from "./Slices/getImage";

const store = configureStore({
  reducer: {
    farmersDetails: fetchAllFarmersReducer,
    farmerpagination: fetchAllFarmersReducer,
    bookingPagination: fetchAllBookingsReducer,
    providersDetails: fetchAllProvidersReducer,
    providerPageData: fetchAllProvidersReducer,
    bookingsDetails: fetchAllBookingsReducer,
    bookingDetailsbyId: fetchBookingDetailsbyIdReducer,
    lands: fetchLandsReducer,
    eqpservices: fetchEquipmentrelatedServicesReducer,
    count: fetchCountsReducer,
    postoffice: fetchPostalVillagesbyApiReducer,
    requests: fetchSupportRequestsReducer,
    equipments: fetchProviderEquipmentSliceReducer,
    user: fetchUserReducer,
    userDetails: fetchUserReducer,
    services: fetchServicesReducer,
    equipmentchoices: fetchSuitableEqpChoicesReducer,
    auth: authImp,
    servicesDetails: ServicesReducer,
    createEquipment: createEquipmentReducer,
    createService : addServiceReducer,
    updateService : updateServicesReducer,
    equipmentscount:fetchEquipmentsCountReducer,
    paginationDataServices:ServicesReducer,
    getDashboardStats : getStats,
    globalequipments:fetchGlobalEquipmentsReducer,
    profileimageUri:fetchProfileImageUriReducer
  },
});
export default store;
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
