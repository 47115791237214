// servicesSlice.ts
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import webConfig from '../../api-request/webConfig';

// Async thunk for updating a service
export const updateService = createAsyncThunk(
  'api/updateService',
  async ({ id, serviceData }: { id: number, serviceData: any }, thunkAPI) => {
    try {
      const response = await axios.put(`${webConfig.API_DOMAIN_URL}/constants/updateService/${id}`, serviceData);
      console.log("Update service response:", response.data);
      return response.data;
    } catch (error) {
      console.error('Error updating service:', error);
      throw error;
    }
  }
);

// Initial state for the slice
interface Service {
  id: number;
  name: string;
  status: string;
  image: string;
}

interface ServicesState {
  services: Service[];
  loading: boolean;
  error: string | null;
}

const initialState: ServicesState = {
  services: [],
  loading: false,
  error: null,
};

const servicesSlice = createSlice({
  name: 'services',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateService.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateService.fulfilled, (state, action) => {
        state.loading = false;
        const updatedService = action.payload;
        const existingServiceIndex = state.services.findIndex(service => service.id === updatedService.id);
        if (existingServiceIndex !== -1) {
          state.services[existingServiceIndex] = updatedService;
        }
      })
      .addCase(updateService.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to update service';
      });
  },
});

const updateServicesReducer = servicesSlice.reducer;
export default updateServicesReducer;
