import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import webConfig from '../../api-request/webConfig';

export const getEquipmentsCount = createAsyncThunk(
    'api/getEquipmentsCount',
    async ({equipmenttype_id,requested_date,farmercancel_status}:{equipmenttype_id:number,requested_date:Date,farmercancel_status:number}, thunkAPI) => {
      try {
        console.log("equipmenttype_id",equipmenttype_id)
        const response = await axios.get(`${webConfig.API_DOMAIN_URL}/farmer/getEquipmentsCount/${equipmenttype_id}/${requested_date}/${farmercancel_status}`);
        console.log("response", response.data);
        return response.data;
      } catch (error) {
        console.error('Error fetching equipmenttype_id:', error);
       
        throw error;
      }
    }
  );
    interface EquipmentsCount {}

  interface ApiState {
    equipmentscount: EquipmentsCount | null;
    loading: boolean;
    error: string | null;
  }
  
  const initialState: ApiState = {
    equipmentscount: null,
    loading: false,
    error: null,
  };
  

  const fetchEquipmentsCountSlice = createSlice({
    name: 'fetchEquipmentscount',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(getEquipmentsCount.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(getEquipmentsCount.fulfilled, (state, action) => {
          state.loading = false;
          state.equipmentscount = action.payload;
        })
        .addCase(getEquipmentsCount.rejected, (state, action) => {
          state.loading = false;
          state.error = action.error.message || 'Failed to fetch eqps';
        })
      
    },
  });
  const fetchEquipmentsCountReducer=fetchEquipmentsCountSlice.reducer
  export default fetchEquipmentsCountReducer;