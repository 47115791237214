import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import webConfig from "../../api-request/webConfig";

// Define the async thunk
export const fetchDashboardStats = createAsyncThunk(
  "api/fetchDashboardStats",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(
        `${webConfig.API_DOMAIN_URL}/constants/getStats`
      );
      return response.data;
    } catch (error: any) {
      console.error("Error unable to get the booking counts", error);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Initial state
interface BookingCountsState {
  activeServiceCount: number;
  totalBookings: number;
  todayBookings: number;
  loading: boolean;
  error: string | null;
}

const initialState: BookingCountsState = {
  activeServiceCount: 0,
  totalBookings: 0,
  todayBookings: 0,
  loading: false,
  error: null,
};

// Create the slice
const StatsSlice = createSlice({
  name: "bookingCounts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDashboardStats.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDashboardStats.fulfilled, (state, action) => {
        state.loading = false;
        console.log(action);
        state.activeServiceCount = action.payload.activeServiceCount;
        state.totalBookings = action.payload.totalBookings;
        state.todayBookings = action.payload.todayBookings;
      })
      .addCase(fetchDashboardStats.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

const getStats = StatsSlice.reducer;
export default getStats;
