import React, { useEffect, useState } from "react";
import "./App.css";
import Layout from "./layouts/Layout";
import Header from "./layouts/Header";
import Home from "./layouts/Home";
import Sidebar from "./layouts/Sidebar";
import Navigation from "./layouts/Navigation";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import Bookings from "./layouts/Bookings";
import Farmers from "./layouts/Farmers";
import Providers from "./layouts/Providers";
import BookingDetails from "./layouts/BookingDetails";
import FarmerDetails from "./layouts/FarmerDetails";
import Equipments from "./layouts/Equipments";
import SupportRequests from "./layouts/SupportRequests";
import ProviderDetails from "./layouts/ProviderDetails";
import CreateProvider from "./layouts/CreateProvider";
import ProviderAssign from "./layouts/ProviderAssign";
import LoginInPage from "./layouts/LoginIn";
import { useSelector } from "react-redux";
import { RootState } from "./redux/store";
import AddEquipment from "./layouts/AddEquipment";
import ProviderReschedule from "./layouts/ProviderReschedule";
import Services from "./layouts/Services";
import Reschedule from "./layouts/Reschedule";
import Payment from "./layouts/PaymentRedirecting";
//import ProviderReschedule from "./layouts/ProviderReschedule";

const App: React.FC = () => {
  const navigate = useNavigate();
  const authState = useSelector((state: RootState) => state.auth);
  const [isAuthenticated, setIsAunthenticated] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      setIsAunthenticated(true);
    }
  }, [isAuthenticated]);

  useEffect(()=>{
    if(!authState.isLoggedIn){
      navigate("/login");
    }else{
      
    }
  },[authState]);
  

  return (
    <div className="max-h-screen flex flex-col">
      {authState.isLoggedIn ? (
        <>
           <Header />
          <div className="flex flex-1 overflow-hidden">
            <div className="w-full bg-slate-500 sm:w-1/8 lg:w-1/5 flex-shrink-0" >
            <Sidebar /></div>
            
            <div className="flex-1 overflow-y-auto p-4 h-full"> 
              <Routes>
                <Route path="/home" element={<Home />}></Route>
                <Route path="/bookings" element={<Bookings />}></Route>
                <Route
                  path="/booking-details/:farmerbooking_id/:provider_id"
                  element={<BookingDetails />}
                />
                <Route path="/farmers" element={<Farmers />} />
                <Route
                  path="/farmerdetails/:farmer_id/"
                  element={<FarmerDetails />}
                />
                <Route path="/providers" element={<Providers />} />
                <Route path="/services" element={<Services />} />
                <Route path="/equipments" element={<Equipments />} />
                <Route path="/support" element={<SupportRequests />} />
                <Route
                  path="/providerdetails/:provider_id/"
                  element={<ProviderDetails />}
                />
                <Route path="/CreateProvider" element={<CreateProvider />} />
                <Route path="/provider-assign/:farmerbooking_id" element={<ProviderAssign />} />
                {/* <Route path="/provider-reschedule" element={<ProviderReschedule />} /> */}
                <Route path="/AddEquipment/:provider_id/" element={<AddEquipment/>}/> 
                <Route path="/provider-reschedule/:farmerbooking_id/:provider_id" element={<Reschedule/>}/> 
                <Route path="*" element={<Navigate to="/home" />} />
                <Route path="/payment/" element={<Payment/>}/>

              </Routes>
            </div>
          </div>
        </>
      ) : (
        <>
          <Routes>
            <Route path="/login" element={<LoginInPage />} />
            <Route path="/payment/" element={<Payment/>}/>
          </Routes>
        </>
      )}
    </div>
  );
};

export default App;
