import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import webConfig from '../../api-request/webConfig';

export const fetchAllFarmers = createAsyncThunk(
  'api/fetchAllFarmers',
  async ({ admin_id, page, limit, usernameFilter, phoneFilter, pincodeFilter, villageFilter, farmerIdFilter }: 
         { admin_id: number, page: number, limit: number, usernameFilter?: string, phoneFilter?: string, pincodeFilter?: string, villageFilter?: string, farmerIdFilter?: string }, thunkAPI) => {
      try {
          const response = await axios.get(`${webConfig.API_DOMAIN_URL}/admin/getallfarmers/${admin_id}?page=${page}&limit=${limit}&usernameFilter=${usernameFilter || ''}&phoneFilter=${phoneFilter || ''}&pincodeFilter=${pincodeFilter || ''}&villageFilter=${villageFilter || ''}&farmerIdFilter=${farmerIdFilter || ''}`);
          return response.data;
      } catch (error) {
          console.error('Error fetching farmers:', error);
          throw error;
      }
  }
);

interface FarmersDetails {}

interface ApiState {
  farmersDetails: FarmersDetails | null;
  farmerpagination: {} | null;
  loading: boolean;
  error: string | null;
  authToken: string | null;
}

const initialState: ApiState = {
  farmersDetails: null,
  farmerpagination: null,
  loading: false,
  error: null,
  authToken: null
};

const fetchAllFarmersSlice = createSlice({
  name: 'fetchAllFarmers',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
      builder
          .addCase(fetchAllFarmers.pending, (state) => {
              state.loading = true;
              state.error = null;
          })
          .addCase(fetchAllFarmers.fulfilled, (state, action) => {
              state.loading = false;
              state.farmersDetails = action.payload.farmers;
              state.farmerpagination = action.payload.pagination;
          })
          .addCase(fetchAllFarmers.rejected, (state, action) => {
              state.loading = false;
              state.error = action.error.message || 'Failed to fetch farmers';
          });
  },
});

const fetchAllFarmersReducer = fetchAllFarmersSlice.reducer;
export default fetchAllFarmersReducer;
