import React from "react";
import Layout from "./Layout";

import { IMG } from "../constants/imgs";
import Navigation from "./Navigation";
const Sidebar = () => {
  return (
    <div className="sidebar-container justify-center items-center ">
 <Navigation />
  </div>
  )
}

export default Sidebar;
