import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import webConfig from "../../api-request/webConfig";

export const fetchEqprelatedServices = createAsyncThunk(
  "api/fetchEqprelatedServices",
  async ({ admin_id ,page, limit}: { admin_id: number, page: number, limit: number }, thunkAPI) => {
    try {
      const response = await axios.get(`${webConfig.API_DOMAIN_URL}/admin/ServicesandEquipments/${admin_id}?page=${page}&limit=${limit || ''}`);


      console.log("response", response);
      return response;
    } catch (error) {
      console.error("Error fetching farmer lands:", error);
      // You can throw the error to let the caller handle it, or return an error object
      throw error;
    }
  }
);
interface EqpServices {}

interface ApiState {
  eqpservices: EqpServices | null;
  loading: boolean;
  error: string | null;
}

const initialState: ApiState = {
  eqpservices: null,
  loading: false,
  error: null,
};

const fetchEquipmentandServices = createSlice({
  name: "fetchEqpsandservices",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchEqprelatedServices.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchEqprelatedServices.fulfilled, (state, action) => {
        state.loading = false;
        state.eqpservices = action.payload.data;
      })
      .addCase(fetchEqprelatedServices.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch eqps";
      });
  },
});
const fetchEquipmentrelatedServicesReducer = fetchEquipmentandServices.reducer;
export default fetchEquipmentrelatedServicesReducer;
