import React, { useEffect, useState } from "react";
import Layout from "./Layout";
import { FaRegUser } from "react-icons/fa6";
import { IMG } from "../constants/imgs";
import withLayout from "./withLayout";
import { useParams } from "react-router-dom";
import { AppDispatch } from "../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { fetchfarmerlands } from "../redux/Slices/getFarmerLands";
import LandsCard from "../components/LandsCard";
import { getProfileImageUri } from "../redux/Slices/getImage";
const FarmerDetails = () => {
  const { farmer_id } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const lands = useSelector((state: any) => state.lands.lands);
  const [lat,setLatitude]=useState<string>("")
  const [lng,setLongitude]=useState<string>("")
  const [farmerDetailsContent, setfarmerDetailsContent] = useState<
    { heading: string; value: any }[]
  >([]);
  const profileimageUri=useSelector((state:any)=>state.profileimageUri.profileimageUri)
  const [LandContents, setLandContents] = useState<
    { heading: string; value: any }[]
  >([]);
  const [currentTitle,setCurrentTitle]=useState<string>("")
  const [landTitles, setLandTitles] = useState<string[]>([]);
  

  useEffect(() => {
    if (farmer_id) {
      const convertedFarmerId = parseInt(farmer_id, 10);
      dispatch(fetchfarmerlands({ admin_id: 1, farmer_id: convertedFarmerId }));
    }
  }, []);
  useEffect(() => {
    console.log("lands", lands);
    if (lands && lands.landDetails && lands.landDetails.length > 0) {
      setLandTitles(
        lands.landDetails.map((_: any, index: any) => `Land${index + 1}`)
      );
    }
    dispatch(getProfileImageUri({ userType: "Farmer", phoneNumber: lands?.farmerDetails?.phonenumber }))
    setfarmerDetailsContent([
      { heading: "Name", value: lands?.farmerDetails?.username },
      { heading: "Contact No", value: lands?.farmerDetails?.phonenumber },
      { heading: "Village", value: lands?.farmerDetails?.village },
      { heading: "Pincode", value: lands?.farmerDetails?.pincode },
     
    ]);
  }, [lands]);

  
  const handleTitleClick = (title: string) => {
    console.log("titleeeeeee", title);
    setCurrentTitle(title)
    const index = landTitles.indexOf(title);
    console.log();
    setLatitude(lands?.landDetails[index]?.current_location.latitude)
    setLongitude(lands?.landDetails[index]?.current_location.longitude)
    setLandContents([
      { heading: "Land Name", value: lands?.landDetails[index]?.landname },
      {
        heading: "Survey Number",
        value: lands?.landDetails[index]?.survey_number,
      },
      { heading: "Village", value: lands?.landDetails[index]?.village },
      { heading: "Onwership", value: lands?.landDetails[index]?.ownership },
      { heading: "District", value: lands?.landDetails[index]?.district },
      { heading: "Land Area", value: lands?.landDetails[index]?.land_area },
      { heading: "Location", value: lat + " , "+lng},
    ]);
  };
  useEffect(()=>{
    if(lat && lng && currentTitle ){
      const index = landTitles.indexOf(currentTitle);
      setLandContents([
        { heading: "Land Name", value: lands?.landDetails[index]?.landname },
        {
          heading: "Survey Number",
          value: lands?.landDetails[index]?.survey_number,
        },
        { heading: "Village", value: lands?.landDetails[index]?.village },
        { heading: "Onwership", value: lands?.landDetails[index]?.ownership },
        { heading: "District", value: lands?.landDetails[index]?.district },
        { heading: "Land Area", value: lands?.landDetails[index]?.land_area },
        { heading: "Location", value: lat + " , "+lng},
      ]);
    }
  },[lat,lng])

  return (
    <div className="bg-white h-full w-full p-8">
      <div className="h-8">
        <text className="text-lm font-medium" style={{ fontSize: "20px" }}>
          Farmer Details
        </text>{" "}
      </div>
     
      <div className="flex items-start h-1/2 mt-4">
        <div className="w-1/3 flex  items-center">
          <div className="w-40 h-40 rounded-full bg-light-grey flex justify-center items-center">
          {!profileimageUri ? (
        <img 
          style={{ width: 50, height: 50 }} 
          src="/path/to/placeholder/image.png" // Provide the path to your placeholder image
          alt="Placeholder Image"
        />
      ) : (
        <img 
          className="w-30 h-30 rounded-full"
          src={profileimageUri} 
          alt="Profile Image"
        />
      )}
          </div>
        </div>
        <div className="flex  w-1/4 bg-backdrop-grey justify-center items-center">
          <div className=" w-full p-4 grid grid-cols-1 gap-4 ">
            {Array.isArray(farmerDetailsContent) &&
              farmerDetailsContent.length > 0 &&
              farmerDetailsContent.map((item, index) => (
                <div key={index} className="mb-2 flex flex-row justify-between">
                  <h3 className="text-sm font-medium">{item.heading}</h3>
                  <p className="text-sm">{item.value}</p>
                </div>
              ))}
          </div>
        </div>
      </div>
      <hr style={{ width: "100%", height: "2px", backgroundColor: "green" }} />
      <div className="h-8 mt-6">
        <text className="text-lm font-medium" style={{ fontSize: "20px" }}>
          Land Details
        </text>{" "}
      </div>
      <div className="flex flex-row  overflow-x-hidden ">
        <LandsCard
          title="farmers"
          titles={landTitles}
          contents={landTitles.length>0 ? LandContents : []}
          onTitleClick={(title: string) => handleTitleClick(title)}
        />
      </div>
    </div>
  );
};

export default withLayout(FarmerDetails, "Farmers");
