import React, { useEffect, useState } from "react";
import Layout from "./Layout";
import { IMG } from "../constants/imgs";
import withLayout from "./withLayout";
import { AppDispatch } from "../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fetchAllProviders } from "../redux/Slices/getAllProviders";
import { useNavigate } from "react-router-dom";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import BookingDetails from "./BookingDetails";
import enter from "../assets/images/enter.png";

const Providers = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const providersDetails = useSelector(
    (state: any) => state.providersDetails.providersDetails
  );
  const providerPageData = useSelector(
    (state: any) => state.providerPageData.providerPageData
  );
  const error = useSelector((state: any) => state.providersDetails.error);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [providerId, setProviderId] = useState<number | undefined>(undefined);
  const [username, setUsername] = useState("");
  const [phonenumber, setPhonenumber] = useState("");
  const [pincode, setPincode] = useState("");
  const [village, setVillage] = useState("");
  const [noData, setNoData] = useState(false);

  const limit =10 ; // Match backend default limit

  useEffect(() => {
    dispatch(
      fetchAllProviders({
        admin_id: 1,
        page: currentPage,
        limit,
        provider_id: providerId,
      })
    );
  }, [currentPage, dispatch]); // Dependency on currentPage and dispatch

  useEffect(() => {
    if (providerPageData) {
      setTotalPages(providerPageData.totalPages);
      
    }
  }, [providerPageData]);

  useEffect(() => {
    if (error) {
      setNoData(true);
    } else {
      setNoData(false);
    }
  }, [error, username]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    dispatch(
      fetchAllProviders({
        admin_id: 1,
        page: page,
        limit,
        provider_id: providerId,
        username,
        phonenumber,
        pincode,
        village,
      })
    );
  };

  const handleViewProvider = (id: number) => {
    navigate(`/providerdetails/${id}/`);
  };

  const handleSearch = (
    event: React.KeyboardEvent<HTMLInputElement>,
    field: string
  ) => {
    if (event.key === "Enter") {
      setCurrentPage(1); // Reset to first page for new search
      dispatch(
        fetchAllProviders({
          admin_id: 1,
          page: 1,
          limit,
          provider_id: providerId,
          username,
          phonenumber,
          pincode,
          village,
        })
      );
    }
  };

  const performSearch = () => {
    setCurrentPage(1); // Reset to first page for new search
    dispatch(fetchAllProviders({ admin_id: 1, page: 1, limit, provider_id: providerId, username, phonenumber, pincode, village }));
   
  };
  const headings = [
    "Provider ID",
    "Username",
    "Phone Number",
    "Pincode",
    "Village",
    "Successfull Orders",
    "Action",
  ];

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const filteredProviders = providersDetails || [];

  useEffect(() => {
    if (providersDetails && providersDetails.length === 0) {
      alert("No Providers found for the search query");
    }
  }, [providersDetails]);

  return (
    <div className="h-full overflow-y-auto">
      <div className="grid grid-cols-7">
        {/* Render the heading row */}
        {headings.map((heading, index) => (
          <div
            key={index}
            className="border-b-[2px] border-dark-green p-4 font-bold"
          >
            {heading === "Provider ID" ? (
              <div className="input-containerP"  >
               <div>
              <input
                type="text"
                placeholder="Provider ID"
                value={providerId !== undefined ? providerId.toString() : ""}
                onChange={(e) => setProviderId(e.target.value ? parseInt(e.target.value, 10) : undefined)}
                onKeyDown={(e) => handleSearch(e, 'providerId')}
                className="w-full p-2 border-none focus:outline-none focus:border-none hover:border-none hover:outline-none "

              />
              </div>
               <button className="input-icon w-10  h-full mr-3" onClick={performSearch}>
                  <img src={enter} alt="icon" className="w-50 h-50" />
                </button>
              </div>
            ) : heading === "Username" ? (
              <div className="input-containerP"  >
              <div>
              <input
                type="text"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                onKeyDown={(e) => handleSearch(e, 'username')}
                className="w-full p-2 border-none focus:outline-none focus:border-none hover:border-none hover:outline-none "

                
              />
              </div>
              <button className="input-icon w-10  h-full mr-3" onClick={performSearch}>
                  <img src={enter} alt="icon" className="w-50 h-50" />
                </button>
              </div>
            ) : heading === "Phone Number" ? (
              <div className="input-containerP">
              <div>
              <input
                type="text"
                placeholder="Phone Number"
                value={phonenumber}
                onChange={(e) => setPhonenumber(e.target.value)}
                onKeyDown={(e) => handleSearch(e, 'phonenumber')}
                className="w-full p-2 border-none focus:outline-none focus:border-none hover:border-none hover:outline-none "

              />
              </div>
                 <button className="input-icon w-10  h-full mr-3" onClick={performSearch}>
                  <img src={enter} alt="icon" className="w-50 h-50" />
                </button>
              </div>
            ) : heading === "Pincode" ? (
              <div className="input-containerP">
              <div>
              <input
                type="text"
                placeholder="Pincode"
                value={pincode}
                onChange={(e) => setPincode(e.target.value)}
                onKeyDown={(e) => handleSearch(e, 'pincode')}
                className="w-full p-2 border-none focus:outline-none focus:border-none hover:border-none hover:outline-none "

              />
              </div>
                <button className="input-icon w-10  h-full mr-3" onClick={performSearch}>
                  <img src={enter} alt="icon" className="w-50 h-50" />
                </button>
              </div>
            ) : heading === "Village" ? (
              <div className="input-containerP">
               <div>
              <input
                type="text"
                placeholder="Village"
                value={village}
                onChange={(e) => setVillage(e.target.value)}
                onKeyDown={(e) => handleSearch(e, 'village')}
                               className="w-full p-2 border-none focus:outline-none focus:border-none hover:border-none hover:outline-none "

              />
              </div>
              <button className="input-icon w-10  h-full mr-3" onClick={performSearch}>
              <img src={enter} alt="icon" className="w-50 h-50" />
            </button>
            </div>
            ) : (
              heading
            )}
          </div>
        ))}
        {!noData ? (
          filteredProviders.length > 0 ? (
            filteredProviders.map((provider: any, index: any) => (
              <React.Fragment key={index}>
                <div className="border-b-[2px] border-medium-green p-4">
                  {provider.provider_id}
                </div>
                <div className="border-b-[2px] border-medium-green p-4">
                  <p className="">{provider.username}</p>
                </div>
                <div className="border-b-[2px] border-medium-green p-4">
                  {provider.phonenumber}
                </div>
                <div className="border-b-[2px] border-medium-green p-4">
                  {provider.pincode}
                </div>
                <div className="border-b-[2px] border-medium-green p-4">
                  {provider.village}
                </div>
                <div className="border-b-[2px] border-medium-green p-4">
                {provider?.orders_completed.toString().match(/1/g)?.length || 0}
                </div>
                {/* <div className="border-b-[2px] border-medium-green p-4">
                  {provider.orders_rejected}
                </div> */}
                <div className="border-b-[2px] border-medium-green p-4">
                  <div className="bg-dark-blue flex border-dark-blue border-2 w-1/2 h-[30px] rounded-md items-center justify-center cursor-pointer" onClick={() => handleViewProvider(provider.provider_id)}>
                    <button
                      className="text-white font-medium rounded"
                      
                    >
                      View
                    </button>
                  </div>
                </div>
              </React.Fragment>
            ))
          ) : (
            <div className="flex flex-col justify-center items-center col-span-8  mt-[15%] ">
              {error ? <p>{error}</p> : <p>No Providers available</p>}
              <div
                className="mt-8 cursor-pointer flex items-center justify-center w-12 h-12 bg-[#91DA91] rounded-full"
                onClick={() => navigate("/CreateProvider")}
              >
                <FontAwesomeIcon icon={faPlus} color="white" size="2xl" />
              </div>
            </div>
          )
        ) : (
          <div className=" mt-[15%]">No data Available</div>
        )}
      </div>
      <div className="flex fixed bottom-7 right-0 justify-between px-10">
        <button
          className={`px-4 py-2 rounded mr-2 ${
            currentPage === 1
              ? "bg-gray-300 cursor-not-allowed"
              : "bg-dark-green text-white"
          }`}
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <button
          className={`px-4 py-2 rounded ${
            currentPage === totalPages
              ? "bg-gray-300 cursor-not-allowed"
              : "bg-dark-green text-white"
          }`}
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default withLayout(Providers, "Providers");
