import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "./Layout";
import withLayout from "./withLayout";
import { fetchEqprelatedServices } from "../redux/Slices/getRelatedEqpServices";
import { AppDispatch } from "../redux/store";
import AddEquipmentDialog from "./AddEquipmentDialog";

const Equipments = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const[limit,setLimit] = useState(10);
  const dispatch = useDispatch<AppDispatch>();
  const eqpservices = useSelector(
    (state: any) => state.eqpservices.eqpservices
  );
  useEffect(() => {
    if (eqpservices) {
      setTotalPages(eqpservices.totalPages);
      console.log("eqpsecvvvvvvv",eqpservices.totalPages )
  
    }
  }, [eqpservices]);

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOnClickAdd = () => {
    setIsDialogOpen(!isDialogOpen);
  };

  const [newRows, setNewRows] = useState<any[]>([]);

  useEffect(() => {
    dispatch(fetchEqprelatedServices({ admin_id: 1,page:currentPage,limit:6 }));
  }, [currentPage,dispatch]);





  useEffect(() => {
    console.log("NEw ROWS", newRows);
  }, [newRows]);
  const handleAddCategory = () => {
    setNewRows([
      ...newRows,
      {
        equipmenttype_id: "",
        equipment_name: "",
        service_name: "",
        service_id: "",
      },
    ]);
  };
  const handleAddCategories = () => {
    setNewRows([...newRows, { equipment_id: eqpservices }]);
  };

  const handleInputChange = (index: number, field: string, value: string) => {
    const updatedRows = newRows.map((row, i) =>
      i === index ? { ...row, [field]: value } : row
    );
    setNewRows(updatedRows);
  };

  const headings = [
    "Equipment ID",
    "Equipment Name",
    "Service Name",
    // "Service ID",
  ];

  // console.log(eqpservices);
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(prevPage => prevPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(prevPage => prevPage + 1);
    }
  };
  return (
    <div className="h-full overflow-y-auto">
      {isDialogOpen && (
        <AddEquipmentDialog
          handleClick={handleOnClickAdd}
          open={isDialogOpen}
        />
      )}
      <div className=" grid grid-cols-3">
        {headings.map((heading, index) => (
          <div key={index} className="h-[8vh] bg-medium-green border-b border-gray-300 p-4 font-bold flex">
            {heading}
          </div>
        ))}

{eqpservices && eqpservices?.data && eqpservices.data.length > 0 ? (
  eqpservices.data.map((eqp: any, index: any) => (
    <React.Fragment key={index}>
      <div className="border-b-[2px] border-medium-green p-4">{(currentPage - 1) * limit + index + 1}</div>
      <div className="border-b-[2px] border-medium-green p-4">{eqp.equipment_name}</div>
      <div className="border-b-[2px] border-medium-green p-4">
        {eqp.services.map((service: any, index: number) => {
          return <div key={index}>{`${service.service_name}`}</div>;
        })}
      </div>
    </React.Fragment>
  ))
) : (
  <div className="flex items-center justify-center col-span-3 mt-[15%]">No Eqpuipment available</div>
)}

        {/* Render new rows with input fields */}
        {newRows.map((row, index) => (
          <React.Fragment key={index}>
            <input
              type="text"
              className="border p-4"
              value={row.equipmenttype_id}
              onChange={(e) =>
                handleInputChange(index, "equipmenttype_id", e.target.value)
              }
            />
            <input
              type="text"
              className="border p-4"
              value={row.equipment_name}
              onChange={(e) =>
                handleInputChange(index, "equipment_name", e.target.value)
              }
            />
            <input
              type="text"
              className="border p-4"
              value={row.service_name}
              onChange={(e) =>
                handleInputChange(index, "service_name", e.target.value)
              }
            />
            <input
              type="text"
              className="border p-4"
              value={row.service_id}
              onChange={(e) =>
                handleInputChange(index, "service_id", e.target.value)
              }
            />
          </React.Fragment>
        ))}

        {/* Add Category button */}
      </div>
      <div className="flex fixed bottom-7 right-0 justify-between px-10">
        <button
          className={`px-4 py-2 rounded mr-2 ${currentPage === 1 ? 'bg-gray-300 cursor-not-allowed' : 'bg-dark-green text-white'}`}
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <button
          className={`px-4 py-2 rounded ${currentPage === totalPages ? 'bg-gray-300 cursor-not-allowed' : 'bg-dark-green text-white'}`}
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default withLayout(Equipments, "Equipment Type");
