
import React, { useEffect, useState } from "react";
import Layout from "./Layout";
import { FaRegUser } from "react-icons/fa6";
import { IMG } from "../constants/imgs";
import withLayout from "./withLayout";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { AppDispatch } from "../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { fetchfarmerlands } from "../redux/Slices/getFarmerLands";
import LandsCard from "../components/LandsCard";
import { fetchProviderEquipments } from "../redux/Slices/getProviderEquipments";
import useServices from "../constants/globalservices";
import { fetchAllServices } from "../redux/Slices/getAllServicesSlice";
import { updateEquipmentStatus } from "../redux/Slices/updateEquipmentStatus";
import Modal from "../components/Modal";
const ProviderDetails = () => {
  const navigate = useNavigate();
  const { provider_id } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const {getServiceNameById}=useServices()
  const lands = useSelector((state: any) => state.lands.lands);
  const equipments = useSelector((state: any) => state.equipments.equipments);
  const [equipmentStatuses, setEquipmentStatuses] = useState<(string | undefined)[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [providerDetailsContent, setproviderDetailsContent] = useState<
    { heading: string; value: any }[]
  >([]);
  const [LandContents, setLandContents] = useState<
    { heading: string; value: any }[]
  >([]);
  const [landTitles, setLandTitles] = useState<string[]>([]);
  const [currentTitle,setCurrentTitle]=useState<string>("")
  useEffect(() => {
    if (provider_id) {
      console.log("providerrrrrrr");
      const convertedProviderId = parseInt(provider_id, 10);
      dispatch(
        fetchProviderEquipments({ admin_id: 1, provider_id: convertedProviderId })
      );
    }
  }, []);
  useEffect(() => {
    console.log("equipments", equipments);
    if (
      equipments &&
      equipments.equipmentDetails &&
      equipments.equipmentDetails.length > 0
    ) {
      setLandTitles(
        equipments.equipmentDetails.map(
          (_: any, index: any) => `Equipment${index + 1}`
        )
      );
    }
    setproviderDetailsContent([
      { heading: "Name", value: equipments?.providerDetails?.username },
      {
        heading: "Contact No",
        value: equipments?.providerDetails?.phonenumber,
      },
      { heading: "Village", value: equipments?.providerDetails?.village },
      { heading: "Pincode", value: equipments?.providerDetails?.pincode },
    ]);
    
  }, [equipments]);
  useEffect(() => {
    if (equipments?.equipmentDetails) {
      const initialStatuses: (string | undefined)[] = equipments.equipmentDetails.map((equipment: any) => {
        console.log("providerequipment_status:", equipment.providerequipment_status);
        if (equipment.providerequipment_status === 1) {
          return "Active";
        } else if (equipment.providerequipment_status === 0) {
          return "Inactive";
        } else {
          return undefined; // Handle cases where status is neither 1 nor 0
        }
      });
      console.log("initialStatuses", initialStatuses);
      setEquipmentStatuses(initialStatuses);
     
    }
  }, [equipments]);
  
  useEffect(() => {
    console.log("providerDetailsContent> 🤣", providerDetailsContent);
  }, [equipments]);
  useEffect(() => {
    console.log("equipmentStatuses> 🤣", equipmentStatuses);
  }, [equipmentStatuses]);
  useEffect(() => {
    dispatch(fetchAllServices({ page: 1, limit: 20}));
    console.log("total length: ");
  }, [ ]);
  const handleTitleClick = (title: string) => {
    console.log("titleeeeeee", title);
    setCurrentTitle(title)
    const index = landTitles.indexOf(title);
    console.log();
    setLandContents([
      {
        heading: "Equipment Name",
        value: equipments?.equipmentDetails[index]?.equipment_name,
      },
      {
        heading: "Registration Number",
        value: equipments?.equipmentDetails[index]?.serialnumber,
      },
      {
        heading: "Owner Name",
        value: equipments?.equipmentDetails[index]?.ownername,
      },
      { heading: "Cost", value: equipments?.equipmentDetails[index]?.costperacre },
      { heading: "Equipment offered Services", value:// Assuming equipments is an array or object containing equipmentDetails
         equipments?.equipmentDetails[index]?.provider_offered_services?.map((serviceId:number, index:number) => (
          <p key={index}>{getServiceNameById(serviceId)}</p>
        ))
        },
      // { heading: "Equipment Status", value: equipments?.equipmentDetails[index]?.providerequipment_status===1 ? "Active" : "Inactive" },
      {
        heading: "Equipment Status",
        value: (
          
          <select
          value={(equipmentStatuses && equipmentStatuses.length > 0) ? (console.log("equipmentStatuses[index]:", equipmentStatuses[index]), equipmentStatuses[index]) : ""}
            onChange={(e) => {
              const newStatus = e.target.value;
              // Handle status change logic here if needed
              const statusValue = newStatus === "Active" ? 1 : 0;
              if(provider_id)
              handleStatusChange(statusValue,index,provider_id,equipments?.equipmentDetails[index]?.eqpregistration_id)
           
              
              // Handle status change logic here if needed, e.g., updating the backend
              console.log(`Status for equipment ${index} updated to ${statusValue}`);

            }
          }
            
          >
            <option value="Active">Active</option>
            <option value="Inactive">Inactive</option>
          </select>
        ),
      },
    ]);
  };
  useEffect(()=>{
    if(currentTitle){
      const index = landTitles.indexOf(currentTitle);
      console.log();
      setLandContents([
        {
          heading: "Equipment Name",
          value: equipments?.equipmentDetails[index]?.equipment_name,
        },
        {
          heading: "Registration Number",
          value: equipments?.equipmentDetails[index]?.serialnumber,
        },
        {
          heading: "Owner Name",
          value: equipments?.equipmentDetails[index]?.ownername,
        },
        { heading: "Cost", value: equipments?.equipmentDetails[index]?.costperacre },
        { heading: "Equipment offered Services", value:// Assuming equipments is an array or object containing equipmentDetails
           equipments?.equipmentDetails[index]?.provider_offered_services?.map((serviceId:number, index:number) => (
            <p key={index}>{getServiceNameById(serviceId)}</p>
          ))
          },
        // { heading: "Equipment Status", value: equipments?.equipmentDetails[index]?.providerequipment_status===1 ? "Active" : "Inactive" },
        {
          heading: "Equipment Status",
          value: (
            
            <select
            value={(equipmentStatuses && equipmentStatuses.length > 0) ? (console.log("equipmentStatuses[index]:", equipmentStatuses[index]), equipmentStatuses[index]) : ""}
              onChange={(e) => {
                const newStatus = e.target.value;
                // Handle status change logic here if needed
                const statusValue = newStatus === "Active" ? 1 : 0;
                if(provider_id)
                handleStatusChange(statusValue,index,provider_id,equipments?.equipmentDetails[index]?.eqpregistration_id)
             
                
                // Handle status change logic here if needed, e.g., updating the backend
                console.log(`Status for equipment ${index} updated to ${statusValue}`);
  
              }
            }
              
            >
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </select>
          ),
        },
      ]); 
    }
  },[equipmentStatuses])



  const handleStatusChange = async (newStatus: number, index: number, providerId: string, eqp_id: number) => {
    console.log("newstatus", newStatus, index);
   

    try {
      await dispatch(updateEquipmentStatus({ eqpregistration_id: eqp_id, provider_id: parseInt(providerId), providerequipment_status: newStatus })).unwrap();
      const updatedStatuses = [...equipmentStatuses];
      updatedStatuses[index] = newStatus === 1 ? "Active" : "Inactive";
      setEquipmentStatuses(updatedStatuses);
    } catch (error:any) {
      console.log("errror",error)
      if (error && error.status === 400) {
        setModalMessage("Vehicle already in use");
        setIsModalOpen(true);
        // Revert the status change in the state
        // updatedStatuses[index] = newStatus === 1 ? "Inactive" : "Active";
        // setEquipmentStatuses(updatedStatuses);
      }

    }
}
  return (
    <div className="bg-white h-full w-full p-8">
       <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        message={modalMessage}
      />
      <div className="h-8">
        <text className="text-lm font-medium" style={{ fontSize: "20px" }}>
          Provider Details
        </text>{" "}
      </div>
      <div className="flex items-start h-1/2 mt-4">
        <div className="w-1/3 flex  items-center">
          <div className="w-40 h-40 rounded-full bg-light-grey flex justify-center items-center">
            <FaRegUser style={{ fontSize: "4.5rem", color: "#B8B8B8" }} />
          </div>
        </div>
        <div className="flex  w-1/4 bg-backdrop-grey justify-center items-center">
          <div className=" w-full p-4 grid grid-cols-1 gap-4 ">
            {Array.isArray(providerDetailsContent) &&
              providerDetailsContent.length > 0 &&
              providerDetailsContent.map((item, index) => (
                <div key={index} className="mb-2 flex flex-row justify-between">
                  <h3 className="text-sm font-medium">{item.heading}</h3>
                  <p className="text-sm">{item.value}</p>
                </div>
              ))}
          </div>
        </div>
      </div>
      <hr style={{ width: "100%", height: "2px", backgroundColor: "green" }} />
      <div className="h-8 mt-6 flex flex-row justify-between">
        <text className="text-lm font-medium" style={{ fontSize: "20px" }}>
          Equipment Details
        </text>{" "}
        <button
          className="bg-black rounded-md  px-2 sm:px-4 md:px-8 lg:px-10 xl:px-12 items-center"
          onClick={() => navigate(`/AddEquipment/${provider_id}`)}
        >
          <text className="text-blue-50">+ Add Equipment</text>
        </button>
      </div>
      <div className="flex flex-row h-3/4 overflow-y-auto overflow-x-hidden">
        <LandsCard
          title="equipments"
          titles={landTitles}
          contents={landTitles.length>0 ? LandContents : []}
          onTitleClick={(title: string) => handleTitleClick(title)}
        />
      </div>
    </div>
  );
};

export default withLayout(ProviderDetails, "Service Provider");
