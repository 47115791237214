import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import webConfig from '../../api-request/webConfig';

export const rescheduleProvider = createAsyncThunk(
    'api/rescheduleProvider',
    async ({ eqpregistration_id,
        farmerbooking_id,
        equipmenttype_id,
        service_id,
        provider_id,
        rescheduled_date,
        rescheduled_timeslots,
        booking_status,}: { eqpregistration_id:number | null,
            farmerbooking_id:number,
            equipmenttype_id:number,
            service_id:number,
            provider_id:number,
            rescheduled_date:Date,
            rescheduled_timeslots:number[],
            booking_status:string,}, thunkAPI) => {
      try {
        console.log("reeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee", rescheduled_timeslots,rescheduled_date);
       
        const response = await axios.put(`${webConfig.API_DOMAIN_URL}/admin/rescheduleBooking`,{eqpregistration_id,
            farmerbooking_id,
            equipmenttype_id,
            service_id,
            provider_id,
            rescheduled_date,
            rescheduled_timeslots,
            booking_status});
        
        return response.data;
      } catch (error) {
        console.error('Error posting provider:', error);
        // You can throw the error to let the caller handle it, or return an error object
        throw error;
      }
    }
  );