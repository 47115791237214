import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import CustomeCheckBox from "../components/CustomeCheckBox";
import EmptyImage from "../assets/images/EmptyImgView.svg";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../redux/store";
import { createEquipment } from "../redux/Slices/createEquipment";
import { fetchAllServices } from "../redux/Slices/getAllServicesSlice";
import { fetchEqprelatedServices } from "../redux/Slices/getRelatedEqpServices";

interface DialogProps {
  handleClick: () => void;
  open: boolean;
}

const AddEquipmentDialog = ({ open, handleClick }: DialogProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const [selectedType, setSelectedType] = useState<string>("");
  const [equipmentName, setEquipmentName] = useState("");
  const [selectedServices, setSelectedServices] = useState<string[]>([]);
  const [equipmentImage, setEquipmentImage] = useState<any>();
  const [image, setImage] = useState<any>("");

  const servicesDetails = useSelector(
    (state: any) => state.servicesDetails.servicesDetails
  );

  useEffect(() => {
    dispatch(fetchAllServices({ page: "", limit:""}));
  }, [dispatch]);

  const loading = useSelector((state: any) => state.services.loading);

  const handleTypeChange = (type: string) => {
    setSelectedType(type);
  };

  const handleOnChangeEqpName = (e: any) => {
    setEquipmentName(e.target.value);
  };

  const handleOnUploadImage = (event: any) => {
    const file = event.target.files[0];
    setEquipmentImage(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(`${reader.result}`);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleServiceChange = (service: string) => {
    setSelectedServices((prevSelectedServices) =>
      prevSelectedServices.includes(service)
        ? prevSelectedServices.filter((s) => s !== service)
        : [...prevSelectedServices, service]
    );
  };

  const handleSave = () => {
    console.log(equipmentName, selectedType);
    dispatch(
      createEquipment({
        equipment_name: equipmentName,
        eqpevaluation_type: selectedType,
        equipment_status: 0,
        equipment_image: `${equipmentImage}`,
        services: selectedServices,
      })
    ).then(() => {
      dispatch(fetchEqprelatedServices({ admin_id: 1,page:0,limit:0 }));
      handleClick();
    });
  };

  // console.log(selectedServices)

  return (
    <>
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
        {open && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white rounded-lg shadow-lg w-[800px] mx-auto">
              <div className="bg-light-green rounded-md">
                <div className="p-5 flex justify-between items-center">
                  <h2 className="text-xl font-inter font-bold">
                    Add Equipment 
                  </h2>
                  <button
                    onClick={handleClick}
                    className="text-gray-500 hover:text-gray-700"
                  >
                    <CloseIcon />
                  </button>
                </div>
              </div>
              <div className="p-6 ">
                <div className="flex flex-col gap-8">
                  <div className="flex items-center gap-10">
                    <label className="font-inter font-bold">Image</label>
                    {image === "" ? (
                      <div className="rounded-[10px] bg-[#F3F3F3] flex items-center justify-center h-[98px] w-[98px]">
                        <img
                          src={EmptyImage}
                          alt="image-not-available"
                          className="ml-2"
                        />
                      </div>
                    ) : (
                      <div className="rounded-[10px] bg-[#F3F3F3] flex items-center justify-center h-[98px] w-[98px]">
                        <img
                          src={image}
                          alt="image-not-available"
                          className="h-[98px] w-[98px] rounded-[10px]"
                        />
                      </div>
                    )}
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleOnUploadImage}
                      className="px-4 py-2 w-[305px] h-[38px]"
                    />
                  </div>
                  <div className="flex gap-10 items-center">
                    <div className="flex items-center gap-10">
                      <label className="font-inter font-bold">Name</label>
                      <input
                        placeholder={"Enter..."}
                        value={equipmentName}
                        onChange={handleOnChangeEqpName}
                        className="px-4 py-2 border border-dark-green rounded w-[305px] h-[38px]"
                      />
                    </div>
                    <div className="flex gap-10 items-center">
                      <label className="font-inter font-bold">Type</label>
                      <CustomeCheckBox
                        label={"Acre"}
                        handleClick={() => handleTypeChange("Acre")}
                        isChecked={selectedType === "Acre"}
                      />
                      <CustomeCheckBox
                        label={"Hour"}
                        handleClick={() => handleTypeChange("Hour")}
                        isChecked={selectedType === "Hour"}
                      />
                    </div>
                  </div>
                  <div className="flex gap-10">
                    <label className="font-inter font-bold">Services</label>
                    <div className="flex flex-wrap gap-10 h-[20vh] overflow-x-auto scrollbar-hide">
                      {servicesDetails &&
                        servicesDetails.map((data: any, index: number) => (
                          <div className="w-[40vh]" key={index}>
                            <CustomeCheckBox
                              label={data.name}
                              handleClick={() => handleServiceChange(data)}
                              isChecked={selectedServices.includes(data)}
                            />
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className="flex justify-center h-[42px] gap-10 ">
                    <button
                      disabled={
                        equipmentName === "" ||
                        image === "" ||
                        selectedType === "" ||
                        selectedServices.length === 0
                      }
                      onClick={handleSave}
                      className="flex-1 h-full bg-green-500 disabled:bg-gray-400 disabled:cursor-not-allowed text-white font-bold rounded-md rounded-bl-md focus:outline-none hover:bg-green-600"
                    >
                      Save
                    </button>
                    <button
                      onClick={handleClick}
                      className="flex-1 h-full bg-red-500 text-white font-bold rounded-md rounded-br-md focus:outline-none hover:bg-red-600"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default AddEquipmentDialog;
