import React from "react";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  message: string;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, message }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="bg-white p-5 rounded shadow-lg w-1/2 h-1/4 flex flex-col">
        <h2 className="text-xl font-semibold mb-4">Error</h2>
        <p className="mb-4">{message}</p>
        <div className="mt-auto self-end">
        <button
          onClick={onClose}
          className="p-2 bg-red-500 text-white rounded w-40"
        >
          Close
        </button>
        </div>
    
      </div>
    </div>
  );
};

export default Modal;
