import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import webConfig from '../../api-request/webConfig';


export const fetchfarmerlands = createAsyncThunk(
    'api/fetchfarmerlands',
    async ({admin_id,farmer_id}:{admin_id:number,farmer_id:number}, thunkAPI) => {
      try {
        console.log("farmer_id",farmer_id)
        const response = await axios.get(`${webConfig.API_DOMAIN_URL}/admin/getFarmerlands/${admin_id}/${farmer_id}`);
        console.log("response", response.data);
        return response.data;
      } catch (error) {
        console.error('Error fetching farmer lands:', error);
        // You can throw the error to let the caller handle it, or return an error object
        throw error;
      }
    }
  );
    interface Lands {}

  interface ApiState {
    lands: Lands | null;
    loading: boolean;
    error: string | null;
  }
  
  const initialState: ApiState = {
    lands: null,
    loading: false,
    error: null,
  };
  

  const fetchFarmerLandsSlice = createSlice({
    name: 'fetchLands',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchfarmerlands.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(fetchfarmerlands.fulfilled, (state, action) => {
          state.loading = false;
          state.lands = action.payload;
        })
        .addCase(fetchfarmerlands.rejected, (state, action) => {
          state.loading = false;
          state.error = action.error.message || 'Failed to fetch eqps';
        });
    },
  });
  const fetchLandsReducer=fetchFarmerLandsSlice.reducer
  export default fetchLandsReducer;