
  import { useSelector } from "react-redux";
  enum EquipmentName {
   
}

interface Equipment {
    id: number;
    name: EquipmentName;
}

  
  const useEquipment = () => {
      const globalequipments = useSelector((state: any) => state.globalequipments.globalequipments);
  
      const getEquipmentNameById = (id: number): string => {
          const equipment = globalequipments?.globalequipments?.find((equip: { id: number }) => equip.id === id);
          console.log("CATCHHHHHH",equipment)
          return equipment ? equipment?.name : null;
      };
      const getEquipmentTypeById = (id: number): string => {
        const equipment = globalequipments?.globalequipments?.find((equip: { id: number }) => equip.id === id);
        console.log("CATCHHHHHH",equipment)
        return equipment ? equipment?.eqpevaluation_type : null;
    };
  
      return { getEquipmentNameById,getEquipmentTypeById };
  };
  
  export default useEquipment;
  