import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';


import webConfig from '../../api-request/webConfig';

export const updateEquipmentStatus = createAsyncThunk(
    'api/updateEquipmentStatus',
    async ({ eqpregistration_id,providerequipment_status,provider_id }: { eqpregistration_id:number,providerequipment_status:number,provider_id:number}, thunkAPI) => {
      try {
        console.log("API",eqpregistration_id,providerequipment_status);
        const response = await axios.put(`${webConfig.API_DOMAIN_URL}/admin/updateEquipmentStatus/`,{eqpregistration_id,providerequipment_status,provider_id});
        
        console.log("response",response);
        
        return response.data;
      } catch (error:any) {
        console.error('Error updating status', error);
        if (error.response && error.response.status === 400) {
          return thunkAPI.rejectWithValue({ status: 400, message: 'Vehicle already in use' });
        }
        // Alert.alert("Error -Deleting Equipment")
        // You can throw the error to let the caller handle it, or return an error object
        const axiosError = error as AxiosError; // Type assertion
        if (axiosError.response && axiosError.response.status === 400) {
         
        }
        throw error;
      }
    }
  );