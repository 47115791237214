import { useDispatch, useSelector } from "react-redux";
import CustomDropdown from "../components/CustomeDropdown";
import LabelInput from "../components/labelInput";
import withLayout from "./withLayout";
import { useEffect, useState } from "react";
import { BookingsTimeSlots } from "../constants";
import { AppDispatch } from "../redux/store";
import { useNavigate, useParams } from "react-router-dom";

import { assignProvider } from "../redux/Slices/assignProviderSlice";
import { fetchBookingDetailsbyId } from "../redux/Slices/getBookingDetailsbyId";
import { fetchSuitableEqpChoices } from "../redux/Slices/getSuitableEquipmentsList";

const ProviderAssign = () => {
  const navigate = useNavigate();
  const { farmerbooking_id } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const bookingDetailsbyId = useSelector(
    (state: any) => state.bookingDetailsbyId.bookingDetailsbyId
  );
  const equipmentchoices = useSelector(
    (state: any) => state.equipmentchoices.equipmentchoices
  );
  const [requestedSlots, setRequestedSlots] = useState<any>([]);
  const [selectedSlot, setSelectedSlot] = useState<string>("");
  const [eqp_id, setEqp_id] = useState<number>();
  const [providerchoices, setProviderChoices] = useState<{ value: string; label: string }[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<string>("");
  const [selectedProvider, setSelectedProvider] = useState<string>("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isRejectModalVisible, setRejectIsModalVisible] = useState(false);

  const options = [
    { value: "Accepted", label: "Accepted" },
    { value: "Rejected", label: "Rejected" },
    { value: "option3", label: "Option 3" },
  ];

  const statusOptions = [
    { value: "Accepted", label: "Accepted" },
    { value: "Rejected", label: "Rejected" },
    { value: "Reschedule", label: "Reschedule" },
  ];
  useEffect(() => {
    if (farmerbooking_id ) {
      dispatch(
        fetchBookingDetailsbyId({
          admin_id: 1,
          farmerbooking_id: parseInt(farmerbooking_id, 10),
          provider_id: null
        })
      );
    }
  }, [dispatch, farmerbooking_id]);
  useEffect(() => {
    console.log("equipmentchoices", equipmentchoices);
    if (equipmentchoices) {
      const options = equipmentchoices?.availableEquipments.map((equipment: { provider_id: any; username: any; eqpregistration_id: number }) => ({
        value: equipment.eqpregistration_id,
        label: equipment.username
      }));
      setProviderChoices(options);
    }
  }, [equipmentchoices]);

  useEffect(() => {
    console.log("bookingDetails", bookingDetailsbyId);
    const slots =
      (bookingDetailsbyId?.farmerBookings &&
        bookingDetailsbyId.farmerBookings.requested_timeslot?.map(
          (id: number) => {
            return {
              value: id,
              label: BookingsTimeSlots[`${id}`],
            };
          }
        )) ||
      [];
    setRequestedSlots(slots);
    if (bookingDetailsbyId?.farmerBookings?.booking_status) {
      setSelectedStatus(bookingDetailsbyId.farmerBookings.booking_status);
    }
  }, [bookingDetailsbyId]);

  async function handleProviderChoice(eqpregistration_id: number) {
    setEqp_id(eqpregistration_id);
  }
  const handleReschdule = (
  ) => {
    const utc_date=getUTCDate(bookingDetailsbyId?.farmerBookings?.requested_date)
    dispatch(
      fetchSuitableEqpChoices({
        farmerbooking_id: bookingDetailsbyId?.farmerBookings?.farmerbooking_id,
        equipmenttype_id: bookingDetailsbyId?.farmerBookings?.equipmenttype_id,
        requested_date: bookingDetailsbyId?.farmerBookings?.requested_date,
        requested_timeslots: bookingDetailsbyId?.farmerBookings?.requested_timeslots,
      })
    );

    // dispatch(
    //   fetchBookingDetailsbyId({ admin_id: 1, bookingDetailsbyId?.farmerBookings?.farmerbooking_id, bookingDetailsbyId?.farmerBookings?.requested_timeslo })
    // );
    const prd=null
    navigate(`/provider-reschedule/${farmerbooking_id}/${prd}}`);
  };
  async function handleAssign() {
   console.log("selectedStatus",selectedStatus)
   const utc_date=getUTCDate(bookingDetailsbyId?.farmerBookings?.requested_date)
   const current_date = getUTCDate((new Date()).toString());
   
   console.log("utcdate",utc_date,current_date)
   if(current_date > utc_date){
    alert("Requested date has past the current date,Please Reschedule the booking")
    return;
   }
      if(selectedStatus==="Accepted" && eqp_id) {
    
      dispatch(assignProvider({
        eqpregistration_id: eqp_id, farmerbooking_id: bookingDetailsbyId?.farmerBookings?.farmerbooking_id, equipmenttype_id: bookingDetailsbyId?.farmerBookings?.equipmenttype_id, service_id: bookingDetailsbyId?.farmerBookings?.service_id, requested_date:utc_date, requested_timeslots: bookingDetailsbyId?.farmerBookings?.requested_timeslot,
        booking_status: "Accepted"
      }));
      setIsModalVisible(true);
    }
      else if (selectedStatus==="Rejected")
  {
    
        dispatch(assignProvider({
          eqpregistration_id: null, farmerbooking_id: bookingDetailsbyId?.farmerBookings?.farmerbooking_id, equipmenttype_id: bookingDetailsbyId?.farmerBookings?.equipmenttype_id, service_id: bookingDetailsbyId?.farmerBookings?.service_id, requested_date: bookingDetailsbyId?.farmerBookings?.requested_date, requested_timeslots: bookingDetailsbyId?.farmerBookings?.requested_timeslot,
          booking_status: "Rejected"
        }));
        setRejectIsModalVisible(true);

      }
  
  }
  function extractDate(dateTimeString:string) {
    const utcDate = new Date(dateTimeString); // Parse the input string as UTC date
  
    // Convert UTC date to IST
    const istOffset = 5.5 * 60 * 60 * 1000; // 5.5 hours in milliseconds
    const istDate = new Date(utcDate.getTime() + istOffset);
    
    // Extract the date parts from the IST date
    const day = String(istDate.getUTCDate()).padStart(2, '0');
    const month = String(istDate.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = istDate.getUTCFullYear();
    
    // Return the formatted date string
    return `${year}-${month}-${day}`;
}
  const closeModal = () => {
    setIsModalVisible(false);
    setRejectIsModalVisible(false);

    navigate('/bookings')
  };
  function getUTCDate(dateTimeString: string) {
    // Parse the input string as a UTC date
    const utcDate = new Date(dateTimeString);

    // Convert UTC date to IST
    const istOffset = 5.5 * 60 * 60 * 1000; // 5.5 hours in milliseconds
    const istDate = new Date(utcDate.getTime() + istOffset);

    // Extract the date parts from the IST date
    const day = String(istDate.getUTCDate()).padStart(2, '0');
    const month = String(istDate.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = istDate.getUTCFullYear();

    // Convert the IST date back to UTC format
    const convertedDate = new Date(Date.UTC(year, istDate.getUTCMonth(), istDate.getUTCDate()));

    // Extract the date parts from the converted UTC date
    const utcDay = String(convertedDate.getUTCDate()).padStart(2, '0');
    const utcMonth = String(convertedDate.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
    const utcYear = convertedDate.getUTCFullYear();

    // Return the formatted date string in UTC format
    return `${utcYear}-${utcMonth}-${utcDay}`;
}



  // console.log(extractDate(bookingDetailsbyId?.farmerBookings?.requested_date));

  return (
    <>
      <div className="p-10 h-full overflow-y-auto">
        <p className="font-inter text-20 font-bold leading-24.2 text-left">
          Assign Orders
        </p>
        <div className="h-px bg-gray-300 my-9" />
        <div className="flex flex-col">
          <p className="font-inter text-20 font-bold leading-16.94 text-left mb-4">
            User Details *
          </p>
          <div className="flex w-305 h-38 gap-20">
            <LabelInput
              type="text"
              value={
                bookingDetailsbyId &&
                bookingDetailsbyId?.farmerBookings?.farmer_username
              }
              onChange={(e) => {
                console.log(e.target.value);
              }}
              label={"Name"}
            />
            <LabelInput
              type="text"
              value={
                bookingDetailsbyId &&
                bookingDetailsbyId?.farmerBookings?.farmerbooking_id
              }
              onChange={(e) => {
                console.log(e.target.value);
              }}
              label={"Booking ID"}
            />
          </div>
        </div>
        <div className="h-px bg-gray-300 my-9" />
        <div className="flex flex-col">
          <p className="font-inter text-20 font-bold leading-24.2 text-left mb-4">
            Delivery Details *
          </p>
          <div className="flex w-305 h-38 gap-20">
            <LabelInput
              type="text"
              value={
                bookingDetailsbyId &&
                bookingDetailsbyId?.farmerBookings?.village
              }
              onChange={(e) => {
                console.log(e.target.value);
              }}
              label={"Delivery Address"}
            />
            <LabelInput
              type="text"
              value={
                bookingDetailsbyId &&
                bookingDetailsbyId?.farmerBookings?.district
              }
              onChange={(e) => {
                console.log(e.target.value);
              }}
              label={"Pin Code"}
            />
          </div>
        </div>
        <div className="h-px bg-gray-300 my-9" />
        <div className="flex flex-col">
          <p className="font-inter text-20 font-bold leading-24.2 text-left mb-4">
            Date & Time *
          </p>
          <div className="flex w-305 h-38 gap-20">
            <LabelInput
              type="date"
              value={
                bookingDetailsbyId &&
                extractDate(bookingDetailsbyId?.farmerBookings?.requested_date)
              }
              onChange={(e) => {
                console.log(e.target.value);
              }}
              minDate={new Date().toISOString().split("T")[0]}
              label={"Date"}
            />
            <div className="flex flex-col">
              <p className="font-inter text-14 font-semi-bold leading-16.4 text-left mb-4">
                Requested Slots
              </p>
              <div className="flex w-305 h-38 gap-20">
                <CustomDropdown
                  options={requestedSlots ? requestedSlots : []}
                  selectedValue={selectedSlot}
                  onChange={(e) => setSelectedSlot(e)}
                  placeholder="Select an option"
                />
              </div>
            </div>
          </div>
        </div>
      
        <div className="h-px bg-gray-300 my-9" />
        <div className="flex flex-col">
          <p className="font-inter text-20 font-bold leading-24.2 text-left mb-4">
            Status *
          </p>
          <div className="flex w-305 h-38 gap-20">
            <CustomDropdown
              options={statusOptions}
              selectedValue={selectedStatus}
              onChange={(e) => {
                setSelectedStatus(e);
              }}
              placeholder="Select an option"
            />
          </div>
        </div>
      
        <div className="h-px bg-gray-300 my-9" />
        <div className="flex flex-col">
          <p className="font-inter text-20 font-bold leading-24.2 text-left mb-4">
            Service Provider *
          </p>
   { selectedStatus==="Accepted" &&
     <div className="flex w-305 h-38 gap-20">
            <CustomDropdown
              options={providerchoices}
              selectedValue={selectedProvider}
              onChange={(e) => {
                setSelectedProvider(e);
                handleProviderChoice(parseInt(e)); // Assuming you also need to handle some action on selection
              }}
              placeholder="Select an option"
            />
          </div>
}
        </div>
        <div className="flex gap-5 mt-9 p-2 ">
        {(selectedStatus === "Accepted" || "") ? (
  <button className="w-[477px] h-12 rounded rounded-bl-none bg-medium-blue text-white opacity-100" onClick={handleAssign}>
    Assign
  </button>
) : null}
        {selectedStatus === "Rejected" ? (
  <button className="w-[477px] h-12 rounded rounded-bl-none bg-medium-red text-white opacity-100" onClick={handleAssign}>
    Reject
  </button>
) : null}
{selectedStatus === "Reschedule" ? (
  <button className="w-[477px] h-12 rounded rounded-bl-none bg-dark-brown border-dark-brown text-white opacity-100" onClick={handleReschdule}>
    Reschedule
  </button>
) : null}
          <button className="w-[477px] h-12 rounded rounded-bl-none bg-medium-blue text-white opacity-100"
           onClick={() => navigate("/bookings")}
          >
            Cancel
          </button>
        </div>
        {isModalVisible && (
  <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
    <div className="bg-white p-5 rounded shadow-lg w-1/2 h-1/4 flex flex-col">
      <p className="mb-4">Provider has been assigned</p>
      <div className="mt-auto self-end">
        <button className="p-2 bg-blue-500 text-white rounded w-40" onClick={closeModal}>
          OK
        </button>
      </div>
    </div>
  </div>
)}
        {isRejectModalVisible && (
  <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50" onClick={closeModal}>
    <div className="bg-white p-5 rounded shadow-lg w-1/2 h-1/4 flex flex-col">
      <p className="mb-4">Booking has been Rejected</p>
      <div className="mt-auto self-end">
        <button className="p-2 bg-red-500 text-white rounded w-40" >
          OK
        </button>
      </div>
    </div>
  </div>
)}
      </div>
    </>
  );
};

export default withLayout(ProviderAssign, "Provider Assign");