import React, { KeyboardEventHandler, useEffect, useState } from "react";
import Layout from "./Layout";
import { IMG } from "../constants/imgs";
import withLayout from "./withLayout";
import { AppDispatch } from "../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllBookings } from "../redux/Slices/getAllBookings";
import { fetchBookingDetailsbyId } from "../redux/Slices/getBookingDetailsbyId";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { fetchSuitableEqpChoices } from "../redux/Slices/getSuitableEquipmentsList";
import { fetchAllServices } from "../redux/Slices/getAllServicesSlice";
import { fetchGlobalEquipments } from "../redux/Slices/fetchglobalEquipments";
import { BookingPaymentOptions, BookingStatusOptions } from "../constants";
import enter from "../assets/images/enter.png";
import { Session } from "inspector";

const headings = [
  "Service Type",
  "Booking ID",
  "Farmer Name",
  "Provider Name",
  "Date",
  "Status",
  "Action",
];

const headingValues: { [key: string]: string }[] = [
  { "Service Type": "service_id" },
  { "Booking ID": "farmerbooking_id" },
  { "Farmer Name": "farmer_username" },
  { "Provider Name": "provider_username" },
  { Date: "requested_date" },
  { Status: "booking_status" },
];

const Bookings = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const bookingsDetails = useSelector(
    (state: any) => state.bookingsDetails.bookingsDetails
  );
  const bookingPagination = useSelector(
    (state: any) => state.bookingsDetails.bookingPagination
  );
  // console.log(bookingPagination.totalItems)
  const [provider, setProvider] = useState<string>("");
  const [bookingId, setBookingId] = useState<any>("");
  const [status, setStatus] = useState<string>("");
  const [paymentstatus, setPaymentStatus] = useState<string>("");
  const [display, setDisplay] = useState<string>("");
  const [farmer, setFarmer] = useState<string>("");
  const [date, setDate] = useState<string>("");
  const [inputType, setInputType] = useState("text");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [displaystate, setDisplayState] = useState(""); // State for filtering by Farmer Name
  const [serviceType, setFilterNam] = useState(""); // State for filtering by Farmer Name
  const limit = 10; // Match backend default limit

  useEffect(() => {
    dispatch(
      fetchAllBookings({
        admin_id: 1,
        page: currentPage,
        limit,
        provider,
        bookingId,
        status,
        farmer,
        date,
        paymentstatus
      })
    );
  }, [currentPage, dispatch, status]);

  useEffect(() => {
    if (bookingPagination && bookingPagination.totalPages) {
      setTotalPages(bookingPagination.totalPages);
    }
  }, [bookingPagination]);

  function extractDate(dateTimeString: string) {
    const utcDate = new Date(dateTimeString); // Parse the input string as UTC date

    // Convert UTC date to IST
    const istOffset = 5.5 * 60 * 60 * 1000; // 5.5 hours in milliseconds
    const istDate = new Date(utcDate.getTime() + istOffset);

    // Extract the date parts from the IST date
    const day = String(istDate.getUTCDate()).padStart(2, "0");
    const month = String(istDate.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = istDate.getUTCFullYear();

    // Return the formatted date string
    return `${year}-${month}-${day}`;
  }

  const handleViewDetails = (farmerbooking_id: number, provider_id: number) => {
    console.log("view details", farmerbooking_id, provider_id);
    dispatch(
      fetchBookingDetailsbyId({ admin_id: 1, farmerbooking_id, provider_id })
    );
    dispatch(fetchAllServices({ page: 1, limit: 10 }));
    dispatch(fetchGlobalEquipments());
    navigate(`/booking-details/${farmerbooking_id}/${provider_id}`);
  };

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    const day = String(date.getDate()) + 1;
    const month = String(date.getMonth() + 1);
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  useEffect(() => {
    console.log("bookingsDetails", bookingsDetails);
  }, [bookingsDetails]);

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handleBlur = () => {
    if (!date) {
      setInputType("text");
    }
  };

  const handleFocus = () => {
    setInputType("date");
  };
  function getUTCDate(dateTimeString: string) {
    // Parse the input string as a UTC date
    const utcDate = new Date(dateTimeString);

    // Convert UTC date to IST
    const istOffset = 5.5 * 60 * 60 * 1000; // 5.5 hours in milliseconds
    const istDate = new Date(utcDate.getTime() + istOffset);

    // Extract the date parts from the IST date
    const day = String(istDate.getUTCDate()).padStart(2, '0');
    const month = String(istDate.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = istDate.getUTCFullYear();

    // Convert the IST date back to UTC format
    const convertedDate = new Date(Date.UTC(year, istDate.getUTCMonth(), istDate.getUTCDate()));

    // Extract the date parts from the converted UTC date
    const utcDay = String(convertedDate.getUTCDate()).padStart(2, '0');
    const utcMonth = String(convertedDate.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
    const utcYear = convertedDate.getUTCFullYear();

    // Return the formatted date string in UTC format
    return convertedDate;
}
  const handleReschdule = (
    farmerbooking_id: number,
    provider_id: number,
    equipmenttype_id: number,
    requested_date: Date,
    requested_timeslots: []
  ) => {
    const utc_date=getUTCDate(requested_date.toString())
    dispatch(
      fetchSuitableEqpChoices({
        farmerbooking_id: farmerbooking_id,
        equipmenttype_id: equipmenttype_id,
        requested_date: utc_date,
        requested_timeslots: requested_timeslots,
      })
    );
    dispatch(
      fetchBookingDetailsbyId({ admin_id: 1, farmerbooking_id, provider_id })
    );
    navigate(`/provider-reschedule/${farmerbooking_id}/${provider_id}`);
  };
  const handleOnClickAssign = (
    farmerbooking_id: number,
    provider_id: number,
    equipmenttype_id: number,
    requested_date: Date,
    requested_timeslots: []
  ) => {
    const utc_date=getUTCDate(requested_date.toString())
    dispatch(
      fetchSuitableEqpChoices({
        farmerbooking_id: farmerbooking_id,
        equipmenttype_id: equipmenttype_id,
        requested_date: utc_date,
        requested_timeslots: requested_timeslots,
      })
    );
    dispatch(
      fetchBookingDetailsbyId({ admin_id: 1, farmerbooking_id, provider_id })
    );
    navigate(`/provider-assign/${farmerbooking_id}`);
  };

  const handleSearch = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      setCurrentPage(1);
      dispatch(
        fetchAllBookings({
          admin_id: 1,
          page: currentPage,
          limit,
          provider,
          bookingId,
          status,
          farmer,
          date,
          paymentstatus
        })
      );
    }
  };
  const handleSetStatus = (status: string) => {
    if (status === "Completed") {
      setStatus("SessionEnded");
      setDisplay(status);
    } else if (status === "Reached") {
      setStatus("Reached");
      setDisplay(status);
    } else if (status === "InProgress") {
      setStatus("SessionStarted");
      setDisplay(status);
    } else {
      setStatus(status);
      setDisplay(status);
    }
  };
  const handlePaymentStatus = (status: string) => {
    if (status === "Paid") {
      setPaymentStatus("CHARGED");
      setDisplayState(status);
    } else if (status === "Failed") {
      setPaymentStatus("FAILED");
      setDisplayState(status);
    } else if(status ==="Pending"){
      setPaymentStatus("");
      setDisplayState(status);
    }else{
      setPaymentStatus(status)
      setDisplayState(status)
    }
  };
  const handleSearchStatus = (event: any) => {
    if (event.key === "Enter") {
      setCurrentPage(1);
      dispatch(
        fetchAllBookings({
          admin_id: 1,
          page: currentPage,
          limit,
          provider,
          bookingId,
          status,
          farmer,
          date,
          paymentstatus
        })
      );
    }
  };
  //KeyboardEventHandler<HTMLSelectElement>
  // console.log(bookingId==="");

  // console.log(date);
  const performSearch = () => {
    setCurrentPage(1);
    dispatch(
      fetchAllBookings({
        admin_id: 1,
        page: currentPage,
        limit,
        provider,
        bookingId,
        status,
        farmer,
        date,
        paymentstatus
      })
    );
  };
  return (
    <div className="h-full overflow-y-auto">
      <div className="grid grid-cols-8  ">
        {/* Render the heading row */}
        {/* {headings.map((heading, index) => (
          <div key={index} className="bg-medium-green p-4 font-bold">
            {heading === "Farmer Name" ? ( // Conditional rendering for filter input
              <input
                type="text"
                value={filterName}
                onChange={handleFilterChange}
                placeholder="Farmer Name"
                className="px-2 py-1 border rounded w-[120px]"
              />
            ) : (
              heading
            )}
          </div>
        ))} */}
        <div className="bg-medium-green p-4 font-bold h-[8vh] ">
          <input
            className="p-1 h-[25px] bg-medium-green border-none focus:outline-none focus:border-none hover:border-none hover:outline-none"
            placeholder={"Service ID"}
            value={"Sl.No"}
            disabled
          />
        </div>
        <div className="bg-medium-green p-4 font-bold   h-[8vh]  flex items-center">
          <div className=" bg-white h-[38px] flex flex-row items-center">
            <input
              className="p-1 h-[38px] w-[15vh] border-none focus:outline-none focus:border-none hover:border-none hover:outline-none"
              placeholder={"Booking ID"}
              value={bookingId}
              type="number"
              onChange={(e: any) => {
                setBookingId(e.target.value);
                setFarmer("");
                setPaymentStatus("");
                setProvider(""); 
                setDate("");
                setInputType("text");
                setStatus("");
              }}
              onKeyDown={handleSearch}
            />
            <button className="input-icon w-5 h-5 mr-3" onClick={performSearch}>
              <img src={enter} alt="icon" className="w-full" />
            </button>
          </div>
        </div>
        <div className="bg-medium-green p-4 font-bold   h-[8vh]  flex items-center">
          <div className=" bg-white h-[38px] flex flex-row items-center">
            <input
              className="p-1 h-[30px] w-[15vh] border-none focus:outline-none focus:border-none hover:border-none hover:outline-none"
              placeholder={"Farmer"}
              value={farmer}
              onChange={(e: any) => {
                setFarmer(e.target.value);
                setBookingId("");
                setProvider("");
                setPaymentStatus("");
                setDate("");
                setInputType("text");
                setStatus("");
              }}
              onKeyDown={handleSearch}
            />
            <button className="input-icon w-5 h-5 mr-3" onClick={performSearch}>
              <img src={enter} alt="icon" className="w-full" />
            </button>
          </div>
        </div>
        <div className="bg-medium-green p-4 font-bold   h-[8vh]  flex items-center">
          <div className=" bg-white h-[38px] flex flex-row items-center">
            <input
              className="p-1 h-[38px] w-[15vh] border-none focus:outline-none focus:border-none hover:border-none hover:outline-none"
              placeholder={"Provider"}
              value={provider}
              onChange={(e: any) => {
                setProvider(e.target.value);
                setBookingId("");
                setFarmer("");
                setDate("");
                setInputType("text");
                setStatus("");
              }}
              onKeyDown={handleSearch}
            />
            <button className="input-icon w-5 h-5 mr-3" onClick={performSearch}>
              <img src={enter} alt="icon" className="w-full" />
            </button>
          </div>
        </div>
        <div className="bg-medium-green p-4 font-bold   h-[8vh]  flex items-center">
          <div className=" bg-white h-[38px] flex flex-row items-center">
            <input
              className="p-1 h-[38px] w-[15vh] border-none focus:outline-none focus:border-none hover:border-none hover:outline-none"
              placeholder={"Date"}
              type={inputType}
              onFocus={handleFocus}
              onBlur={handleBlur}
              value={date}
              onChange={(e: any) => {
                setDate(e.target.value);
                setProvider("");
                setPaymentStatus("");
                setBookingId("");
                setFarmer("");
                setStatus("");
              }}
              onKeyDown={handleSearch}
            />
            <button className="input-icon w-5 h-5 mr-3" onClick={performSearch}>
              <img src={enter} alt="icon" className="w-full" />
            </button>
          </div>
        </div>
        <div className="bg-medium-green p-4 font-bold  h-[8vh]">
          <select
            className=" mt-[-5px] p-1 h-[38px] w-[20vh] border-none focus:outline-none focus:border-none hover:border-none hover:outline-none"
            value={display}
            onChange={(e) => {
              //setStatus(e.target.value);
              handleSetStatus(e.target.value);
              setPaymentStatus("");
              setProvider("");
              setBookingId("");
              setFarmer("");
              setDate("");
              setInputType("text");
            }}
            onKeyDown={handleSearchStatus}
          >
            {status === "" && <option value="">Status</option>}
            {status !== "" && <option value="">All</option>}
            {BookingStatusOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        <div className="bg-medium-green p-4 font-bold  h-[8vh]">
          <select
            className=" mt-[-5px] p-1 h-[38px] w-[20vh] border-none focus:outline-none focus:border-none hover:border-none hover:outline-none"
            value={displaystate}
            onChange={(e) => {
              //setPaymentStatus(e.target.value);
              handlePaymentStatus(e.target.value);
              setProvider("");
              setBookingId("");
              setStatus("");
              setFarmer("");
              setDate("");
              setInputType("text");
            }}
            onKeyDown={performSearch}
          >
            {paymentstatus === "" && <option value="">Payment</option>}
            {paymentstatus !== "" && <option value="">All</option>}
            {BookingPaymentOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        <div className="bg-medium-green p-4 font-bold  h-[8vh]">
          <input
            className="p-1 h-[25px] w-[10vh] bg-medium-green border-none focus:outline-none focus:border-none hover:border-none hover:outline-none"
            // placeholder={"Action"}
            value={"Action"}
            disabled
          />
        </div>

        {/* Loop through farmer details and render them in subsequent rows */}
        {bookingsDetails && bookingsDetails.length > 0 ? (
          bookingsDetails.map((item: any, index: any) => (
            <React.Fragment key={index}>
              <div className="border-b-[2px] border-medium-green p-4">
                {index + 1 + (currentPage - 1) * limit}
              </div>
              <div className="border-b-[2px] border-medium-green p-4 ">
                {item.farmerbooking_id}
              </div>
              <div className="border-b-[2px] border-medium-green p-4">
                {item.farmer_username}
              </div>
              <div className="border-b-[2px] border-medium-green p-4">
                {item.provider_username}
              </div>
              <div className="border-b-[2px] border-medium-green p-4">
                {extractDate(item.requested_date)}
              </div>
              <div className="border-b-[2px] border-medium-green p-4">
                {(item.booking_status === "Accepted" ||
                  item.booking_status === "Rescheduled") &&
                item.provider_status !== null
                  ? item.provider_status === "SessionEnded"
                    ? "Completed"
                    : item.provider_status === "SessionStarted"
                    ? "InProgress"
                    : item.provider_status === "Reached"
                    ? "Reached"
                    : item.provider_status
                  : item.booking_status}
              </div>
              <div className="border-b-[2px] border-medium-green p-4">
              {(item.farmer_payment_status === "CHARGED" ||
  item.farmer_payment_status === "AUTHENTICATION_FAILED" ||
  item.farmer_payment_status === "AUTHORIZATION_FAILED") ? (
    item.farmer_payment_status === "CHARGED" ? "Paid" : "Failed"
  ) : "Pending"}

              </div>

              <div className="border-b-[2px] border-medium-green p-4">
                <div className="flex gap-2">
                  {item.booking_status !== "Pending" && (
                    <div
                      className="bg-dark-blue flex border-dark-blue border-2 w-1/2 h-full rounded-md items-center justify-center cursor-pointer "
                      onClick={() =>
                        handleViewDetails(
                          item.farmerbooking_id,
                          item.provider_id
                        )
                      }
                    >
                      <button className="text-white font-medium rounded px-1">
                        View
                      </button>
                    </div>
                  )}

                  {(item.booking_status === "Accepted" ||
                    item.booking_status === "Rescheduled") &&
                    item.provider_status === null && (
                      <div
                        className="bg-dark-brown flex border-dark-brown border-2  h-full rounded-md items-end justify-center "
                        onClick={() =>
                          handleReschdule(
                            item.farmerbooking_id,
                            item.provider_id,
                            item.equipmenttype_id,
                            item.requested_date,
                            item.requested_timeslot
                          )
                        }
                      >
                        <button className="text-white font-medium rounded px-1 ">
                          Reschedule
                        </button>
                      </div>
                    )}

                  {item.booking_status === "Pending" && (
                    <div
                      className="bg-medium-blue flex border-dark-blue border-2 w-1/2 h-full rounded-md items-end justify-center cursor-pointer "
                      onClick={() =>
                        handleOnClickAssign(
                          item.farmerbooking_id,
                          item.provider_id,
                          item.equipmenttype_id,
                          item.requested_date,
                          item.requested_timeslot
                        )
                      }
                    >
                      <button className="text-white font-medium rounded ">
                        Assign
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </React.Fragment>
          ))
        ) : (
          <div className="col-span-6 flex items-center justify-center  mt-[15%]">
            <p>No Bookings available</p>
          </div>
        )}
      </div>
      {bookingsDetails && bookingsDetails.length > 0 && (
        <div className="flex fixed bottom-7 right-0 justify-between   px-10">
          <button
            className={`px-4 py-2 rounded mr-2 ${
              currentPage === 1
                ? "bg-gray-300 cursor-not-allowed"
                : "bg-dark-green text-white"
            }`}
            onClick={handlePrevPage}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <button
            className={`px-4 py-2 rounded ${
              currentPage === totalPages
                ? "bg-gray-300 cursor-not-allowed"
                : "bg-dark-green text-white"
            }`}
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      )}
    </div>
  );
};

export default withLayout(Bookings, "Bookings");
