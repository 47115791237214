interface InputLabelProps {
  label: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  type: string;
  placeholder?: string;
  typeExtention?: string;
  minDate?: string;
}

const LabelInput = ({ placeholder, typeExtention, label, type, value, onChange,minDate }: InputLabelProps) => {
  return (
    <div className="flex flex-col">
      {label && <label className="mb-2 text-14 font-semi-bold leading-24.2 text-left mb-4">{label}</label>}
      <input
        step={typeExtention}
        placeholder={placeholder}
        type={type}
        value={value}
        onChange={onChange}
        min={minDate}
        className="px-4 py-2 border rounded w-[305px] h-[38px] focus:outline-none focus:border-transparent"
        
      />
    </div>
  );
};

export default LabelInput;
