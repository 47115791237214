import React, { useEffect, useState } from "react";
import withLayout from "./withLayout";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../redux/store";
import { fetchPostalVillages } from "../redux/Slices/getPostalVillages";
import { useNavigate } from "react-router-dom";
import { fetchUserByPhoneNumber } from "../redux/Slices/findUserSlice";
import { addServiceProviderByPhoneNumber } from "../redux/Slices/addServiceProviderSlice";

const CreateProvider = () => {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState("");
  const postoffices = useSelector((state: any) => state.postoffice.postoffice);
  const [pincode, setPincode] = useState<string>("");
  const [username, setUsername] = useState<string>("");
  const [mobile, setMobile] = useState<string>("");
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [errorState, setErrorState] = useState({
    state: false,
    message: "",
  });
  const [phoneAlertMessage, setPhoneAlertMessage] = useState<string>("");
  const [pincodeAlertMessage, setPincodeAlertMessage] = useState<string>("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector((state: any) => state.user.user);

  const handleDropdownChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSelectedOption(event.target.value);
  };

  const isSaveButtonDisabled =
  !username.trim() || mobile.length != 10 || !selectedOption;

  const handleSave = async () => {
    if (!isSaveButtonDisabled) {
      const resData: any = await dispatch(
        addServiceProviderByPhoneNumber({
          name: username,
          phoneNumber: mobile,
          pincode: pincode,
          village: selectedOption,
          token: "",
        })
      );
      if (resData.payload?.response) {
        // console.log(resData.payload.response.data.error);
        setErrorState({
          ...errorState,
          state: true,
          message: `${resData.payload.response.data.error}`,
        });
      } else {
        setAlertMessage("Provider registered successfully");
        setUsername("");
        setMobile("");
        setSelectedOption("");
        setPincode("");
        setIsModalVisible(true);
      }
    }
  };

  useEffect(() => {
    if (pincode && pincode.length === 6) {
      dispatch(fetchPostalVillages({ pincode }));
    }
  }, [pincode]);

  useEffect(() => {
    if (mobile && mobile.length === 10) {
      dispatch(
        fetchUserByPhoneNumber({
          userType: "ServiceProvider",
          phoneNumber: mobile,
        })
      );
    }
  }, [mobile]);

  const handleMobileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setErrorState({
      ...errorState,
      state: false,
      message: ``,
    });
    const { value } = e.target;
    if (!/^\d*$/.test(value)) {
      setPhoneAlertMessage("You can enter Only Digits");
      return;
    }
    const numericValue = value.replace(/\D/g, "");
    if (numericValue.length <= 10) {
      setMobile(numericValue);
      setPhoneAlertMessage("");
    } else {
      setPhoneAlertMessage("You cannot enter more than 10 digits");
    }
  };

  const handlePincodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (!/^\d*$/.test(value)) {
      setPincodeAlertMessage("You can enter only Digits");
      return;
    }
    const numericValue = value.replace(/\D/g, "");
    if (numericValue.length <= 6) {
      setPincode(numericValue);
      setPincodeAlertMessage("");
    } else {
      setPincodeAlertMessage("You cannot enter more than 6 digits");
    }
  };

  const closeModal = () => {
    setIsModalVisible(false);
    navigate("/providers");
  };

  return (
    <div className="grid grid-cols-7 pl-10 bg-white h-full">
      <div className="flex flex-col col-span-7">
        <form className="flex space-x-4 mt-8">
          <div className="flex flex-col">
            <label htmlFor="username" className="mb-2 font-bold">
              User Name<span className="text-red-600"> *</span>
            </label>
            <input
              type="text"
              id="username"
              name="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="p-2 border rounded w-64 border-green-400"
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="mobile" className="mb-2 font-bold">
              Mobile Number<span className="text-red-600"> *</span>
            </label>
            <div className="flex items-center">
              <span className="p-2 border rounded-l w-12 border-green-400 bg-gray-200 text-gray-600">
                +91
              </span>
              <input
                type="text"
                id="mobile"
                name="mobile"
                value={mobile}
                onChange={handleMobileChange}
                className="p-2 border rounded-r w-52 border-green-400"
              />
            </div>
            {phoneAlertMessage && (
              <div className="text-center text-red-600">
                {phoneAlertMessage}
              </div>
            )}
            {errorState.state && (
              <div className="text-center text-red-600">
                {"Number already exist"}
              </div>
            )}
          </div>
          <div className="flex flex-col">
            <label htmlFor="pincode" className="mb-2 font-bold">
              Pin Code
            </label>
            <input
              type="text"
              id="pincode"
              name="pincode"
              value={pincode}
              onChange={handlePincodeChange}
              className="p-2 border rounded w-64 border-green-400"
            />
            {pincodeAlertMessage && (
              <div className="text-center text-red-600">
                {pincodeAlertMessage}
              </div>
            )}
          </div>
          <div className="flex flex-col">
            <label htmlFor="dropdown" className="mb-2 font-bold">
              Select Village
            </label>
            <select
              id="dropdown"
              name="dropdown"
              className={`p-2 border rounded w-64 ${
                selectedOption === "" ? "border-green-400" : "border-custom"
              }`}
              onChange={handleDropdownChange}
            >
              <option value="">Select an option</option>
              {postoffices &&
                postoffices.length > 0 &&
                postoffices.map((postoffice: any, index: number) => (
                  <option key={index} value={postoffice.Name}>
                    {postoffice.Name}
                  </option>
                ))}
            </select>
          </div>
        </form>
        <div className="flex justify-center mt-14 w-100">
          <button
            className="p-2 text-white font-bold rounded w-64 mr-14"
            style={{
              backgroundColor: isSaveButtonDisabled ? "#ECECEC" : "#38AF38",
            }}
            disabled={isSaveButtonDisabled}
            onClick={handleSave}
          >
            Save
          </button>
          <button
            className="p-2 text-white rounded w-64"
            style={{ backgroundColor: "#FF0000" }}
            onClick={() => navigate("/providers")}
          >
            Cancel
          </button>
        </div>
      </div>
      {isModalVisible && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="bg-white p-5 rounded shadow-lg w-1/2 h-1/4 flex flex-col">
            <p className="mb-4">{alertMessage}</p>
            <div className="mt-auto self-end">
              <button
                className="p-2 bg-blue-500 text-white rounded w-40"
                onClick={closeModal}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default withLayout(CreateProvider, "CreateProvider");
