// supportStatusSlice.ts
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import webConfig from '../../api-request/webConfig';

// Async thunk for updating support status
export const updateSupportStatus = createAsyncThunk(
  'api/updateSupportStatus',
  async ({ support_id, support_status }: { support_id: string, support_status: string }, thunkAPI) => {
    try {
      const response = await axios.put(`${webConfig.API_DOMAIN_URL}/admin/UpdateSupportStatus`, {
        support_id,
        support_status
      });
      console.log("Update support status response:", response.data);
      return response.data;
    } catch (error) {
      console.error('Error updating support status:', error);
      throw error;
    }
  }
);

// Initial state for the slice
interface SupportTicket {
  support_id: string;
  support_status: string;
}

interface SupportState {
  supportTickets: SupportTicket[];
  loading: boolean;
  error: string | null;
}

const initialState: SupportState = {
  supportTickets: [],
  loading: false,
  error: null,
};

const supportStatusSlice = createSlice({
  name: 'supportStatus',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateSupportStatus.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateSupportStatus.fulfilled, (state, action) => {
        state.loading = false;
        const updatedSupportTicket = action.payload;
        const existingTicketIndex = state.supportTickets.findIndex(ticket => ticket.support_id === updatedSupportTicket.support_id);
        if (existingTicketIndex !== -1) {
          state.supportTickets[existingTicketIndex] = updatedSupportTicket;
        } else {
          state.supportTickets.push(updatedSupportTicket);
        }
      })
      .addCase(updateSupportStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to update support status';
      });
  },
});

const updateSupportStatusReducer = supportStatusSlice.reducer;
export default updateSupportStatusReducer;
