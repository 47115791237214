import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import webConfig from '../../api-request/webConfig';

export const getProfileImageUri = createAsyncThunk(
    'api/getProfileImageUri',
    async ({userType,phoneNumber}:{userType:string,phoneNumber:string}, thunkAPI) => {
      try {
       
        const response = await axios.get(`${webConfig.API_DOMAIN_URL}/getimage/${userType}/${phoneNumber}`);
        console.log("response", response.data);
        return response.data;
      } catch (error) {
        console.error('Error fetching getimage :', error);
        // You can throw the error to let the caller handle it, or return an error object
        throw error;
      }
    }
  );
    interface ImageUri {}

  interface ApiState {
    profileimageUri: ImageUri | null;
    loading: boolean;
    error: string | null;
  }
  
  const initialState: ApiState = {
    profileimageUri: null,
    loading: false,
    error: null,
  };
  

  const fetchProfileImageUriSlice = createSlice({
    name: 'fetchProfile',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(getProfileImageUri.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(getProfileImageUri.fulfilled, (state, action) => {
          state.loading = false;
          state.profileimageUri = action.payload.imageURI;
        })
        .addCase(getProfileImageUri.rejected, (state, action) => {
          state.loading = false;
          state.error = action.error.message || 'Failed to fetch eqps';
        })

    },
  });
  const fetchProfileImageUriReducer=fetchProfileImageUriSlice.reducer
  export default fetchProfileImageUriReducer;