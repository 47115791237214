import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import webConfig from "../../api-request/webConfig";

// Async thunk for POST request to create equipment
export const createEquipment = createAsyncThunk(
  "createEquipment",
  async (requestData: any, thunkAPI) => {
    try {
      console.log(requestData);
      const response = await axios.post(
        `${webConfig.API_DOMAIN_URL}/constants/addEquipment`,
        requestData
      );
        console.log("Create equipment response:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error creating equipment:", error);
      throw error;
    }
  }
);

// Initial state and slice definition for creating equipment
interface CreateEquipmentState {
  newEquipment: any | null;
  loading: boolean;
  error: string | null;
}

const initialCreateEquipmentState: CreateEquipmentState = {
  newEquipment: null,
  loading: false,
  error: null,
};

const createEquipmentSlice = createSlice({
  name: "createEquipment",
  initialState: initialCreateEquipmentState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createEquipment.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createEquipment.fulfilled, (state, action) => {
        state.loading = false;
        state.newEquipment = action.payload;
      })
      .addCase(createEquipment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to create equipment";
      });
  },
});

const createEquipmentReducer = createEquipmentSlice.reducer;
export default createEquipmentReducer;
