import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import webConfig from '../../api-request/webConfig';

// Async thunk for POST request to add a service
export const addService = createAsyncThunk(
  'addService',
  async (requestData: any, thunkAPI) => {
    try {
      const response = await axios.post(`${webConfig.API_DOMAIN_URL}/constants/addService`, requestData);
      console.log("Add service response:", response.data);
      return response;
    } catch (error:any) {
      console.error('Error adding service:', error);
      return thunkAPI.rejectWithValue(error.response?.data || 'Unknown error');
    }
  }
);

// Initial state and slice definition for adding a service
interface AddServiceState {
    newService: any | null;
    loading: boolean;
    error: string | null;
  }
  
  const initialAddServiceState: AddServiceState = {
    newService: null,
    loading: false,
    error: null,
  };
  
  const addServiceSlice = createSlice({
    name: 'addService',
    initialState: initialAddServiceState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(addService.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(addService.fulfilled, (state, action) => {
          state.loading = false;
          state.newService = action.payload;
        })
        .addCase(addService.rejected, (state, action) => {
          state.loading = false;
          state.error = action.error.message || 'Failed to add service';
        });
    },
  });

const addServiceReducer = addServiceSlice.reducer;
export default addServiceReducer;
