import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import webConfig from '../../api-request/webConfig';

export const fetchSupportRequests = createAsyncThunk(
  'api/fetchSupportRequests',
  async ({ admin_id, page, limit, user_type, user_id, username, phone_number, support_raised_date, message, support_status }:
    { admin_id: number, page: number, limit: number, user_type?: string, user_id?: string, username?: string, phone_number?: string, support_raised_date?: string, message?: string, support_status?: string }, thunkAPI) => {
    try {
      const params = new URLSearchParams();
      params.append('page', page.toString()); 
      params.append('limit', limit.toString());
      if (user_type) params.append('user_type', user_type);
      if (user_id) params.append('user_id', user_id);
      if (username) params.append('username', username);
      if (phone_number) params.append('phone_number', phone_number);
      if (support_raised_date) params.append('support_raised_date', support_raised_date); // New date filter parameter
      if (message) params.append('message', message);
      if (support_status) params.append('support_status', support_status); // New status filter parameter

      const response = await axios.get(`${webConfig.API_DOMAIN_URL}/admin/getSupportRequests/${admin_id}?${params.toString()}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching getSupportRequests :', error);
      throw error;
    }
  }
);

interface Requests { }

interface ApiState {
  requests: Requests | null;
  loading: boolean;
  error: string | null;
}

const initialState: ApiState = {
  requests: null,
  loading: false,
  error: null,
};

const fetchSupportRequestsSlice = createSlice({
  name: 'fetchRequests',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSupportRequests.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSupportRequests.fulfilled, (state, action) => {
        state.loading = false;
        state.requests = action.payload;
      })
      .addCase(fetchSupportRequests.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch support requests';
      });
  },
});

const fetchSupportRequestsReducer = fetchSupportRequestsSlice.reducer;
export default fetchSupportRequestsReducer;
