// src/store/slices/authSlice.ts
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import webConfig from '../../api-request/webConfig';

interface AuthState {
  token: string | null;
  isLoggedIn: boolean;
  error: string | null;
}

const initialState: AuthState = {
  token: localStorage.getItem('token'),
  isLoggedIn: !!localStorage.getItem('token'),
  error: null,
};

// Define the async thunk for login
export const login = createAsyncThunk(
  'auth/login',
  async ({ email, password }: { email: string; password: string }, thunkAPI) => {
    try {
      const response = await axios.post(`${webConfig.API_DOMAIN_URL}/admin/login`, { email, password }).then(res => res.data);
      console.log(response);
      return response.token;
    } catch (error: any) {
      console.error('Error logging in:', error);
      // Optionally, you can return a rejected value for more control in the slice
      return thunkAPI.rejectWithValue(error.response?.data || 'Unknown error');
    }
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      state.token = null;
      state.isLoggedIn = false;
      localStorage.removeItem('token');
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.fulfilled, (state, action) => {
        state.token = action.payload;
        state.isLoggedIn = true;
        state.error = null;
        localStorage.setItem('token', action.payload);
      })
      .addCase(login.rejected, (state, action) => {
        state.error = action.payload as string;
      });
  },
});

export const { logout } = authSlice.actions;
const authImp =  authSlice.reducer;
export default authImp;
