import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import webConfig from '../../api-request/webConfig';

export const addServiceProviderByPhoneNumber = createAsyncThunk(
    'api/addServiceProviderByPhoneNumber',
    async ({ name, phoneNumber,pincode,village,token}: { name: string; phoneNumber: string,pincode:string,village:string,token:string}, thunkAPI) => {
      try {
        console.log("API",name,phoneNumber);
        const response = await axios.post(`${webConfig.API_DOMAIN_URL}/provider/addProvider`,{name,phoneNumber,pincode,village,token});
        console.log("addServiceProviderByPhoneNumber", response.data);
        return response;
      } catch (error) {
        console.error('Error fetching provider by phone number:', error);
        // You can throw the error to let the caller handle it, or return an error object
        return error;
      }
    }
  );