import React from "react";
import Layout from "./Layout";
import { IMG } from "../constants/imgs";
const Header = () => {
  return (
    <div className="header">
      <div className="logo ">
        <img src={IMG.Logo} alt="Logo" />
      </div>
    </div>
  );
};

export default Header;
