enum TimeSlots {
    MORNING1 = "6:00 AM - 7:00 AM",
    MORNING2 = "7:00 AM - 8:00 AM",
    MORNING3 = "8:00 AM - 9:00 AM",
    MORNING4 = "9:00 AM - 10:00 AM",
    MORNING5 = "10:00 AM - 11:00 AM",
    MORNING6 = "11:00 AM - 12:00 PM",
    AFTERNOON1 = "12:00 PM - 1:00 PM",
    AFTERNOON2 = "1:00 PM - 2:00 PM",
    AFTERNOON3 = "2:00 PM - 3:00 PM",
    AFTERNOON4 = "3:00 PM - 4:00 PM",
    AFTERNOON5 = "4:00 PM - 5:00 PM",
    EVENING1 = "5:00 PM - 6:00 PM",
}

export interface TimeSlot {
    id: number;
    name: TimeSlots;
}
export const BookingsTimeSlots: { [key: number]: string } = {
    1: TimeSlots.MORNING1,
    2: TimeSlots.MORNING2,
    3: TimeSlots.MORNING3,
    4: TimeSlots.MORNING4,
    5: TimeSlots.MORNING5,
    6: TimeSlots.MORNING6,
    7: TimeSlots.AFTERNOON1,
    8: TimeSlots.AFTERNOON2,
    9: TimeSlots.AFTERNOON3,
    10: TimeSlots.AFTERNOON4,
    11: TimeSlots.AFTERNOON5,
    12: TimeSlots.EVENING1,
  };

const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
export const validatePassword = (password: string)=> {
  return passwordRegex.test(password);
}
const emailRegEx = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const validateEmail = (userId: string) => {
  userId = userId.trim();
  if (userId === "") return false;
  return emailRegEx.test(userId) && userId.indexOf("@@") === -1;
};
export const ServicesTableHeads = ['Sl.No', 'Service Type', 'Image', 'Status', 'Action'];
export const ServicesTableData = [
  {
    slNo: 1,
    serviceType: 'Land Preparation - Wet Land',
    image: 'https://via.placeholder.com/100',
    status: true,
    action: 'Edit/Delete'
  },
  {
    slNo: 2,
    serviceType: 'Land Preparation - Dry Land',
    image: 'https://via.placeholder.com/100',
    status: false,
    action: 'Edit/Delete'
  },
  {
    slNo: 3,
    serviceType: 'Land Levelling',
    image: 'https://via.placeholder.com/100',
    status: true,
    action: 'Edit/Delete'
  },
  {
    slNo: 4,
    serviceType: 'Soil Testing',
    image: 'https://via.placeholder.com/100',
    status: true,
    action: 'Edit/Delete'
  },
  {
    slNo: 5,
    serviceType: 'Irrigation Setup',
    image: 'https://via.placeholder.com/100',
    status: false,
    action: 'Edit/Delete'
  }
];


export const BookingStatusOptions = ["Pending", "Accepted", "Rescheduled", "Completed","InProgress","Reached","Cancelled","Rejected"];
export const BookingPaymentOptions = ["Paid", "Failed","Pending"];