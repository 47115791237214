import React, { useEffect, useState } from "react";
import Layout from "./Layout";
import withLayout from "./withLayout";
import { AppDispatch } from "../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllFarmers } from "../redux/Slices/getAllFarmers";
import { useNavigate } from "react-router-dom";
import FarmerDetails from "./FarmerDetails";
import { ServicesTableData, ServicesTableHeads } from "../constants";
import DeleteIcon from "../assets/images/DeleteAction.svg";
import AddServiceDialog from "./AddServiceDialog";
import { fetchAllServices, setPage } from "../redux/Slices/getAllServicesSlice";
import { updateService } from "../redux/Slices/updateService";

const Services = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [selectedService, setSelectedServices] = useState<any>();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [limit, setLimit] = useState(10);

  const handleSelectedService = (serviceData: any) => {
    setSelectedServices(serviceData);
    handleDialogBox();
  };

  let servicesDetails = useSelector(
    (state: any) => state.servicesDetails.servicesDetails
  );
  let PaginationData = useSelector(
    (state: any) => state.paginationDataServices.paginationDataServices
  );
 
  console.log("🍵🕐>>",PaginationData)
  console.log("🍵🕐 servicesDetails>>",servicesDetails)

  useEffect(() => {
    if (servicesDetails) {
      setTotalPages(PaginationData.totalPages);
      console.log("🍵🕐>>", servicesDetails);
    }
  }, [PaginationData]);

  useEffect(() => {
    dispatch(fetchAllServices({ page: currentPage, limit: limit}));
    console.log("total length: ", servicesDetails);
  }, [currentPage, limit]);

  const handleDialogBox = () => {
    setOpenDialog(!openDialog);
  };

  const handlePageChange = (page: number) => {
    dispatch(setPage(page));
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handleOnClickStatus = (id: number, status: number) => {
    dispatch(
      updateService({
        id: id,
        serviceData: {
          service_status: status,
        },
      })
    ).then(() => {
      dispatch(fetchAllServices({ page: currentPage, limit: 5 }));
    });
  };
  // console.log(servicesDetails);
  return (
    <div className="h-full overflow-y-auto">
      {openDialog && (
        <AddServiceDialog
          title="Edit"
          serviceDetails={selectedService}
          handleClick={handleDialogBox}
          open={openDialog}
        />
      )}
      <div className="grid grid-cols-5 h-[10vh]">
        {/* Render the heading row */}
        {ServicesTableHeads.map((heading, index) => (
          <div
            key={index}
            className="bg-medium-green border-b border-gray-300 p-4 font-bold flex justify-center items-center"
          >
            {heading}
          </div>
        ))}

        {/* Loop through farmer details and render them in subsequent rows */}
        {servicesDetails && servicesDetails.length > 0 ? (
          servicesDetails.map((data: any, index: number) => (
            <>
              <div className="border-b-[2px] border-medium-green p-4 flex justify-center items-center">
                {index + 1 + (currentPage - 1) * limit}
              </div>
              <div className="border-b-[2px] border-medium-green p-4 flex justify-center items-center">
                {data.name}
              </div>
              <div className="border-b-[2px] border-medium-green p-4 flex justify-center items-center">
                <img
                  src={"https://via.placeholder.com/100"}
                  alt="no-available"
                  className="rounded-md h-[50px] w-[50px] hover:cursor-pointer"
                />
              </div>
              <div className="border-b-[2px] border-medium-green p-4 flex justify-center items-center">
                {data.service_status ? (
                  <div className="relative group">
                    <button
                      onClick={() => {
                        handleOnClickStatus(data.serviceId, 0);
                      }}
                      className="w-[91px] h-[25px] bg-[#38AF38] text-white rounded-md"
                    >
                      Enabled
                    </button>
                    <span className="absolute w-[20vh] flex items-center justify-center top-full mb-2 left-1/2 transform -translate-x-1/2 px-2 py-1 text-xs text-black rounded opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                      Click to Disable
                    </span>
                  </div>
                ) : (
                  <div className="relative group">
                    <button
                      onClick={() => {
                        handleOnClickStatus(data.serviceId, 1);
                      }}
                      className="w-[91px] h-[25px] bg-[#FF0F00] text-white rounded-md"
                    >
                      Disabled
                    </button>
                    <span className="absolute top-full  w-[20vh] flex items-center justify-center mb-2 left-1/2 transform -translate-x-1/2 px-2 py-1 text-xs text-red text-black rounded opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                      Click to Enable
                    </span>
                  </div>
                )}
              </div>
              <div className="border-b-[2px] border-medium-green p-4 flex justify-center items-center">
                <div className="flex gap-5 items-center">
                  <button
                    className="w-[57px] h-[25px] bg-blue text-white flex items-center justify-center rounded-md focus:outline-none hover:bg-blue-600"
                    style={{ backgroundColor: "#1E7DD5" }}
                    onClick={() => handleSelectedService(data)}
                  >
                    Edit
                  </button>
                  {/* <img
                    src={DeleteIcon}
                    alt="no-available"
                    className="h-[25px] hover:cursor-pointer"
                  /> */}
                </div>
              </div>
            </>
          ))
        ) : (
          <div className="flex items-center justify-center col-span-5 mt-[15%]">
            No Service available
          </div>
        )}
      </div>
      <div className="flex fixed bottom-7 right-0 justify-between px-10">
        <button
          className={`px-4 py-2 rounded mr-2 ${
            currentPage === 1
              ? "bg-gray-300 cursor-not-allowed"
              : "bg-dark-green text-white"
          }`}
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <button
          className={`px-4 py-2 rounded ${
            currentPage === totalPages
              ? "bg-gray-300 cursor-not-allowed"
              : "bg-dark-green text-white"
          }`}
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default withLayout(Services, "Services");
