import React, { useEffect, useState } from "react";
import Layout from "./Layout";
import { IMG } from "../constants/imgs";

import { useDispatch, useSelector } from "react-redux";
import Card from "../components/Card";
import { useLocation, useParams } from "react-router-dom";
import { AppDispatch } from "../redux/store";
import { fetchBookingDetailsbyId } from "../redux/Slices/getBookingDetailsbyId";
import withLayout from "./withLayout";
import useServices from "../constants/globalservices";
import { fetchAllServices } from "../redux/Slices/getAllServicesSlice";
import useEquipment from "../constants/globalequipments";
import { BookingsTimeSlots } from "../constants";

const BookingDetails = () => {

  const [slotData, setSlotData] = useState<string>("");

  const dispatch = useDispatch<AppDispatch>();
  const {getServiceNameById}=useServices();
  //const {getEquipmentTypeById}=useEquipment()
  const bookingDetailsbyId = useSelector(
    (state: any) => state.bookingDetailsbyId.bookingDetailsbyId
  );

  const [serviceDetailsContent, setServiceDetailsContent] = useState<
    { heading: string; value: any }[]
  >([]);
  const [equipmentDetailsContent, setEquipmentDetailsContent] = useState<
    { heading: string; value: any }[]
  >([]);
  const [farmerDetailsContent, setFarmerDetailsContent] = useState<
    { heading: string; value: any }[]
  >([]);
  const [providerDetailsContent, setProviderDetailsContent] = useState<
    { heading: string; value: any }[]
  >([]);
  const [sessionDetailsContent, setSessionDetailsContent] = useState<
    { heading: string; value: any }[]
  >([]);
  const [billingDetailsContent, setBillingDetailsContent] = useState<
    { heading: string; value: any }[]
  >([]);
  const {getEquipmentTypeById}=useEquipment();
  const [costvalue,setCost]=useState<string>("")
  const [costperhour,setCostperhour]=useState<string>("")
  const { farmerbooking_id, provider_id } = useParams();
  useEffect(() => {
    dispatch(fetchAllServices({ page: 1, limit:10}));
  }, [dispatch]);
useEffect(()=>{
  if(  bookingDetailsbyId?.providerBookings?.equipmenttype_id){
    const equipment_type=getEquipmentTypeById(bookingDetailsbyId?.providerBookings?.equipmenttype_id)
    if(equipment_type==="Acre"){
      setCost( bookingDetailsbyId?.providerBookings?.costperacre)
    }else if(equipment_type==="Hour"){
      setCost( bookingDetailsbyId?.providerBookings?.cost)
    }
  }
},[bookingDetailsbyId])
  useEffect(() => {
    if (farmerbooking_id && provider_id) {
      // Convert string params to numbers
      const convertedFarmerBookingId = parseInt(farmerbooking_id, 10);
      const convertedProviderId = parseInt(provider_id, 10);
      dispatch(
        fetchBookingDetailsbyId({
          admin_id: 1,
          farmerbooking_id: convertedFarmerBookingId,
          provider_id: convertedProviderId,
        })
      );
    }
  }, []);
  const getPaymentStatus = (status:string) => {
    switch (status) {
      case "CHARGED":
        return "Payment Successful";
      case "AUTHENTICATION_FAILED":
      case "AUTHORIZATION_FAILED":
        return "Payment Failed";
      default:
        return "Pending";
    }
  };
  
  useEffect(() => {
    console.log("bookingsDetailsbyId", bookingDetailsbyId);
    setServiceDetailsContent([
      {
        heading: "Service Type",
        value: getServiceNameById( bookingDetailsbyId?.farmerBookings?.service_id) || "",
      },
      {
        heading: "Date & TimeSlot",
        value: dateAndTimeSlot(bookingDetailsbyId?.farmerBookings?.requested_date) || "",
      },
      // {
      //   heading: "Final Acreage",
      //   value: bookingDetailsbyId?.farmerBookings?.landcovered_byprovider || "",
      // },
    ]);
    //aditional 
    setEquipmentDetailsContent([
      {
        heading: "Equipment Name",
        value: bookingDetailsbyId?.providerBookings?.equipment_name || "",
      },
      {
        heading: "Registration No.",
        value: bookingDetailsbyId?.providerBookings?.serialnumber || "",
      },
      {
        heading: "Owner Name",
        value: bookingDetailsbyId?.providerBookings?.ownername || "",
      },
      {
        heading: "Price Range",
        value:   costvalue  || "",
      },
    ]);
    setFarmerDetailsContent([
      {
        heading: "Name",
        value: bookingDetailsbyId?.farmerBookings?.farmer_username || "",
      },
      {
        heading: "Land Name",
        value: bookingDetailsbyId?.farmerBookings?.landname || "",
      },
      {
        heading: "Address",
        value: bookingDetailsbyId?.farmerBookings?.farmer_village || "",
      },
      {
        heading: "User Id",
        value: bookingDetailsbyId?.farmerBookings?.farmer_id || "",
      },
      {
        heading: "Estimated Land Area",
        value: bookingDetailsbyId?.farmerBookings?.farmer_estimatedarea || "",
      },
      {
        heading: "Contact No.",
        value: bookingDetailsbyId?.farmerBookings?.farmer_phonenumber || "",
      },
    ]);
    setProviderDetailsContent([
      {
        heading: "Name",
        value: bookingDetailsbyId?.farmerBookings?.provider_username || "",
      },
      {
        heading: "Address",
        value: bookingDetailsbyId?.farmerBookings?.provider_village || "",
      },
      {
        heading: "User Id",
        value: bookingDetailsbyId?.farmerBookings?.provider_id || "",
      },
      {
        heading: "Contact No.",
        value: bookingDetailsbyId?.farmerBookings?.provider_phonenumber || "",
      },
    ]);
    setSessionDetailsContent([
      {
        heading: "OTP used",
        value: bookingDetailsbyId?.farmerBookings?.farmer_otp || "",
      },
      // {
      //   heading: "Started Time",
      //   value: bookingDetailsbyId?.providerBookings?.starttime || "",
      // },
      // {
      //   heading: "End Time",
      //   value: bookingDetailsbyId?.providerBookings?.endtime || "",
      // },
      // {
      //   heading: "Break Time",
      //   value: bookingDetailsbyId?.providerBookings?.breaktime || "",
      // },
      {
        heading: "Date",
        value: dateAndTimeSlot(bookingDetailsbyId?.farmerBookings?.requested_date) || "",
      },
      // { heading: "Total Time", value: bookingDetailsbyId?.providerBookings?.starttime || "" },
    ]);
     const equipment_type=getEquipmentTypeById(bookingDetailsbyId?.providerBookings?.equipmenttype_id)
     if(equipment_type==="Acre")
     {
    setBillingDetailsContent([
      {
        heading: "Price per acre",
        value: costvalue || "",
      },
      {
        heading: "Total Acres",
        value: bookingDetailsbyId?.farmerBookings?.landcovered_byprovider || "",
      },
      {
        heading: "Total GST",
        value: " 0% ",
      },
      {
        heading: "Total Platform Charges",
        value: "0%",
      },
      {
        heading: "Total Bill",
        value: bookingDetailsbyId?.farmerBookings?.totalservicecharge || "",
      },
      {
        heading: "Payment Status",
        value: getPaymentStatus(bookingDetailsbyId?.farmerBookings?.farmer_payment_status) || "",
      },
    ]);
    }else if(equipment_type==="Hour"){
     setBillingDetailsContent([
      {
        heading: "Price per Hour",
        value: costvalue || "",
      },
      {
        heading: "Start Time",
        value: bookingDetailsbyId?.providerBookings?.starttime || "",
      },
       {
        heading: "End Time",
        value: bookingDetailsbyId?.providerBookings?.endtime || "",
      },
      {
        heading: "Total GST",
        value: " 0% ",
      },
      {
        heading: "Total Platform Charges",
        value: "0%",
      },
      {
        heading: "Total Bill",
        value: bookingDetailsbyId?.farmerBookings?.totalservicecharge || "",
      },
      {
        heading: "Payment Status",
        value: getPaymentStatus(bookingDetailsbyId?.farmerBookings?.farmer_payment_status) || "",
      },
    ]);
    }
  }, [bookingDetailsbyId, costvalue, dateAndTimeSlot, getEquipmentTypeById, getServiceNameById]);

  useEffect(() => {
    if (bookingDetailsbyId?.farmerBookings?.requested_timeslot) {
        const slots = bookingDetailsbyId.farmerBookings.requested_timeslot.map((id: number) => {
            return {
                value: id,
                label: BookingsTimeSlots[`${id}`],
            };
        });

        if (slots.length > 0) {
            setSlotData(slots[0].label);
        } else {
            setSlotData(""); 
        }
    } else {
        setSlotData(""); 
    }
}, [bookingDetailsbyId]);
  

function dateAndTimeSlot(utcDate: string): string {
  const date = new Date(utcDate);
  
  // Adjust the date to IST
  date.toLocaleString('en-US', { timeZone: 'Asia/Kolkata' });

  // Extract day, month, and year
  const day = date.getDate();
  const month = date.getMonth() + 1; // Months are zero indexed, so we add 1
  const year = date.getFullYear();

  // Format to dd-mm-yyyy
  const formattedDate = `${day}-${month}-${year}, ${slotData}`;

  return formattedDate;
}



  return (
    <div className="flex flex-col h-full overflow-y-auto">
      <Card title="Service Details" content={serviceDetailsContent} />
      <Card title="Equipment Details" content={equipmentDetailsContent} />
      <Card title="Farmer Details" content={farmerDetailsContent} />
      <Card title="Provider Details" content={providerDetailsContent} />
      <Card title="Session Details" content={sessionDetailsContent} />
      <Card title="Billing Details" content={billingDetailsContent} />
    </div>
  );
};

export default withLayout(BookingDetails, "BookingDetails");
