import React, { useEffect, useState } from "react";
import withLayout from "./withLayout";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../redux/store";
import { useNavigate, useParams } from "react-router-dom";
import { fetchEqprelatedServices } from '../redux/Slices/getRelatedEqpServices';
import { registerEquipment } from "../redux/Slices/registerEquipment";
import { fetchrelatedservices } from "../redux/Slices/fetchEqprelatedServices";
import useEquipment from "../constants/globalequipments";
import { fetchGlobalEquipments } from "../redux/Slices/fetchglobalEquipments";

const AddEquipment = () => {
const equipments = useSelector((state: any) => state.equipments.equipments);

  const navigate = useNavigate();
  const { provider_id } = useParams();
  const [selectedOption, setSelectedOption] = useState<string>("");
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [eq, setEq] = useState<any[]>([]);
  const [serviceName, setserviceName] = useState<any[]>([]);
  const [ownerName, setOwnerName] = useState<string>(equipments?.providerDetails?.username);
  const [registrationNumber, setRegistrationNumber] = useState<string>("");
  const [price, setPrice] = useState<string>("");
  const [selectedServices, setSelectedServices] = useState<number[]>([]); // Array to store selected service IDs
  const dispatch = useDispatch<AppDispatch>();
  const eqpservices = useSelector((state: any) => state.eqpservices.eqpservices);
  const services = useSelector((state: any) => state.services.services) || [];
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSaveButtonEnabled, setisSaveButtonEnabled] = useState(false);
  const [providerDetailsContent, setproviderDetailsContent] = useState<
  { heading: string; value: any }[]
>([]);
const {getEquipmentTypeById}=useEquipment();

useEffect(()=>{ dispatch(fetchGlobalEquipments());},[])
   console.log("ahhaa",equipments);
  // Create a Set to store unique equipment names
  //new comment
  const uniqueEquipmentNames = new Set();
  const filteredEq = eq.filter((arr: { value: string; equipment_name: string; equipmenttype_id: number }) => {
    if (!uniqueEquipmentNames.has(arr.equipment_name)) {
      uniqueEquipmentNames.add(arr.equipment_name);
      return true;
    }
    return false;
  });

  useEffect(() => {
    if (eqpservices && eqpservices.relatedServiceNames) {
      setEq(eqpservices.relatedServiceNames);
    }
  }, [eqpservices]);

  useEffect(() => {
    dispatch(fetchEqprelatedServices({ admin_id: 1,page:1,limit:20 }));
  }, [dispatch]);

  useEffect(() => {
    if (services && services.relatedServices) {
      setserviceName(services.relatedServices);
    } 
  }, [services]);

  useEffect(() => {
    if (selectedOption) {
      const typeid = parseInt(selectedOption);
      dispatch(fetchrelatedservices({ vehicleType_id: typeid }));
    }
  }, [selectedOption, dispatch]);

useEffect(()=>{
  console.log("length of servicesssssss",selectedServices)},[selectedServices])



  useEffect(() => {
    if (
      selectedOption !== "" &&
      ownerName !== "" &&
      registrationNumber !== "" &&
      price !== "" &&
      parseFloat(price) > 0 &&
      selectedServices.length >0
    ) {
      setisSaveButtonEnabled(true);
      setAlertMessage("");
    } else {
      setisSaveButtonEnabled(false);
      if (price !== "" && parseFloat(price) <= 0) {
        setAlertMessage("Price must be greater than 0");
      } else {
        setAlertMessage("");
      }
    }
  }, [selectedOption, ownerName, registrationNumber, price, selectedServices]);

  const handleSave = () => {
    const equipmentDetails = {
      equipment_name: eq.find(e => e.equipmenttype_id === parseInt(selectedOption))?.equipment_name || "",
      equipmenttype_id: selectedOption,
      cost: null,
      costperacre: price,
      ownername: ownerName,
      services_offered: selectedServices,
      serialNumber: registrationNumber,
      provider_id: provider_id
    };
    setIsModalVisible(true);

    if (provider_id) {
      console.log("getEquipmentTypeByteId(parseInt(seletedOption)",selectedOption,getEquipmentTypeById(parseInt(selectedOption)))
      dispatch(registerEquipment({ 
        equipment_name: eq.find(e => e.equipmenttype_id === parseInt(selectedOption))?.equipment_name || "",
        equipmenttype_id: parseInt(selectedOption),
        cost: (getEquipmentTypeById(parseInt(selectedOption))==="Hour") ? parseInt(price) : 0,
        costperacre: (getEquipmentTypeById(parseInt(selectedOption))==="Acre") ? parseInt(price) : 0,
        ownername: ownerName,
        services_offered: selectedServices,
        serialNumber: registrationNumber,
        provider_id: parseInt(provider_id)
      }));
    }
  };

  const closeModal = () => {
    setIsModalVisible(false);
    navigate('/providers');
  };

  const handleServiceChange = (serviceId: number) => {
    console.log("cool",serviceId,selectedServices)
    setSelectedServices(prevServices =>
      prevServices.includes(serviceId)
        ? prevServices.filter(id => id !== serviceId)
        : [...prevServices, serviceId]

    );
  };

  const handleOwnerNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setOwnerName(value);
  };

  const validatePrice = (value: string) => {
    const regex = /^[0-9]*$/;
    return regex.test(value);
  };

  const handlePriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (validatePrice(value) || value === "") {
      setPrice(value);
      setAlertMessage("");
    } else {
      alert("Only numbers are allowed for Price");
    }
    setPrice(event.target.value);
  };

  return (
    <div className="grid grid-cols-7 pl-10 bg-white h-full">
      <div className="flex flex-col col-span-7">
        <form className="flex space-x-4 mt-8">
          <div className="flex flex-col">
            <label htmlFor="dropdown" className="mb-2 font-bold">
              Equipment Type
            </label>
            <select
              id="dropdown"
              name="dropdown"
              className={`p-2 border rounded w-64 ${
                selectedOption === "" ? "border-green-400" : "border-custom"
              }`}
              value={selectedOption}
              onChange={(e) => setSelectedOption(e.target.value)}
            >
              <option value="">Select an option</option>
              {filteredEq.map((arr: { value: string; equipment_name: string; equipmenttype_id: number }, index: number) => (
                <option key={index} value={arr.equipmenttype_id}>
                  {arr.equipment_name}
                </option>
              ))}
            </select>
          </div>

          <div className="flex flex-col">
            <label htmlFor="OwnerName" className="mb-2 font-bold">
              Owner Name<span className="text-red-600"> *</span>
            </label>
            <input
              type="text"
              id="OwnerName"
              name="OwnerName"
              value={ownerName}
              onChange={handleOwnerNameChange}
              className="p-2 border rounded w-64 border-green-400"
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="RegistrationNumber" className="mb-2 font-bold">
              Enter Registration No.
            </label>
            <input
              type="text"
              id="RegistrationNumber"
              name="RegistrationNumber"
              value={registrationNumber}
              onChange={(event) => setRegistrationNumber(event.target.value)}
              className="p-2 border rounded w-64 border-green-400"
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="Price" className="mb-2 font-bold">
              Enter Price / per Acre
            </label>
            <input
              type="text"
              id="Price"
              name="Price"
              value={price}
              onChange={handlePriceChange}
              className="p-2 border rounded w-64 border-green-400"
            />
        {alertMessage && <div className="text-center  text-red-600">{alertMessage}</div>}

          </div>
        </form>

        <hr className="mt-10" />

        <div className="mt-4">Select Services for Equipment *</div>

        <div className="flex flex-col mt-4">
          {serviceName.map((service: { service_id: number; service_name: string }) => (
            <div key={service.service_id} className="flex items-center mr-4">
              <input 
                type="checkbox" 
                id={(service.service_id).toString()} 
                name="services" 
                value={service.service_id} 
                className="mr-2" 
                onChange={() => handleServiceChange(service.service_id)}
              />
              <label htmlFor={`service-${service.service_id}`}>{service.service_name}</label>
            </div>
          ))}
        </div>

        <div className="flex justify-center mt-14 w-100">
          <button
            className="p-2 text-white font-bold rounded w-64 mr-14"
            onClick={handleSave}
            disabled={!isSaveButtonEnabled}
            style={{ backgroundColor: !isSaveButtonEnabled ? "#ECECEC" : "#38AF38" }}
          >
            Save
          </button>
          <button
            className="p-2 text-white rounded w-64"
            style={{ backgroundColor: "#FF0000" }}
            onClick={() => navigate("/providerdetails/2/")}
          >
            Cancel
          </button>
        </div>
        {isModalVisible && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
            <div className="bg-white p-5 rounded shadow-lg w-1/2 h-1/4 flex flex-col">
              <p className="mb-4">Equipment Added Successfully</p>
              <div className="mt-auto self-end">
                <button className="p-2 bg-blue-500 text-white rounded w-40" onClick={closeModal}>
                  OK
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default withLayout(AddEquipment, "AddEquipment");
