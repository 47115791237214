import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import webConfig from '../../api-request/webConfig';

export const registerEquipment = createAsyncThunk(
    'api/registerEquipment',
    async ({equipment_name, equipmenttype_id, cost,costperacre, ownername,services_offered, serialNumber, provider_id}: { equipment_name:string, equipmenttype_id:number, cost:number,costperacre:number, ownername:string,services_offered:number[], serialNumber:string, provider_id:number}, thunkAPI) => {
      try {
        console.log("registerEquipment");
        console.log("equipmentname",equipment_name,equipmenttype_id, cost,costperacre, ownername,services_offered, serialNumber, provider_id)

        const response = await axios.post(`${webConfig.API_DOMAIN_URL}/admin/registrationofeqp`,{equipment_name, equipmenttype_id, cost,costperacre, ownername,services_offered, serialNumber, provider_id});
        console.log("registerEquipment", response.data);
        return response.data;
      } catch (error) {
        console.error('Error in registerEquipment:', error);
        // You can throw the error to let the caller handle it, or return an error object
        throw error;
      }
    }
  );