import React, { useEffect, useState } from "react";

const LandsCard: React.FC<{
  titles: string[];
  contents: { heading: string; value: any }[];
  onTitleClick: (index: string) => void;
  title: string;
}> = ({ titles, contents, onTitleClick, title }) => {
  const [expandedCard, setExpandedCard] = useState<string | null>(null);

  useEffect(() => {
    if (title === "farmers") {
      setExpandedCard("Land1");
      onTitleClick("Land1");
    } else {
      setExpandedCard("Equipment1");
      onTitleClick("Equipment1");
    }
  }, [titles]);

  const toggleExpansion = (title: string) => {
    if (expandedCard === title) {
      setExpandedCard(null);
    } else {
      setExpandedCard(title);
    }
  };
  useEffect(() => {
    console.log("contents", contents);
  }, [contents]);
  // console.log(contents);
  return (
    <div className="w-full  mt-4 flex flex-col ">
      <div className="flex flex-row border-b-2 border-gray-100 px-4 gap-10  ">
        {titles.map((title, index) => (
          <div
            key={index}
            onClick={() => {
              toggleExpansion(title);
              onTitleClick(title);
            }}
            className={`flex w-24 px-6 items-center justify-center cursor-pointer ${
              expandedCard === title ? "text-blue-400" : "text-gray-600"
            } border-b-2 ${expandedCard === title ? "border-blue-400" : ""}`}
          >
            <h2 className="text-lg font-semibold gap-6">{title}</h2>
          </div>
        ))}
      </div>
      
      <div className=' bg-light-greyy rounded-xl' style={{width:"90%", marginTop:10}}>
  {expandedCard && (
    <div className="rounded-md shadow-md p-4 bottom-1 mt-4 w-full flex flex-row flex-wrap ">
      {contents.map((item, index) => (
        <div key={index} className="flex flex-row gap-5 mb-7" style={{width: "50%"}}>
          <h3 className="text-sm font-bold">{item.heading}</h3>
          <p className="text-sm">{item.value}</p>
        </div>
      ))}
    </div>
  )}
</div>
    </div>
  );
};


export default LandsCard;
