// Interface Definition
interface ConfigInterface {
    API_DOMAIN_URL: string;
}

// Config Class
class webConfigFile {
    API_DOMAIN_URL: string;
    constructor() {
         this.API_DOMAIN_URL = "https://greenqs.in/backend";
       // this.API_DOMAIN_URL = "http://localhost:3000";
    }
}

// Config Instance
const webConfig = new webConfigFile();

export default webConfig;
