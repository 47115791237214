import { useSelector } from "react-redux";

enum ServiceName {
  
}

interface Service {
    id: number;
    name: ServiceName;
}

const useServices = () => {
    const servicesDetails = useSelector(
        (state: any) => state.servicesDetails.servicesDetails
      );

   

    const getServiceNameById = (serviceId: number): string => {
        console.log("mmmmmmmmmmmmmmmmmm",servicesDetails)
        const service = servicesDetails.find((s: { id: number }) => s.id === serviceId);
        console.log("serviceeeeee",service)
        return service ? service.name : undefined;
    };

    return { getServiceNameById };
};

export default useServices;
