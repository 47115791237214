import "../css/withLayout.css";

import React, { Children, ReactNode, useState } from "react";
import AddServiceDialog from "./AddServiceDialog";
import AddEquipmentDialog from "./AddEquipmentDialog";
import { useNavigate } from "react-router-dom";

const withLayout = (Component: any, title: any) => {
  return (props: any) => {
    const navigate = useNavigate();
    const [openDialog, setOpenDialog] = useState(false);
    const todaysdate = new Date().toLocaleDateString();
    const todaystime = new Date().toLocaleTimeString();
    const handleDialogBox = () => {
      setOpenDialog(!openDialog);
    };
    
    return (
      <>
        {title === "Services" && (
          <>
            <AddServiceDialog
              title="Add"
              handleClick={handleDialogBox}
              open={openDialog}
            />
          </>
        )}
        {title === "Equipment Type" && (
          <>
            {openDialog && (
              <AddEquipmentDialog
                handleClick={handleDialogBox}
                open={openDialog}
              />
            )}
          </>
        )}
        <div className="titled-container py-6 bg-white">
          {title === "Home" ? (
            <span className="Home_title bg-light-green h-16">
              <div className=" flex flex-row items-center">
                <h1 className=" mr-3 font-bold text-2xl">Date</h1>
                <h3 className="mr-10">{todaysdate}</h3>
              </div>
              <div className=" flex flex-row items-center">
                <h1 className=" mr-3 font-bold text-2xl">Time</h1>
                <h3>{todaystime}</h3>
              </div>
            </span>
          ) : (
            <span className="title bg-light-green h-16 flex justify-between items-center">
              {title}
              {(title === "Equipment Type" || title === "Services") && <button
                onClick={handleDialogBox}
                className="bg-green-500 flex items-center justify-center px-[15px] py-[5px] text-white font-semi-bold rounded-md focus:outline-none hover:bg-green-600"
              >
                {`+ Add ${title}`}
              </button>}
              {(title === "Providers") && <button
                onClick={() => navigate("/CreateProvider")}
                className="bg-green-500 flex items-center justify-center px-[15px] py-[5px] text-white font-semi-bold rounded-md focus:outline-none hover:bg-green-600"
              >
                {`+ Add ${title}`}
              </button>}
            </span>
          )}

          <div className="titled-content bg-white">
            <Component {...props} />
          </div>
        </div>
      </>
    );
  };
};

export default withLayout;
