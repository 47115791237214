import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import webConfig from '../../api-request/webConfig';
export const fetchCounts = createAsyncThunk(
    'api/fetchCounts',
    async ({ admin_id }: { admin_id:number }, thunkAPI) => {
      try {
        console.log("API USEEEEEEEEEEEEEEEEEEEEEEE");
        const response = await axios.get(`${webConfig.API_DOMAIN_URL}/admin/getCountofUsers/${admin_id}`);
        console.log("response of getCountofUsers ", response.data);
        return response.data;
      } catch (error) {
        console.error('Error fetching getCountofUsers : not exist', error);
        // You can throw the error to let the caller handle it, or return an error object
        throw error;
      }
    }
  );
  
    interface Count {
   
    }
  interface ApiState {
    count: Count | null;
    loading: boolean;
    error: string | null;
    authToken:string | null;
  }
  
  const initialState: ApiState = {
    count: null,
    loading: false,
    error: null,
    authToken:null
  };
  

  const fetchCountsSlice = createSlice({
    name: 'fetchCount',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchCounts.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(fetchCounts.fulfilled, (state, action) => {
          state.loading = false;
       
          state.count = action.payload;
        
      
        })
        .addCase(fetchCounts.rejected, (state, action) => {
          state.loading = false;
          state.error = action.error.message || 'Failed to fetch user';
        })
       
    },
  });
  const fetchCountsReducer=fetchCountsSlice.reducer
  export default fetchCountsReducer