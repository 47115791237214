import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import webConfig from '../../api-request/webConfig';



export const fetchUserByPhoneNumber = createAsyncThunk(
    'api/fetchUserByPhoneNumber',
    async ({ userType, phoneNumber }: { userType: string; phoneNumber: string }, thunkAPI) => {
      try {
        console.log("API USEEEEEEEEEEEEEEEEEEEEEEE",userType,phoneNumber);
        const response = await axios.get(`${webConfig.API_DOMAIN_URL}/user/${userType}/${phoneNumber}`);
        console.log("response of user ", response.data);
        return response.data;
      } catch (error) {
        console.error('Error fetching user by phone number: not exist', error);
        throw error;
      }
    }
  );
    interface User {}
    interface UserDetails {
   
    }
  interface ApiState {
    userDetails: UserDetails | null;
    user: User[] | null;
    provider_id:number|null;
    farmer_id:number|null;
    loading: boolean;
    error: string | null;
    authToken:string | null;
  }
  
  const initialState: ApiState = {
    userDetails: null,
    user: null,
    provider_id:null,
    farmer_id:null,
    loading: false,
    error: null,
    authToken:null
  };
  

  const fetchUserSlice = createSlice({
    name: 'fetchUser',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchUserByPhoneNumber.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(fetchUserByPhoneNumber.fulfilled, (state, action) => {
          state.loading = false;
          state.user = action.payload.userFound;
          state.userDetails = action.payload.user;
          console.log("User after fulfilled", state.user,state.userDetails,state.provider_id);
          console.log("farmer_id",state.farmer_id)
          if(state.user){
          state.provider_id=action.payload.user.user?.provider_id;
          state.farmer_id=action.payload.user.user?.farmer_id;
          }
          state.authToken=action.payload.token
          console.log("tokennnnnnnnnnnnnnnnnnnnnnnnnnnnnn",state.authToken,state.user)
        })
        .addCase(fetchUserByPhoneNumber.rejected, (state, action) => {
          state.loading = false;
          state.error = action.error.message || 'Failed to fetch user';
          console.log("state.eror",state.error)
          
        })
        
    },
  });
  const fetchUserReducer=fetchUserSlice.reducer
  export default fetchUserReducer;