import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import webConfig from '../../api-request/webConfig';


export const fetchProviderEquipments = createAsyncThunk(
    'api/fetchProviderEquipments',
    async ({admin_id,provider_id}:{admin_id:number,provider_id:number}, thunkAPI) => {
      try {
        console.log("getEquipmentDetails",provider_id)
        const response = await axios.get(`${webConfig.API_DOMAIN_URL}/admin/getEquipmentDetails/${admin_id}/${provider_id}`);
        console.log("response", response.data);
        return response.data;
      } catch (error) {
        console.error('Error fetching getEquipmentDetails:', error);
        // You can throw the error to let the caller handle it, or return an error object
        throw error;
      }
    }
  );
    interface Equipments {}

  interface ApiState {
    equipments: Equipments | null;
    loading: boolean;
    error: string | null;
  }
  
  const initialState: ApiState = {
    equipments: null,
    loading: false,
    error: null,
  };
  

  const fetchProviderEquipmentSlice = createSlice({
    name: 'fetchequipments',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchProviderEquipments.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(fetchProviderEquipments.fulfilled, (state, action) => {
          state.loading = false;
          state.equipments = action.payload;
        })
        .addCase(fetchProviderEquipments.rejected, (state, action) => {
          state.loading = false;
          state.error = action.error.message || 'Failed to fetch eqps';
        });
    },
  });
  const fetchProviderEquipmentSliceReducer=fetchProviderEquipmentSlice.reducer
  export default fetchProviderEquipmentSliceReducer;