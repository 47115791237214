import { useDispatch, useSelector } from "react-redux";
import CustomDropdown from "../components/CustomeDropdown";
import LabelInput from "../components/labelInput";
import withLayout from "./withLayout";
import { useEffect, useState } from "react";
import { BookingsTimeSlots } from "../constants";
import { AppDispatch } from "../redux/store";
import { useNavigate, useParams } from "react-router-dom";

import { assignProvider } from "../redux/Slices/assignProviderSlice";
import { getEquipmentsCount } from "../redux/Slices/getEquipmentsCount";
import { fetchSuitableEqpChoices } from "../redux/Slices/getSuitableEquipmentsList";
import { rescheduleProvider } from "../redux/Slices/rescheduleProviderSlice";
import { fetchBookingDetailsbyId } from "../redux/Slices/getBookingDetailsbyId";
enum TimeSlots {
  MORNING1 = "6:00 AM - 7:00 AM",
  MORNING2 = "7:00 AM - 8:00 AM",
  MORNING3 = "8:00 AM - 9:00 AM",
  MORNING4 = "9:00 AM - 10:00 AM",
  MORNING5 = "10:00 AM - 11:00 AM",
  MORNING6 = "11:00 AM - 12:00 PM",
  AFTERNOON1 = "12:00 PM - 1:00 PM",
  AFTERNOON2 = "1:00 PM - 2:00 PM",
  AFTERNOON3 = "2:00 PM - 3:00 PM",
  AFTERNOON4 = "3:00 PM - 4:00 PM",
  AFTERNOON5 = "4:00 PM - 5:00 PM",
  EVENING1 = "5:00 PM - 6:00 PM",
}

interface TimeSlot {
  id: number;
  name: TimeSlots;
}
const TimeSlotsArray: TimeSlot[] = [
  { id: 1, name: TimeSlots.MORNING1 },
  { id: 2, name: TimeSlots.MORNING2 },
  { id: 3, name: TimeSlots.MORNING3 },
  { id: 4, name: TimeSlots.MORNING4 },
  { id: 5, name: TimeSlots.MORNING5 },
  { id: 6, name: TimeSlots.MORNING6 },
  { id: 7, name: TimeSlots.AFTERNOON1 },
  { id: 8, name: TimeSlots.AFTERNOON2 },
  { id: 9, name: TimeSlots.AFTERNOON3 },
  { id: 10, name: TimeSlots.AFTERNOON4 },
  { id: 11, name: TimeSlots.AFTERNOON5 },
  { id: 12, name: TimeSlots.EVENING1 },
];

const Reschedule = () => {
  const navigate = useNavigate();
  const { farmerbooking_id,provider_id } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const bookingDetailsbyId = useSelector(
    (state: any) => state.bookingDetailsbyId.bookingDetailsbyId
  );
  const equipmentchoices = useSelector(
    (state: any) => state.equipmentchoices.equipmentchoices
  );
  const [requestedSlots, setRequestedSlots] = useState<any>([]);
  const [slotData, setSlotData] = useState<string>("");
  const [selectedSlot, setSelectedSlot] = useState<number[]>([]);
  const [eqp_id, setEqp_id] = useState<number>();
  const [reassignedprovider_id, setprovider_id] = useState<number>();
  const [providerchoices, setProviderChoices] = useState<
    { value: string; id: number; label: string }[]
  >([]);
  const [selectedStatus, setSelectedStatus] = useState<string>("");
  const [selectedProvider, setSelectedProvider] = useState<string>("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectGMTDate, setDateGMT] = useState<Date>();
  const [timeSlots, setTimeSlots] = useState<TimeSlot[]>([]);
  const equipmentscount = useSelector(
    (state: any) => state.equipmentscount.equipmentscount
  );
  const [todaysdate, setTodaysDate] = useState("");
  const bookingsDetails = useSelector(
    (state: any) => state.bookingsDetails.bookingsDetails
  );

  useEffect(() => {
    // Set today's date in YYYY-MM-DD format
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, "0"); // Months start at 0!
    const dd = String(today.getDate()).padStart(2, "0");
    setTodaysDate(`${yyyy}-${mm}-${dd}`);
    
  }, []);
  useEffect(() => {
    if (farmerbooking_id && provider_id) {
      const parsedFarmerBookingId = parseInt(farmerbooking_id, 10);
      const parsedProviderId = parseInt(provider_id, 10);

      // Validate parsed IDs
      if (!isNaN(parsedFarmerBookingId) && !isNaN(parsedProviderId)) {
        dispatch(
          fetchBookingDetailsbyId({
            admin_id: 1,
            farmerbooking_id: parsedFarmerBookingId,
            provider_id: parsedProviderId
          })
        );
      } else {
        dispatch(
          fetchBookingDetailsbyId({
            admin_id: 1,
            farmerbooking_id: parsedFarmerBookingId,
            provider_id: null
          })
        );
      }
    }
  }, [dispatch, farmerbooking_id, provider_id]);
  const options = [
    { value: "Accepted", label: "Accepted" },
    { value: "Rejected", label: "Rejected" },
    { value: "option3", label: "Option 3" },
  ];

  const statusOptions = [
    { value: "Rescheduled", label: "Reschedule" },
    // { value: "Rejected", label: "Rejected" },
  ];

  useEffect(() => {
    console.log("equipmentchoices", equipmentchoices);
    if (equipmentchoices) {
      const options = equipmentchoices?.availableEquipments.map(
        (equipment: {
          provider_id: any;
          username: any;
          eqpregistration_id: number;
        }) => ({
          value: equipment.eqpregistration_id,
          id: equipment.provider_id,
          label: equipment.username,
        })
      );
      setProviderChoices(options);
    }
  }, [equipmentchoices]);
  useEffect(() => {
    console.log("requestedSlots", requestedSlots);
  }, [requestedSlots]);
  useEffect(() => {
    if (bookingDetailsbyId?.farmerBookings?.equipmenttype_id && selectGMTDate)
      dispatch(
        getEquipmentsCount({
          equipmenttype_id:
            bookingDetailsbyId?.farmerBookings?.equipmenttype_id,
          requested_date: selectGMTDate,
          farmercancel_status: 0,
        })
      );
  }, [selectGMTDate]);
  
  useEffect(() => {
    console.log(
      "equipmentscountequipmentscountequipmentscountequipmentscount",
      equipmentscount,
      TimeSlotsArray
    );
    if(selectGMTDate){
      const accessibleSlots = filterAccessibleTimeSlots(selectGMTDate);
    const updatedTimeSlots = accessibleSlots.filter(
      (slot) => !equipmentscount?.exceedingSlots.includes(slot.id)
    );
    console.log("updatedTimeSlots", updatedTimeSlots);
    //setTimeSlots(updatedTimeSlots);
    setRequestedSlots(updatedTimeSlots);
  }
  }, [equipmentscount,selectGMTDate]);

  useEffect(() => {
    if (bookingDetailsbyId?.farmerBookings) {
      const slots = bookingDetailsbyId.farmerBookings.requested_timeslot?.map((id: number) => {
        return {
          value: id,
          label: BookingsTimeSlots[`${id}`],
        };
      }) || [];
  
      setSlotData(slots[0]?.label);
    }
  }, [bookingDetailsbyId]);
  const getCurrentHour = () => {
    const now = new Date();
    return now.getHours();
  };

  const convertTo24HourFormat = (time: any) => {
    const [hour, minute] = time.split(':');
    let hourInt = parseInt(hour, 10);
    const period = time.split(' ')[1];
    if (period === 'PM' && hourInt !== 12) {
      hourInt += 12;
    } else if (period === 'AM' && hourInt === 12) {
      hourInt = 0;
    }
    return hourInt;
  };

  const isSameDay = (date1: Date, date2: Date) => {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  };

  const filterAccessibleTimeSlots = (selectedGMTDate: Date) => {
    const currentHour = getCurrentHour();
    const currentDate = new Date();
    const selectedDate = new Date(selectedGMTDate);
    console.log("selecte date", selectedDate, currentDate)
    if (isSameDay(currentDate, selectedDate)) {
      return TimeSlotsArray.filter(slot => {
        const startHour = slot.name.split(' - ')[0];
        const slotHour = convertTo24HourFormat(startHour);
        return slotHour > currentHour;
      });
    } else {
      return TimeSlotsArray;
    }
  };
  async function handleProviderChoice(eqpregistration_id: number) {
    setEqp_id(eqpregistration_id);
    const matchingProvider = providerchoices.find(
      (choice) => parseInt(choice.value) === eqpregistration_id
    );

    if (matchingProvider) {
      const matchingId = matchingProvider.id;
      setprovider_id(matchingId);
      console.log("Matching ID:", matchingId);
      // Use matchingId as needed
    } else {
      console.log("No matching provider found");
    }
    //setprovider_id()
  }
  useEffect(() => {
    if (selectGMTDate && selectedSlot && selectedSlot.length > 0)
      dispatch(
        fetchSuitableEqpChoices({
          farmerbooking_id:
            bookingDetailsbyId?.farmerBookings?.farmerbooking_id,
          equipmenttype_id:
            bookingDetailsbyId?.farmerBookings?.equipmenttype_id,
          requested_date: selectGMTDate,
          requested_timeslots: selectedSlot,
        })
      );
  }, [selectedSlot, selectGMTDate]);
  async function handleReschedule() {
    console.log("selectedStatus", selectedStatus,selectedSlot);
    if (!selectGMTDate) {
      alert("Please reschedule with a date.");
      return;
    }
    
    if (selectedSlot.length === 0) {
      alert("Please reschedule with a slot.");
      return;
    }
    
    if (!reassignedprovider_id) {
      alert("Please reassign with a new provider.");
      return;
    }
    
    if (
      selectedStatus === "Rescheduled" &&
      eqp_id &&
      selectGMTDate &&
      reassignedprovider_id
    )
      dispatch(
        rescheduleProvider({
          eqpregistration_id: eqp_id,
          farmerbooking_id:
            bookingDetailsbyId?.farmerBookings?.farmerbooking_id,
          equipmenttype_id:
            bookingDetailsbyId?.farmerBookings?.equipmenttype_id,
          service_id: bookingDetailsbyId?.farmerBookings?.service_id,
          provider_id: reassignedprovider_id,
          rescheduled_date: selectGMTDate,
          rescheduled_timeslots: selectedSlot,
          booking_status: "Rescheduled",
        })
      );

    setIsModalVisible(true);
  }
  const closeModal = () => {
    setIsModalVisible(false);
    navigate("/bookings");
  };
  const handleSlotChange = (selectedSlot: number) => {
    console.log("Selected slot:", selectedSlot);
    setSelectedSlot([selectedSlot]);
    // setSelectedSlot((prevSelectedSlots) => {
    //   const isSelected = prevSelectedSlots.some(
    //     (slot) => slot === selectedSlot
    //   );
    //   if (isSelected) {
    //     return prevSelectedSlots.filter((slot) => slot !== selectedSlot);
    //   } else {
    //     return [...prevSelectedSlots, selectedSlot];
    //   }
    // });
  };

  const handleDateConfirm = (date: Date) => {
    // Ensure that date is a valid Date object
    if (!(date instanceof Date)) {
      throw new Error("Invalid date object");
    }

    //const isoString = new Date(requested_date);
    const utcDateString = date;
    setDateGMT(utcDateString);
  };
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${day}-${month}-${year}`;
  };
  const formatTime = (dateString: string) => {
    const date = new Date(dateString);
    const hours = String(date.getUTCHours()).padStart(2, "0");
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");
    const seconds = String(date.getUTCSeconds()).padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
  };

  return (
    <>
      <div className="p-10 h-full overflow-y-auto">
        <p className="font-inter text-20 font-bold leading-24.2 text-left">
          Assign Orders
        </p>
        <div className="h-px bg-gray-300 my-9" />
        <div className="flex flex-col">
          <p className="font-inter text-20 font-bold leading-16.94 text-left mb-4">
            User Details *
          </p>
          <div className="flex w-305 h-38 gap-20">
            <LabelInput
              type="text"
              value={
                bookingDetailsbyId &&
                bookingDetailsbyId?.farmerBookings?.farmer_username
              }
              onChange={(e) => {
                console.log(e.target.value);
              }}
              label={"Name"}
            />
            <LabelInput
              type="text"
              value={
                bookingDetailsbyId &&
                bookingDetailsbyId?.farmerBookings?.farmerbooking_id
              }
              onChange={(e) => {
                console.log(e.target.value);
              }}
              label={"Booking ID"}
            />
          </div>
        </div>
        <div className="h-px bg-gray-300 my-9" />
        <div className="flex flex-col">
          <p className="font-inter text-20 font-bold leading-24.2 text-left mb-4">
            Delivery Details *
          </p>
          <div className="flex w-305 h-38 gap-20">
            <LabelInput
              type="text"
              
              value={
                bookingDetailsbyId &&
                bookingDetailsbyId?.farmerBookings?.village
              }
              onChange={(e) => {
                console.log(e.target.value);
              }}
              label={"Delivery Address"}
            />
            <LabelInput
              type="text"
              value={
                bookingDetailsbyId &&
                bookingDetailsbyId?.farmerBookings?.district
              }
              onChange={(e) => {
                console.log(e.target.value);
              }}
              label={"Pin Code"}
            />
          </div>
        </div>
        <div className="h-px bg-gray-300 my-9" />
        <div className="flex flex-col justify-center">
          <p className="font-inter text-20 font-bold leading-24.2 text-left mb-4">
            Date & Time *
          </p>
          <div className="flex w-305 h-38 gap-20 mb-7">
            <div>
            
              <div>
              <LabelInput
              type="text"
              
              value={
                bookingDetailsbyId &&
                formatDate(bookingDetailsbyId?.farmerBookings?.requested_date)
              }
              onChange={(e) => {
                console.log(e.target.value);
              }}
              label={"Requested Date"}
            />
                
              </div>
           
            </div>
            <div className="flex flex-col ">
              
              <div className="flex w-305 h-38 gap-20">
              
                
              <LabelInput
              type="text"
              
              value={
                bookingDetailsbyId &&
                slotData ? slotData : ""
              }
              onChange={(e) => {
                console.log(e.target.value);
              }}
              label={" Requested Slots"}
            />
              </div> 
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="flex w-305 h-38 gap-20">
            <LabelInput
              type="date"
              value={
                selectGMTDate
                  ? selectGMTDate.toISOString().split("T")[0] :""
                 
              }
              onChange={(e) => {
                const date = new Date(e.target.value); // Convert the string to a Date object
                handleDateConfirm(date);
              }}
              minDate={new Date().toISOString().split("T")[0]}
              label={"Reshedule Date"}
            />
            <div className="flex flex-col">
              <p className="font-inter text-14 font-semi-bold leading-16.4 text-left mb-4">
                Reshedule Slots
              </p>
              <div className="flex w-305 h-38 gap-20">
                <CustomDropdown
                  options={requestedSlots.map(
                    (slot: { id: any; name: any }) => ({
                      value: slot.id,
                      label: slot.name,
                    })
                  )}
                  selectedValue={
                    selectedSlot && selectedSlot.length > 0
                      ? selectedSlot[0].toString()
                      : ""
                  }
                  onChange={(e) => {
                    console.log("eeeeeeee", e);
                    handleSlotChange(parseInt(e));
                  }}
                  placeholder="Select an option"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="h-px bg-gray-300 my-9" />
        <div className="flex flex-col">
          <p className="font-inter text-20 font-bold leading-24.2 text-left mb-4">
            Status *
          </p>
          <div className="flex w-305 h-38 gap-20">
            <CustomDropdown
              options={statusOptions}
              selectedValue={selectedStatus}
              onChange={(e) => {
                setSelectedStatus(e);
              }}
              placeholder="Select an option"
            />
          </div>
        </div>
        <div className="h-px bg-gray-300 my-9" />
        <div className="flex flex-col">
          <p className="font-inter text-20 font-bold leading-24.2 text-left mb-4">
            Service Provider *
          </p>
          {selectedStatus === "Rescheduled" && (
            <div className="flex w-305 h-38 gap-20">
              <CustomDropdown
                options={providerchoices}
                selectedValue={selectedProvider}
                onChange={(e) => {
                  setSelectedProvider(e);
                  handleProviderChoice(parseInt(e)); // Assuming you also need to handle some action on selection
                }}
                placeholder="Select an option"
              />
            </div>
          )}
        </div>
        <div className="flex gap-5 mt-9 p-2 ">
          {selectedStatus === "Rescheduled" || "" ? (
            <button
              className="w-[477px] h-12 rounded rounded-bl-none bg-medium-blue text-white opacity-100"
              onClick={handleReschedule}
            >
              Reschedule
            </button>
          ) : null}
          {selectedStatus === "Rejected" ? (
            <button
              className="w-[477px] h-12 rounded rounded-bl-none bg-medium-red text-white opacity-100"
              onClick={handleReschedule}
            >
              Reject
            </button>
          ) : null}
          <button
            className="w-[477px] h-12 rounded rounded-bl-none bg-medium-red text-white opacity-100"
            onClick={() => navigate("/bookings")}
          >
            Cancel
          </button>
        </div>
        {isModalVisible && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
            <div className="bg-white p-5 rounded shadow-lg w-1/2 h-1/4 flex flex-col">
              <p className="mb-4">Provider has been assigned</p>
              <div className="mt-auto self-end">
                <button
                  className="p-2 bg-blue-500 text-white rounded w-40"
                  onClick={closeModal}
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default withLayout(Reschedule, "Reschedule Booking");
