import React, { useState } from 'react';

const Card: React.FC<{ title: string; content:{ heading: string; value: any; }[] }> = ({ title, content }) => {
  const [expanded, setExpanded] = useState(true);

  const toggleExpansion = () => {
    setExpanded(!expanded);
  };

  return (
    <div className={`bg-light-green rounded-md shadow-md p-4 bottom-2 border-b-dark-green ${expanded ? 'h-auto' : 'h-24'} w-full`} >
      <div className="flex justify-between items-center cursor-pointer">
        <h2 className="text-lg font-semibold">{title}</h2>
        <svg
          className={`w-6 h-6 transform transition-transform ${expanded ? 'rotate-0' : 'rotate-360'}`}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          onClick={toggleExpansion}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d={expanded ? 'M5 15l7-7 7 7' : 'M19 9l-7 7-7-7'}
          />
        </svg>
      </div>
      {expanded && (
  <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
    {content.map((item, index) => (
      <div key={index} className="mb-2 flex flex-row gap-2">
        <h3 className="text-sm font-medium">{item.heading}</h3>
        <p className="text-sm">{item.value}</p>
      </div>
    ))}
  </div>
)}
     {expanded && (<div className='bg-dark-green w-full' style={{height:"1px"}}></div>)}
    </div>
  
  );
};

export default Card;
