import React, { useEffect, useState } from "react";
import Layout from "./Layout";

import { IMG } from "../constants/imgs";
import { AppDispatch, RootState } from "../redux/store";
import { useDispatch, useSelector } from "react-redux";
import withLayout from "./withLayout";
import { fetchEqprelatedServices } from "../redux/Slices/getRelatedEqpServices";
import { fetchCounts } from "../redux/Slices/getCounts";
import Farmer from "../assets/images/farmer.png";
import Provider from "../assets/images/provider_icon.png";
import RightICon from "../assets/images/right_ICon.png";
import BookingIcon from "../assets/images/Booking_Icon.png";
import ServiceIcon from "../assets/images/Service_logo.png";
import { useNavigate } from "react-router-dom";
import { fetchDashboardStats } from "../redux/Slices/dashboardSlice";
import { fetchGlobalEquipments } from "../redux/Slices/fetchglobalEquipments";

const todaysdate = new Date();
const Home = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [farmerCount, setFarmercount] = useState<number>();
  const [providerCount, setProvidercount] = useState<number>();
  const providersDetails = useSelector(
    (state: any) => state.providersDetails.providersDetails
  );
  const count = useSelector((state: any) => state.count.count);

  const { activeServiceCount, totalBookings, todayBookings, loading, error } =
    useSelector((state: RootState) => state.getDashboardStats);

  console.log(activeServiceCount, todayBookings, todayBookings, totalBookings);

  // Dispatch the thunk on component mount
  useEffect(() => {
    dispatch(fetchDashboardStats());
  }, [dispatch]);
useEffect(()=>{ const newState = {
  home: true,
  booking: false,
  farmer: false,
  provider: false,
  service: false,
  equipment: false,
  support: false
};
saveStateToLocalStorage(newState);},[])
  useEffect(() => {
    console.log("count", count);
    if (count && count.farmerCount && count.serviceProviderCount) {
      console.log("countttt", count, count.farmerCount);
      setFarmercount(count.farmerCount);
      setProvidercount(count.serviceProviderCount);
    }
  }, [count]);
  useEffect(()=>{ dispatch(fetchGlobalEquipments());},[])
  useEffect(() => {
    dispatch(fetchCounts({ admin_id: 1 }));
  }, []);
  const saveStateToLocalStorage = (state:any) => {
    localStorage.setItem('navState', JSON.stringify(state));
  };
  const handleFarmerClick = () => {
    const newState = {
      home: false,
      booking: false,
      farmer: true,
      provider: false,
      service: false,
      equipment: false,
      support: false
    };
    saveStateToLocalStorage(newState);

    navigate("/farmers");

   
  };

  const handleProviderClick = () => {
    const newState = {
      home: false,
      booking: false,
      farmer: false,
      provider: true,
      service: false,
      equipment: false,
      support: false
    };
    saveStateToLocalStorage(newState);
    navigate("/providers");
  };

  const handleServiceClick = () => {
    const newState = {
      home: false,
      booking: false,
      farmer: false,
      provider: false,
      service: true,
      equipment: false,
      support: false
    };
    saveStateToLocalStorage(newState);
    navigate("/services");
  };

  const handleBookingClick = () => {
    const newState = {
      home: false,
      booking: true,
      farmer: false,
      provider: false,
      service: false,
      equipment: false,
      support: false
    };
    saveStateToLocalStorage(newState);
    navigate("/bookings");
  };

  return (
    <div className="w-full h-full p-10 overflow-y-auto">
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
        {/* Box 1 */}
        <div
          className="w-full h-[20vh] p-5  bg-white shadow-lg rounded-lg transform transition-transform duration-300 hover:scale-110 cursor-pointer"
          onClick={handleFarmerClick}
        >
          <div className="flex flex-row items-center">
            <div className="h-12 w-12 rounded-full flex items-center justify-center">
              <img src={Farmer} alt="logo" className="h-6 w-6 object-contain" />
            </div>
            <span className="font-bold text-2xl ml-2">Farmers</span>
          </div>
          <div className="flex flex-row items-center justify-between mt-4">
            <span className="text-center font-bold text-4xl px-5">
              {farmerCount}
            </span>
            <div className="absolute bottom-0 right-0 mb-4 mr-4">
                <img
                  src={RightICon}
                  alt="logo"
                  className="h-4 w-4 object-contain"
                />
              </div>
          </div>
        </div>

        {/* Box 2 */}
        <div
          className="w-full h-[20vh] py-5 bg-white shadow-lg rounded-lg transform transition-transform duration-300 hover:scale-110 cursor-pointer"
          onClick={handleProviderClick}
        >
          <div className="flex flex-row items-center">
            <div className="h-12 w-12 rounded-full flex items-center justify-center">
              <img
                src={Provider}
                alt="logo"
                className="h-6 w-6 object-contain"
              />
            </div>
            <span className="font-bold text-2xl ml-2">Providers</span>
          </div>
          <div className="flex flex-row items-center justify-between mt-4">
            <span className="text-center font-bold text-4xl px-5">
              {providerCount}
            </span>
            <div className="absolute bottom-0 right-0 mb-4 mr-4">
                <img
                  src={RightICon}
                  alt="logo"
                  className="h-4 w-4 object-contain"
                />
              </div>
          </div>
        </div>

        {/* Box 3 */}
        <div
          className="w-full h-[20vh] py-5 bg-white shadow-lg rounded-lg transform transition-transform duration-300 hover:scale-110 cursor-pointer"
          onClick={handleServiceClick}
        >
          <div className="flex flex-row items-center">
            <div className="h-12 w-12 rounded-full flex items-center justify-center">
              <img
                src={ServiceIcon}
                alt="logo"
                className="h-6 w-6 object-contain"
              />
            </div>
            <span className="font-bold text-2xl ml-2">Services</span>
          </div>
          <div className="flex flex-row items-center justify-between mt-4">
            <span className="text-center font-bold text-4xl px-5">
              {activeServiceCount}
            </span>
            <div className="absolute bottom-0 right-0 mb-4 mr-4">
                <img
                  src={RightICon}
                  alt="logo"
                  className="h-4 w-4 object-contain"
                />
              </div>
          </div>
        </div>

        {/* Box 4 */}
        <div
          className="w-full h-[20vh] py-5 bg-white shadow-lg rounded-lg transform transition-transform duration-300 hover:scale-110 cursor-pointer"
          onClick={handleBookingClick}
        >
          <div className="flex flex-row items-center">
            <div className="h-12 w-12 rounded-full flex items-center justify-center">
              <img
                src={BookingIcon}
                alt="logo"
                className="h-6 w-6 object-contain"
              />
            </div>
            <span className="font-bold text-2xl ml-2">Total Bookings</span>
          </div>
          <div className="flex flex-row items-center justify-between mt-4">
            <span className="text-center font-bold text-4xl px-5">
              {totalBookings}
            </span>
            <div className="absolute bottom-0 right-0 mb-4 mr-4">
                <img
                  src={RightICon}
                  alt="logo"
                  className="h-4 w-4 object-contain"
                />
              </div>
          </div>
        </div>

        {/* Box 5 */}
        <div
          className="w-full h-[20vh] py-5 bg-white shadow-lg rounded-lg transform transition-transform duration-300 hover:scale-110 cursor-pointer"
          onClick={handleBookingClick}
        >
          <div className="flex flex-row items-center">
            <div className="h-12 w-12 rounded-full flex items-center justify-center">
              <img
                src={BookingIcon}
                alt="logo"
                className="h-6 w-6 object-contain"
              />
            </div>
            <span className="font-bold text-2xl ml-2">Today Bookings</span>
          </div>
          <div className="flex flex-row items-center justify-between mt-4">
            <span className="text-center font-bold text-4xl px-5">
              {todayBookings}
            </span>
              <div className="absolute bottom-0 right-0 mb-4 mr-4">
                <img
                  src={RightICon}
                  alt="logo"
                  className="h-4 w-4 object-contain"
                />
              </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withLayout(Home, "Home");
