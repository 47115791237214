import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchPostalVillages = createAsyncThunk(
    'api/fetchPostalVillages',
    async (pincode : { pincode: string }, thunkAPI) => {
      try {
        console.log("pincode",pincode)
        const response = await axios.get(`https://api.postalpincode.in/pincode/${pincode.pincode}`);
        console.log("response", response.data[0]);
        return response.data[0].PostOffice;
      } catch (error) {
        console.error('Error fetching Villages:', error);
        // You can throw the error to let the caller handle it, or return an error object
        throw error;
      }
    }
  );
    interface PostOffice {}

  interface ApiState {
    postoffice: PostOffice | null;
    loading: boolean;
    error: string | null;
  }
  
  const initialState: ApiState = {
    postoffice: null,
    loading: false,
    error: null,
  };
  

  const fetchPostalVillagesbyApi = createSlice({
    name: 'fetchpostalvillages',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchPostalVillages.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(fetchPostalVillages.fulfilled, (state, action) => {
          state.loading = false;
          state.postoffice = action.payload;
        })
        .addCase(fetchPostalVillages.rejected, (state, action) => {
          state.loading = false;
          state.error = action.error.message || 'Failed to fetch services';
        })
        
    },
  });
  const fetchPostalVillagesbyApiReducer=fetchPostalVillagesbyApi.reducer
  export default fetchPostalVillagesbyApiReducer;