import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import webConfig from "../../api-request/webConfig";
export const fetchAllBookings = createAsyncThunk(
  "api/fetchAllBookings",
  async (
    {
      admin_id,
      page,
      limit,
      provider,
      bookingId,
      status,
      farmer,
      date,
      paymentstatus
    }: {
      admin_id: number;
      page: number;
      limit: number;
      provider: string;
      bookingId: number;
      status: string;
      farmer: string;
      date: string;
      paymentstatus:string;
    },
    thunkAPI
  ) => {
    try {
      const response = await axios.get(
        `${
          webConfig.API_DOMAIN_URL
        }/admin/getAllFarmerBookingDetails/${admin_id}?page=${page}&limit=${limit}&provider_username=${
          provider ? provider : ""
        }&farmerbooking_id=${bookingId ? bookingId : ""}&booking_status=${
          status ? status : ""
        }&farmer_username=${farmer ? farmer : ""}&requested_date=${
          date ? date : ""
        }&farmer_payment_status=${paymentstatus ? paymentstatus : ""}`
      );
      console.log(response)
      console.log("response of getAllFarmerBookingDetails ", response.data);
      return response.data;
    } catch (error) {
      console.error(
        "Error fetching getAllFarmerBookingDetails : not exist",
        error
      );
      // You can throw the error to let the caller handle it, or return an error object
      throw error;
    }
  }
);

interface BookingsDetails {}
interface ApiState {
  bookingsDetails: BookingsDetails | null;
  loading: boolean;
  bookingPagination: {} | null;
  error: string | null;
  authToken: string | null;
}

const initialState: ApiState = {
  bookingsDetails: null,
  bookingPagination: null,
  loading: false,
  error: null,
  authToken: null,
};

const fetchAllBookingsSlice = createSlice({
  name: "fetchAllBookings",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllBookings.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllBookings.fulfilled, (state, action) => {
        state.loading = false;

        state.bookingsDetails = action.payload.request;
        state.bookingPagination = action.payload.pagination;
      })
      .addCase(fetchAllBookings.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch user";
      });
  },
});
const fetchAllBookingsReducer = fetchAllBookingsSlice.reducer;
export default fetchAllBookingsReducer;
