import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import webConfig from '../../api-request/webConfig';

export const fetchAllProviders = createAsyncThunk(
  'api/fetchAllProviders',
  async ({ admin_id, page, limit, username, phonenumber, pincode, village, provider_id }: 
    { admin_id: number, page: number, limit: number, username?: string, phonenumber?: string, pincode?: string, village?: string, provider_id?: number }, thunkAPI) => {
    try {
      const response = await axios.get(`${webConfig.API_DOMAIN_URL}/admin/getallproviders/${admin_id}`, {
        params: {
          page,
          limit,
          username,
          phonenumber,
          pincode,
          village,
          provider_id,
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching providers : not exist', error);
      throw error;
    }
  }
);

interface ProvidersDetails {}

interface ApiState {
  providersDetails: ProvidersDetails | null;
  providerPageData: any | null;
  loading: boolean;
  error: string | null;
  authToken: string | null;
}

const initialState: ApiState = {
  providersDetails: null,
  providerPageData: null,
  loading: false,
  error: null,
  authToken: null
};

const fetchAllProvidersSlice = createSlice({
  name: 'fetchAllProviders',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllProviders.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllProviders.fulfilled, (state, action) => {
        state.loading = false;
        state.providersDetails = action.payload.paginatedData?.providers;
        state.providerPageData = action.payload.Data;
      })
      .addCase(fetchAllProviders.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch providers';
      });
  },
});

const fetchAllProvidersReducer = fetchAllProvidersSlice.reducer;
export default fetchAllProvidersReducer;
