import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import EmptyImage from "../assets/images/EmptyImgView.svg";
import { AppDispatch } from "../redux/store";
import { useDispatch } from "react-redux";
import { addService } from "../redux/Slices/createService";
import { fetchAllServices } from "../redux/Slices/getAllServicesSlice";
import { updateService } from "../redux/Slices/updateService";

interface DialogProps {
  handleClick: () => void;
  open: boolean;
  serviceDetails?: any;
  title: string;
}

const AddServiceDialog = ({
  open,
  handleClick,
  serviceDetails,
  title,
}: DialogProps) => {
  const [errorState, setErrorState] = useState({
    state: false,
    message: "",
  });
  const [serviceName, setServiceName] = useState("");
  const [serviceImage, setServiceImage] = useState<any>();
  const [image, setImage] = useState("");
  const dispatch = useDispatch<AppDispatch>();

  const handleOnUploadImage = (event: any) => {
    const file = event.target.files[0];
    setServiceImage(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(`${reader.result}`);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSave = async () => {
    if (title === "Add") {
      try {
        const resData: any = await dispatch(
          addService({
            service_name: serviceName,
            service_status: 0,
            service_image: serviceImage,
          })
        );
        if (resData.error) {
          setErrorState({
            ...errorState,
            state: true,
            message: `${resData.payload.error}`,
          });
        } else {
          dispatch(fetchAllServices({ page: "", limit:""}));
          handleClick();
        }
      } catch (error: any) {
        console.log("Error unable to add service : ", error);
      }
    } else {
      dispatch(
        updateService({
          id: serviceDetails.serviceId,
          serviceData: {
            service_name: serviceName,
            service_image: serviceImage,
          },
        })
      ).then(() => {
        dispatch(fetchAllServices({ page: "", limit:""}));

        handleClick();
      });
    }
  };

  useEffect(() => {
    if (serviceDetails) {
      setServiceName(serviceDetails.name);
      setServiceImage(serviceDetails.image);
      setImage(serviceDetails.image);
    } else {
      setServiceName("");
      setServiceImage(null);
      setImage("");
    }
  }, [serviceDetails]);

  return (
    <>
      {open && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white rounded-lg shadow-lg w-[800px]">
            <div className="bg-light-green rounded-t-md">
              <div className="p-5 flex justify-between items-center">
                <h2 className="text-xl font-inter font-bold">{`${title} Service`}</h2>
                <button
                  onClick={handleClick}
                  className="text-gray-500 hover:text-gray-700"
                >
                  <CloseIcon />
                </button>
              </div>
            </div>
            <div className="px-[70px] py-[50px]">
              {errorState.state && (
                <div
                  className="h-[40px] flex gap-1 items-center justify-center bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                  role="alert"
                >
                  <strong className="font-bold">Warning!</strong>
                  <span className="block sm:inline"> {errorState.message}</span>
                </div>
              )}
              <div className="flex flex-col gap-8">
                {/* Service Name Input */}
                <div className="flex flex-col gap-5">
                  <label className="font-inter font-bold">Service Name</label>
                  <input
                    type="text"
                    value={serviceName}
                    onChange={(e) => setServiceName(e.target.value)}
                    className="px-4 py-2 border border-dark-green rounded w-[305px] h-[38px]"
                    placeholder="Enter service name"
                  />
                </div>

                {/* Service Image Input */}
                <div className="flex flex-col gap-5">
                  <label className="font-inter font-bold">Service Image</label>
                  <div className="flex items-center">
                    {image === "" ? (
                      <div className="rounded-[10px] bg-[#F3F3F3] flex items-center justify-center h-[98px] w-[98px]">
                        <img
                          src={EmptyImage}
                          alt="image-not-available"
                          className="ml-2"
                        />
                      </div>
                    ) : (
                      <div className="rounded-[10px] bg-[#F3F3F3] flex items-center justify-center h-[98px] w-[98px]">
                        <img
                          src={image}
                          alt="image-not-available"
                          className="h-[98px] w-[98px] rounded-[10px]"
                        />
                      </div>
                    )}
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleOnUploadImage}
                      className="px-4 py-2 w-[305px] h-[38px]"
                    />
                  </div>
                </div>

                {/* Save and Cancel Buttons */}
                <div className="flex justify-center gap-10 mt-6">
                  <button
                    disabled = {serviceName==="" || image===""}
                    onClick={handleSave}
                    className="flex-1 h-10 bg-green-500 text-white disabled:bg-gray-400 disabled:cursor-not-allowed font-bold rounded-md focus:outline-none hover:bg-green-600"
                  >
                    Save
                  </button>
                  <button
                    onClick={handleClick}
                    className="flex-1 h-10 bg-red-500 text-white font-bold rounded-md focus:outline-none hover:bg-red-600"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddServiceDialog;
