import React, { useEffect } from "react";
import Layout from "./Layout";

import { IMG } from "../constants/imgs";
import Navigation from "./Navigation";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../redux/store";
import { handleJusPayResponse } from "../redux/Slices/handleJusPayResponse";
const Payment = () => {
 // const { order_id } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  // useEffect(()=>{
  //   if(order_id)
  //   dispatch(handleJusPayResponse({order_id:order_id}))
  // },[order_id])
  return (
    <div className="sidebar-container justify-center items-center ">
            <a href="agritech://PaymentMethod">Expo Link 1</a>
            <a href="agritech://">Expo Link 2</a>

  </div>
  )
}

export default Payment;
