import { Link, useLocation, useNavigate } from "react-router-dom";
import Farmer from "../assets/images/farmer.png";
import Home from "../assets/images/Home.svg";
import BookingIcon from "../assets/images/Booking_Icon.png";
import ServiceIcon from "../assets/images/Service_logo.png";
import EquipmentIcon from "../assets/images/EquipmentLogo.svg";
import Provider from "../assets/images/provider_icon.png";
import Support from "../assets/images/support_Icon.png";
import Logout from "../assets/images/LogOut_icon.png";
import { logout } from "../redux/Slices/login";
import { AppDispatch, RootState } from "../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

const Navigation = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const authState = useSelector((state: RootState) => state.auth);
  const location = useLocation();
  const [home, setHome] = useState(false);
  const [booking, setBooking] = useState(false);
  const [farmer, setFarmer] = useState(false);
  const [service, setService] = useState(false);
  const [provider, setProvider] = useState(false);
  const [equipment, setEquipment] = useState(false);
  const [support, setSupport] = useState(false);

  const navStateString = localStorage.getItem('navState');
  useEffect(() => {
    const savedState = localStorage.getItem('navState');
    if (savedState) {
      const parsedState = JSON.parse(savedState);
      setHome(parsedState.home);
      setBooking(parsedState.booking);
      setFarmer(parsedState.farmer);
      setService(parsedState.service);
      setProvider(parsedState.provider);
      setEquipment(parsedState.equipment);
      setSupport(parsedState.support);
    }
  }, [navStateString]);


  useEffect(() => {
   
    const path = location.pathname.split("/")[1];
    const newState = {
      home: path === "home",
      booking: path=== "bookings" || path=== "booking-details" || path=== "provider-reschedule" || path=== "provider-assign",
      farmer: path === "farmers" || path==="farmerdetails",	
      service: path === "services",
      provider: path === "providers" || path==="providerdetails" ,
      equipment: path === "equipments",
      support: path === "support"
    };
  
    setHome(newState.home);
    setBooking(newState.booking);
    setFarmer(newState.farmer);
    setService(newState.service);
    setProvider(newState.provider);
    setEquipment(newState.equipment);
    setSupport(newState.support);
  }, [location]);
   

  useEffect(()=>{console.log("nav",navStateString)},[navStateString])

  const saveStateToLocalStorage = (state:any) => {
    localStorage.setItem('navState', JSON.stringify(state));
  };

  const handleOnClickLogout = () => {
    dispatch(logout());
    navigate("/login");
  };


  const handleOnClickHome = () => {
    const newState = {
      home: true,
      booking: false,
      farmer: false,
      provider: false,
      service: false,
      equipment: false,
      support: false
    };
    saveStateToLocalStorage(newState);
    setHome(true);
    setBooking(false);
    setFarmer(false);
    setProvider(false);
    setService(false);
    setEquipment(false);
    setSupport(false);
  };

  const handleOnClickBooking = () => {
    const newState = {
      home: false,
      booking: true,
      farmer: false,
      provider: false,
      service: false,
      equipment: false,
      support: false
    };
    saveStateToLocalStorage(newState);
    setHome(false);
    setBooking(true);
    setFarmer(false);
    setProvider(false);
    setService(false);
    setEquipment(false);
    setSupport(false);
  };

  const handleOnClickFarmer = () => {
    const newState = {
      home: false,
      booking: false,
      farmer: true,
      provider: false,
      service: false,
      equipment: false,
      support: false
    };
    saveStateToLocalStorage(newState);
    setHome(false);
    setBooking(false);
    setFarmer(true);
    setProvider(false);
    setService(false);
    setEquipment(false);
    setSupport(false);
  };

  const handleOnClickProvider = () => {
    const newState = {
      home: false,
      booking: false,
      farmer: false,
      provider: true,
      service: false,
      equipment: false,
      support: false
    };
    saveStateToLocalStorage(newState);
    setHome(false);
    setBooking(false);
    setFarmer(false);
    setProvider(true);
    setService(false);
    setEquipment(false);
    setSupport(false);
  };

  const handleOnClickEquipment = () => {
    const newState = {
      home: false,
      booking: false,
      farmer: false,
      provider: false,
      service: false,
      equipment: true,
      support: false
    };
    saveStateToLocalStorage(newState);
    setHome(false);
    setBooking(false);
    setFarmer(false);
    setProvider(false);
    setService(false);
    setEquipment(true);
    setSupport(false);
  };

  const handleOnClickService = () => {
    const newState = {
      home: false,
      booking: false,
      farmer: false,
      provider: false,
      service: true,
      equipment: false,
      support: false
    };
    saveStateToLocalStorage(newState);
    setHome(false);
    setBooking(false);
    setFarmer(false);
    setProvider(false);
    setService(true);
    setEquipment(false);
    setSupport(false);
  };

  const handleOnClickSupport = () => {
    const newState = {
      home: false,
      booking: false,
      farmer: false,
      provider: false,
      service: false,
      equipment: false,
      support: true
    };
    saveStateToLocalStorage(newState);
    setHome(false);
    setBooking(false);
    setFarmer(false);
    setProvider(false);
    setService(false);
    setEquipment(false);
    setSupport(true);
  };

  return (
    <div className="flex flex-col h-full">
      <ul className="flex flex-col items-center flex-grow py-10">
        <li onClick={handleOnClickHome} className={`flex flex-row h-16 w-full ${home ? 'bg-dark-green hover:bg-dark-green' : 'hover:bg-medium-green'}`}>
          <Link
            to="/home"
            className="flex flex-row w-full items-center px-12 h-full gap-4"
          >
            <div className="h-10 w-10 rounded-full flex items-center justify-center">
              <img src={Home} alt="logo" className="h-6 w-6 object-contain" />
            </div>
            <div className={`text-black hover:text-white font-medium  ${home && 'text-white hover:text-white'}`}>Home</div>
          </Link>
        </li>
        <li onClick={handleOnClickBooking} className={`flex flex-row h-16 w-full ${booking ? 'bg-dark-green hover:bg-dark-green' : 'hover:bg-medium-green'}`}>
          <Link
            to="/bookings"
            className="flex flex-row w-full items-center px-12 h-full gap-4"
          >
            <div className="h-10 w-10 rounded-full flex items-center justify-center">
              <img
                src={BookingIcon}
                alt="logo"
                className="h-6 w-6 object-contain"
              />
            </div>
            <div className={`text-black hover:text-white font-medium ${booking && 'text-white hover:text-white'}`}>
              Bookings
            </div>
          </Link>
        </li>
        <li onClick={handleOnClickFarmer} className={`flex flex-row h-16 w-full ${farmer ? 'bg-dark-green hover:bg-dark-green' : 'hover:bg-medium-green'}`}>
          <Link
            to="/farmers"
            className="flex flex-row w-full items-center px-12 h-full gap-4"
          >
            <div className="h-10 w-10 rounded-full flex items-center justify-center">
              <img src={Farmer} alt="logo" className="h-8 w-8 object-contain" />
            </div>
            <div className={`text-black hover:text-white font-medium ${farmer && 'text-white hover:text-white'}`}>
              Farmers
            </div>
          </Link>
        </li>
        <li onClick={handleOnClickProvider} className={`flex flex-row h-16 w-full ${provider ? 'bg-dark-green hover:bg-dark-green' : 'hover:bg-medium-green'}`}>
          <Link
            to="/providers"
            className="flex flex-row w-full items-center px-12 h-full gap-4"
          >
            <div className="h-10 w-10  rounded-full flex items-center justify-center">
              <img
                src={Provider}
                alt="logo"
                className="h-8 w-8 object-contain"
              />
            </div>
            <div className={`text-black hover:text-white font-medium ${provider && 'text-white hover:text-white'}`}>
              Providers
            </div>
          </Link>
        </li>
        <li onClick={handleOnClickService} className={`flex flex-row h-16 w-full ${service ? 'bg-dark-green hover:bg-dark-green' : "hover:bg-medium-green"}`}>
          <Link
            to="/services"
            className="flex flex-row w-full items-center px-12 h-full gap-4"
          >
            <div className="h-10 w-10  rounded-full flex items-center justify-center">
              <img
                src={ServiceIcon}
                alt="logo"
                className="h-7 w-7 object-contain"
              />
            </div>
            <div className={`text-black hover:text-white font-medium ${service && 'text-white hover:text-white'}`}>
              Services
            </div>
          </Link>
        </li>
        <li onClick={handleOnClickEquipment} className={`flex flex-row h-16 w-full ${equipment ? 'bg-dark-green hover:bg-dark-green' : 'hover:bg-medium-green'}`}>
          <Link
            to="/equipments"
            className="flex flex-row w-full items-center px-12 h-full gap-4"
          >
            <div className="h-10 w-10 rounded-full flex items-center justify-center">
              <img
                src={EquipmentIcon}
                alt="logo"
                className="h-8 w-8 object-contain"
              />
            </div>
            <div className={`text-black hover:text-white font-medium ${equipment && 'text-white hover:text-white'}`}>
              Equipment Type
            </div>
          </Link>
        </li>
        <li onClick={handleOnClickSupport} className={`flex flex-row h-16 w-full ${!support ? 'hover:bg-medium-green' : 'hover:bg-dark-green'}  ${support ? 'bg-dark-green' : ""}`}>
          <Link
            to="/support"
            className="flex flex-row w-full items-center px-12 h-full gap-4"
          >
            <div className="h-10 w-10  rounded-full flex items-center justify-center">
              <img
                src={Support}
                alt="logo"
                className="h-7 w-7 object-contain"
              />
            </div>
            <div className={`text-black hover:text-white font-medium ${support && 'text-white hover:text-white'}`}>
              Support
            </div>
          </Link>
        </li>
      </ul>
      <div
        onClick={handleOnClickLogout}
        className="py-4 w-full hover:bg-dark-green hover:text-white"
      >
        <Link
          to="/login"
          className="flex flex-row w-full items-center px-12 h-full gap-4 "
        >
          <div className="h-10 w-10  rounded-full flex items-center justify-center">
            <img src={Logout} alt="logo" className="h-6 w-6 object-contain" />
          </div>
          <div className="text-black font-medium hover:text-white">Logout</div>
        </Link>
      </div>
    </div>
  );
};

export default Navigation;
